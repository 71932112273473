@charset 'UTF-8';
@use './global/' as *;



.input {
  background-color: var(--white-color);
  border: 1px solid var(--gray-500-color);
  border-radius: 6px;
  box-shadow: none;
  color: var(--gray-dark-color);
  font-size: var(--form-font-size);
  line-height: 1;
  padding: $input-y-padding $input-x-padding;
  width: 100%;

  // Placeholder
  &::placeholder {
    font-size: $placeholder-font-size;
    transition: color var(--base-transition);
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background-color: var(--white-color);
    border: 0;
  }

  // Focus
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: var(--white-color);
    border-color: var(--base-color);
    box-shadow: $focus-ring;
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown):not([type="file"]) {
    background-color: var(--gray-100-color);
    border-color: var(--base-color);
  }

  // Disabled
  &:disabled {
    background-color: var(--gray-100-color);
    cursor: not-allowed;
    color: var(--gray-600-color);
    border-color: var(--gray-400-color);
  }

  // Textarea
  @at-root textarea#{&} {
    font-family: $base-font-family;
    line-height: 1.6;
    height: auto;
    overflow-wrap: break-word;
    padding: 1em;
    resize: vertical;
    width: 100%;
  }

  // File uplode
  &[type="file"] {
    font-size: 1rem;
    overflow: hidden;
    padding: .75em .5em;

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::file-selector-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--gray-200-color);
  }
  &::-webkit-file-upload-button {
    @include file-selector-button($input-x-padding, $input-y-padding);
  }
  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--gray-200-color);
  }
}





// Select
select.select {
  background-color: var(--white-color);
  background-image: set-svg( $caret-up-down, $gray-500 );
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: .75em;
  border: 1px solid var(--gray-500-color);
  border-radius: 6px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: var(--form-font-size);
  outline: none;
  padding: $input-y-padding 3em $input-y-padding 1em;
  position: relative;
  transition: border-color var(--base-transition);
  width: auto;
  &::-moz-focus-inner {
    border: 0;
  }
  &:focus {
    background-color: var(--white-color);
    border-color: var(--base-color);
    box-shadow: $focus-ring;
    outline: 0;
  }
  &:disabled {
    background-color: var(--gray-100-color);
    cursor: not-allowed;
    color: var(--gray-600-color);
    border-color: var(--gray-400-color);
  }
}





// Checkbox & Radio
label.checkbox,
label.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--form-font-size);
  &:has(:disabled) {
    cursor: not-allowed;
  }

  input {
    all: revert;
    cursor: pointer;
    margin: 0 .5em 0 0;
    width: 1.25em;
    height: 1.25em;
    &:disabled {
      cursor: not-allowed;
    }
    &:focus {
      box-shadow: $focus-ring-small;
    }
  }
}





// Title
label.form-name {
  display: block;
  margin-bottom: .5em;
}




// Layout
.form-field {
  & + & {
    margin-top: $form-field-gutter;
  }
}



.form-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  &.vertical {
    flex-direction: column;
  }
}



.form-grid {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  gap: var(--form-grid-gutter);

  > {
    @include exportGridColumns();
  }

  // 子要素にカラムの指定がない場合はすべて1カラムになる
  > * {
    grid-column: auto / span $grid-columns;
  }

  // 隣接
  &:not(:last-child) {
    margin-bottom: var(--grid-gutter);
  }

  // gapless
  &.gapless {
    gap: 0;
  }
}
