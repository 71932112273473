@charset 'UTF-8';
@use './global/' as *;



.accordion-wrapper {
  position: relative;
  width: 100%;
}



.accordion-item {
  border: 1px solid var(--base-color);
  overflow: hidden;

  &:first-of-type {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
  }
  &:last-of-type {
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  &:not(:first-of-type) {
    border-top: none;
  }
}



.accordion-heading {
  background-color: var(--base-color);
  border-bottom: 1px solid var(--base#{$prefix-dark}-color);
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding: 1em;
  position: relative;
  transition: background-color var(--base-transition);
  width: 100%;

  @include hover(){
    background-color: var(--base#{$prefix-dark}-color);
  }

  // icon
  &::after {
    content: '';
    @include setSvgIcon( $circle-plus, $white, 1.25em );
  }

  // open
  &[aria-pressed="true"] {
    &::after {
      @include setSvgIcon( $circle-minus, $white, 1.25em );
    }
  }
}



.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height var(--base-transition);
}

.accordion-contents-inner {
  background-color: var(--white-color);
  padding: 1em;
}
