@charset 'UTF-8';
@use './global/' as *;



.label {
  background-color: var(--base-color);
  border: $label-border-width solid transparent;
  border-radius: $label-border-radius;
  color: var(--white-color);
  display: inline-block;
  font-size: $label-font-size;
  line-height: 1 !important;
  padding: map-get($label-padding-y, xs) map-get($label-padding-x, xs);
  text-align: center;
  position: relative;

  @each $key, $value in $breakpoints {
    @include media($key){
      padding: map-get($label-padding-y, $key) map-get($label-padding-x, $key);
    }
  }

  // bacground color pattern
  @each $name, $color in $all-colors {
    &.#{$name} {
      background-color: var(--#{$name}-color);
      color: var(--white-color);
    }
  }

  &.outline {
    background-color: var(--white-color);
    border: $label-border-width solid var(--base-color);
    color: var(--base-color);

    // border color pattern
    $label-all-colors: map-merge(
      $label-background-colors,
      $basic-colors
    );
    @each $name, $color in $all-colors {
      &.#{$name} {
        border-color: var(--#{$name}-color);
        color: var(--#{$name}-color);
      }
    }
  }

  &.expanded {
    display: block;
  }
}

span.label {
  margin-left: .5em;
  margin-right: 0;
  &.left {
    margin-left: 0;
    margin-right: .5em;
  }
}
