@charset 'UTF-8';
@use 'sass:math';
@use 'sass:map';
@use './var' as *;
@use './functions' as *;





//
// モバイルファーストでメディアクエリを書き出す
// @params $name：ブレークポイントの名前
// @params $breakpoints：ブレークポイントの配列
//
@mixin media($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}





// Container
@mixin baseContainer( $y-padding: 0, $x-padding: $container-side-padding, $width: $container-max-width )
{
  margin: 0 auto;
  padding: $y-padding $x-padding;
  width: 100%;
  max-width: $width;
}





// Make grid
@mixin flexProperty($width)
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}





//
// Row Columns
// @params $columns：グリッド数
// @params $breakpoints：ブレークポイントの配列
//
@mixin exportRowColumns($columns: $grid-columns, $breakpoints: $breakpoints)
{
  @each $breakpoint in map.keys($breakpoints) {
    @include media($breakpoint, $breakpoints) {
      @for $i from 1 through $columns {
        // 通常のカラム
        .#{$breakpoint}-#{$i} {
          @include flexProperty( math.div(100%, math.div($columns, $i)) );
          margin: 0;
          padding: calc( var(--grid-gutter) * .5 );
        }

        // センター揃えのカラム
        .#{$breakpoint}-#{$i}-center {
          @include flexProperty( math.div(100%, math.div($columns, $i)) );
          margin: 0 auto;
          padding: calc( var(--grid-gutter) * .5 );
        }

        // オフセットのカラム
        .#{$breakpoint}-#{$i}-offset {
          @include flexProperty( math.div(100%, math.div($columns, $i)) );
          margin-left: ( math.div(100%, math.div($columns, $i)) );
          padding: calc( var(--grid-gutter) * .5 );
        }

        // カラム数指定
        .#{$breakpoint}-col-#{$i} {
          @include flexProperty( math.div(100%, $i) );
          margin: 0;
          padding: calc( var(--grid-gutter) * .5 );
        }
      }
    }
  }
}





//
// Grid Columns
// @params $columns：グリッド数
// @params $breakpoints：ブレークポイントの配列
//
@mixin exportGridColumns($columns: $grid-columns, $breakpoints: $breakpoints)
{
  @each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint, $breakpoints) {
      @for $i from 1 through $columns {
        // 通常のカラム
        .#{$breakpoint}-#{$i} {
          grid-column: auto / span $i;
        }
      }
    }
  }
}





// Hoverの指定
@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}





// SVG
@mixin setSvgIcon( $svg: $arrow-right, $color: $base, $size: 1em )
{
  background-image: set-svg($svg, $color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: $size;
  height: $size;
}





// ファイルアップロード
@mixin file-selector-button( $x: 1em, $y: 1em )
{
  background-color: $gray-100;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: $gray-dark;
  margin: ($x * -1) ($y * -1);
  margin-inline-end: $x;
  padding: $y $x;
  pointer-events: none;
}





// Container Query
@mixin containerQuery( $width: $container-query-width )
{
  @container ( min-width: #{$width} ) {
    @content;
  }
}
