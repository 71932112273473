@charset 'UTF-8';
@use 'sass:math';
@use './color' as *;
@use '../functions' as *;



// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;



// root font size
$root-font-size: (
  xs: 14px,
  sm: 14px,
  md: 14px,
  lg: 16px,
  xl: 16px
) !default;



$font-size-percentage: (
  'xsmall'  : 62.5%,
  'small'   : 75%,
  'medium'  : 87.5%,
  'large'   : 112.5%,
  'xlarge'  : 125%,
  'xxlarge' : 150%,
  'exlarge' : 200%
) !default;



// Base font size
$base-font-size: 1rem !default;

// Base font family
$base-font-family: system-ui, -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;

// Base font color
$base-font-color: $gray-dark !default;

// Base line height
$base-line-height: 1.6 !default;



// Background color
$body-background-color     : $white !default; // body
$header-background-color   : colorCodeIncludingOpacity($base, 90%) !default; // header
$globalnav-background-color: $header-background-color !default; // navigation
$footer-background-color   : $base !default; // footer



// Spacing

// Spacer
$base-spacer: 1rem !default;

// Container max width
$container-max-width: 1000px !default;

// Container side padding
$container-side-padding: $base-spacer !default;



// Base transition
$base-transition: .2s ease-out;

// Base border-radius
$base-border-radius: .5rem !default;

// Base box-shadw
$shadow-color: #295375 !default;
$base-box-shadow:       0 .5rem 1rem colorCodeIncludingOpacity($shadow-color, 7%) !default;
$base-box-shadow-small: 0 .125rem .25rem colorCodeIncludingOpacity($shadow-color, 15%) !default;
$base-box-shadow-large: 0 1rem 3rem colorCodeIncludingOpacity($shadow-color, 7%) !default;



// Class name
$pagetop-active-class-name: '.pagetop-active' !default;      // Pagetop
$pagination-active-class:   '.current' !default;             // Pagination



// grid column
$grid-columns: 12 !default;
$grid-gutter:  1.5rem !default;



// Heading
$h1-font-size: $base-font-size * 2.25 !default;
$h2-font-size: $base-font-size * 2 !default;
$h3-font-size: $base-font-size * 1.75 !default;
$h4-font-size: $base-font-size * 1.5 !default;
$h5-font-size: $base-font-size * 1.25 !default;
$h6-font-size: $base-font-size !default;

$heading-margin-bottom: $base-spacer * 2 !default;
$headings-font-weight:  500 !default;
$heading-line-height:   1.2 !default;



// .lead
$lead-letter-spacing: 0 !default;
$lead-line-height   : 1.6 !default;



// Anchor links
$base-link-color:            #0033cc !default;
$base-link-decoration:       none    !default;
$base-link-hover-color:      inherit !default;
$base-link-hover-decoration: none    !default;



// Btn
$btn-font-size-normal: 1rem !default;
$btn-font-size-small:  $btn-font-size-normal * .8125 !default;
$btn-font-size-large:  $btn-font-size-normal * 1.25 !default;
$btn-font-sizes: (
  'small':  $btn-font-size-small,
  'normal': $btn-font-size-normal,
  'large':  $btn-font-size-large
) !default;

// border 太さ
$btn-border-width: 1px !default;

// 角丸
$btn-border-radius: 2em !default;

// padding 左右
$btn-x-padding-small:  1.25em !default;
$btn-x-padding-normal: 1.25em !default;
$btn-x-padding-large:  1.125em !default;
$btn-x-paddings: (
  'small':  $btn-x-padding-small,
  'normal': $btn-x-padding-normal,
  'large':  $btn-x-padding-large
) !default;

// padding 上下
$btn-y-padding-small:  .575em !default;
$btn-y-padding-normal: .825em !default;
$btn-y-padding-large:  .825em !default;
$btn-y-paddings: (
  'small':  $btn-y-padding-small,
  'normal': $btn-y-padding-normal,
  'large':  $btn-y-padding-large
) !default;

// background color
$btn-background-colors: (
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco
) !default;

$btn-hover-background-colors: (
  'accent': lighten( $accent, 7.5% ),
  'uno'   : lighten( $uno, 7.5% ),
  'dos'   : lighten( $dos, 7.5% ),
  'tres'  : lighten( $tres, 7.5% ),
  'cuatro': lighten( $cuatro, 7.5% ),
  'cinco' : lighten( $cinco, 7.5% )
) !default;

// text color
$btn-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;

$btn-hover-colors: (
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;



// form
$form-font-size       : 16px !default; // iOSのフォーカス時のズームを無効にするには16px以上を指定
$focus-color          : colorCodeIncludingOpacity($blue, 25%) !default;
$focus-ring           : 0 0 0 .25rem #{$focus-color} !default;
$focus-ring-small     : 0 0 0 .125rem #{$focus-color} !default;
$form-field-gutter    : 2rem !default;
$form-grid-gutter     : 8px !default;
$input-x-padding      : 1em !default;
$input-y-padding      : .75em !default;
$placeholder-color    : $gray-600 !default;
$placeholder-font-size: .75em !default;



// Horizontal line
$hr-border  : 1px solid $gray-400 !default;
$hr-y-gutter: $base-spacer !default;



// Table
$table-border-color:        $gray-300 !default;
$table-th-background-color: $gray-100 !default;
$table-td-background-color: $white    !default;



// Label
$label-font-size: .75em;

$label-padding-y: (
  xs: .425em,
  sm: .425em,
  md: .5em,
  lg: .4em,
  xl: .4em
) !default;

$label-padding-x: (
  xs: .6em,
  sm: .6em,
  md: .6em,
  lg: .6em,
  xl: .6em
) !default;

// 角丸
$label-border-radius: 0px !default;

// アウトライン時のボーダーの太さ
$label-border-width: 1px !default;

// background color
$label-background-colors: (
  'base'  : $base,
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco
) !default;

// text color
$label-colors: (
  'base'  : $white,
  'accent': $white,
  'uno'   : $white,
  'dos'   : $white,
  'tres'  : $white,
  'cuatro': $white,
  'cinco' : $white
) !default;



// z-index
$z-index-header   : 4 !default;
$z-index-globalnav: 3 !default;
$z-index-main     : 0 !default;
$z-index-footer   : 1 !default;
$z-index-pagetop  : 2 !default;
$z-index-loading  : 5 !default;
