@charset "UTF-8";
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-text-opacity {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable=true]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

:root {
  --root-font-size: 14px;
  --base-color: #007e39;
  --accent-color: #2e2f33;
  --uno-color: #6f42c1;
  --dos-color: #d63384;
  --tres-color: #fd7e14;
  --cuatro-color: #2da8a8;
  --cinco-color: #5f6987;
  --first-color: #6f42c1;
  --second-color: #d63384;
  --third-color: #fd7e14;
  --fourth-color: #2da8a8;
  --five-color: #5f6987;
  --base-light-color: #b3d8c4;
  --accent-light-color: #c0c1c2;
  --uno-light-color: #d4c6ec;
  --dos-light-color: #f3c2da;
  --tres-light-color: #fed8b9;
  --cuatro-light-color: #c0e5e5;
  --cinco-light-color: #cfd2db;
  --first-light-color: #d4c6ec;
  --second-light-color: #f3c2da;
  --third-light-color: #fed8b9;
  --fourth-light-color: #c0e5e5;
  --five-light-color: #cfd2db;
  --base-dark-color: #004c22;
  --accent-dark-color: #1c1c1f;
  --uno-dark-color: #432874;
  --dos-dark-color: #801f4f;
  --tres-dark-color: #984c0c;
  --cuatro-dark-color: #1b6565;
  --cinco-dark-color: #393f51;
  --first-dark-color: #432874;
  --second-dark-color: #801f4f;
  --third-dark-color: #984c0c;
  --fourth-dark-color: #1b6565;
  --five-dark-color: #393f51;
  --gray-100-color: #f8f9fa;
  --gray-200-color: #e9ecef;
  --gray-300-color: #dee2e6;
  --gray-400-color: #ced4da;
  --gray-500-color: #adb5bd;
  --gray-600-color: #6c757d;
  --gray-700-color: #495057;
  --gray-800-color: #343a40;
  --gray-900-color: #212529;
  --gray-dark-color: #333;
  --black-color: #000;
  --white-color: #fff;
  --red-color: #b0120a;
  --yellow-color: #fff000;
  --orange-color: #f18f2c;
  --green-color: #63a764;
  --blue-color: #005fcc;
  --pink-color: #ff0066;
  --purple-color: #6f42c1;
  --cyan-color: #17a2b8;
  --grid-columns: 12;
  --grid-gutter: 1.5rem;
  --form-grid-gutter: 8px;
  --base-font-size: 1rem;
  --form-font-size: 16px;
  --base-font-color: #333;
  --base-font-family: system-ui, -apple-system, Robot, Helvetica Neue, Helvetica, Hiragino Sans, Noto Sans CJK JP, メイリオ, Meiryo, Arial, sans-serif;
  --box-shadow: 0 0.5rem 1rem #29537512;
  --box-shadow-small: 0 0.125rem 0.25rem #29537526;
  --box-shadow-large: 0 1rem 3rem #29537512;
  --base-transition: 0.2s ease-out;
  --border-radius: 0.5rem;
  --spacing: 1rem;
  --large-spacing: 2rem;
  --xlarge-spacing: 4rem;
  --exlarge-spacing: 8rem;
}
@media (min-width: 576px) {
  :root {
    --root-font-size: 14px;
  }
}
@media (min-width: 768px) {
  :root {
    --root-font-size: 14px;
  }
}
@media (min-width: 992px) {
  :root {
    --root-font-size: 16px;
  }
}
@media (min-width: 1200px) {
  :root {
    --root-font-size: 16px;
  }
}

html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  background-color: #fff;
  color: var(--base-font-color);
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: #0033cc;
  cursor: revert;
  text-decoration: none;
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    color: inherit;
    text-decoration: none;
  }
}

hr {
  border-top: 0;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: 1rem 0;
  width: 100%;
  height: 0;
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.construct-header {
  background-color: #007e39e6;
  box-shadow: var(--box-shadow);
  flex: 0 0 auto;
  width: 100%;
  z-index: 4;
}
@media (min-width: 992px) {
  .construct-header {
    box-shadow: none;
  }
}

.construct-globalnav {
  animation: show var(--base-transition);
  display: none;
  background-color: #007e39e6;
  flex: 0 0 auto;
  max-height: calc(100% - 70px);
  overflow: auto;
  width: 100%;
  z-index: 3;
}
.construct-globalnav[aria-hidden=false] {
  display: block;
}
.construct-globalnav::after {
  content: "";
  background-color: #000000e6;
  display: block;
  position: fixed;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  .construct-globalnav::after {
    content: none;
  }
}
@media (min-width: 992px) {
  .construct-globalnav {
    max-height: none;
    overflow: visible;
  }
}

.construct-main {
  background-color: transparent;
  container-type: inline-size;
  flex: 1 0 auto;
  padding: var(--exlarge-spacing) 0;
  width: 100%;
  z-index: 0;
}

.construct-footer {
  background-color: #007e39;
  flex: none;
  width: 100%;
  z-index: 1;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 1000px;
}

.section-content, .section-block {
  width: 100%;
}
.section-content:not(:last-child), .section-block:not(:last-child) {
  margin-bottom: var(--exlarge-spacing);
}

.block {
  width: 100%;
}
.block:not(:last-child) {
  margin-bottom: var(--xlarge-spacing);
}

.center-block {
  display: block;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-gutter);
}
.grid:has([class$=-center]) {
  place-items: center;
}
.grid > .xs-1 {
  grid-column: auto/span 1;
}
.grid > .xs-2 {
  grid-column: auto/span 2;
}
.grid > .xs-3 {
  grid-column: auto/span 3;
}
.grid > .xs-4 {
  grid-column: auto/span 4;
}
.grid > .xs-5 {
  grid-column: auto/span 5;
}
.grid > .xs-6 {
  grid-column: auto/span 6;
}
.grid > .xs-7 {
  grid-column: auto/span 7;
}
.grid > .xs-8 {
  grid-column: auto/span 8;
}
.grid > .xs-9 {
  grid-column: auto/span 9;
}
.grid > .xs-10 {
  grid-column: auto/span 10;
}
.grid > .xs-11 {
  grid-column: auto/span 11;
}
.grid > .xs-12 {
  grid-column: auto/span 12;
}
@media (min-width: 576px) {
  .grid > .sm-1 {
    grid-column: auto/span 1;
  }
  .grid > .sm-2 {
    grid-column: auto/span 2;
  }
  .grid > .sm-3 {
    grid-column: auto/span 3;
  }
  .grid > .sm-4 {
    grid-column: auto/span 4;
  }
  .grid > .sm-5 {
    grid-column: auto/span 5;
  }
  .grid > .sm-6 {
    grid-column: auto/span 6;
  }
  .grid > .sm-7 {
    grid-column: auto/span 7;
  }
  .grid > .sm-8 {
    grid-column: auto/span 8;
  }
  .grid > .sm-9 {
    grid-column: auto/span 9;
  }
  .grid > .sm-10 {
    grid-column: auto/span 10;
  }
  .grid > .sm-11 {
    grid-column: auto/span 11;
  }
  .grid > .sm-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 768px) {
  .grid > .md-1 {
    grid-column: auto/span 1;
  }
  .grid > .md-2 {
    grid-column: auto/span 2;
  }
  .grid > .md-3 {
    grid-column: auto/span 3;
  }
  .grid > .md-4 {
    grid-column: auto/span 4;
  }
  .grid > .md-5 {
    grid-column: auto/span 5;
  }
  .grid > .md-6 {
    grid-column: auto/span 6;
  }
  .grid > .md-7 {
    grid-column: auto/span 7;
  }
  .grid > .md-8 {
    grid-column: auto/span 8;
  }
  .grid > .md-9 {
    grid-column: auto/span 9;
  }
  .grid > .md-10 {
    grid-column: auto/span 10;
  }
  .grid > .md-11 {
    grid-column: auto/span 11;
  }
  .grid > .md-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 992px) {
  .grid > .lg-1 {
    grid-column: auto/span 1;
  }
  .grid > .lg-2 {
    grid-column: auto/span 2;
  }
  .grid > .lg-3 {
    grid-column: auto/span 3;
  }
  .grid > .lg-4 {
    grid-column: auto/span 4;
  }
  .grid > .lg-5 {
    grid-column: auto/span 5;
  }
  .grid > .lg-6 {
    grid-column: auto/span 6;
  }
  .grid > .lg-7 {
    grid-column: auto/span 7;
  }
  .grid > .lg-8 {
    grid-column: auto/span 8;
  }
  .grid > .lg-9 {
    grid-column: auto/span 9;
  }
  .grid > .lg-10 {
    grid-column: auto/span 10;
  }
  .grid > .lg-11 {
    grid-column: auto/span 11;
  }
  .grid > .lg-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 1200px) {
  .grid > .xl-1 {
    grid-column: auto/span 1;
  }
  .grid > .xl-2 {
    grid-column: auto/span 2;
  }
  .grid > .xl-3 {
    grid-column: auto/span 3;
  }
  .grid > .xl-4 {
    grid-column: auto/span 4;
  }
  .grid > .xl-5 {
    grid-column: auto/span 5;
  }
  .grid > .xl-6 {
    grid-column: auto/span 6;
  }
  .grid > .xl-7 {
    grid-column: auto/span 7;
  }
  .grid > .xl-8 {
    grid-column: auto/span 8;
  }
  .grid > .xl-9 {
    grid-column: auto/span 9;
  }
  .grid > .xl-10 {
    grid-column: auto/span 10;
  }
  .grid > .xl-11 {
    grid-column: auto/span 11;
  }
  .grid > .xl-12 {
    grid-column: auto/span 12;
  }
}
.grid > * {
  grid-column: auto/span 12;
}
.grid:not(:last-child) {
  margin-bottom: var(--grid-gutter);
}
.grid.gapless {
  gap: 0;
}

.grid-flex {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc(var(--grid-gutter) * -0.5);
  margin-right: calc(var(--grid-gutter) * -0.5);
  margin-left: calc(var(--grid-gutter) * -0.5);
  margin-bottom: calc(var(--grid-gutter) * -0.5);
}
.grid-flex .xs-1 {
  flex: 0 0 8.3333333333%;
  width: 8.3333333333%;
  max-width: 8.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-1-center {
  flex: 0 0 8.3333333333%;
  width: 8.3333333333%;
  max-width: 8.3333333333%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-1-offset {
  flex: 0 0 8.3333333333%;
  width: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 8.3333333333%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-1 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-2 {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
  max-width: 16.6666666667%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-2-center {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
  max-width: 16.6666666667%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-2-offset {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 16.6666666667%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-2 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-3 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-3-center {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-3-offset {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin-left: 25%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-3 {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
  max-width: 33.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-4 {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
  max-width: 33.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-4-center {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
  max-width: 33.3333333333%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-4-offset {
  flex: 0 0 33.3333333333%;
  width: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 33.3333333333%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-4 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-5 {
  flex: 0 0 41.6666666667%;
  width: 41.6666666667%;
  max-width: 41.6666666667%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-5-center {
  flex: 0 0 41.6666666667%;
  width: 41.6666666667%;
  max-width: 41.6666666667%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-5-offset {
  flex: 0 0 41.6666666667%;
  width: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 41.6666666667%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-5 {
  flex: 0 0 20%;
  width: 20%;
  max-width: 20%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-6 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-6-center {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-6-offset {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin-left: 50%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-6 {
  flex: 0 0 16.6666666667%;
  width: 16.6666666667%;
  max-width: 16.6666666667%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-7 {
  flex: 0 0 58.3333333333%;
  width: 58.3333333333%;
  max-width: 58.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-7-center {
  flex: 0 0 58.3333333333%;
  width: 58.3333333333%;
  max-width: 58.3333333333%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-7-offset {
  flex: 0 0 58.3333333333%;
  width: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 58.3333333333%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-7 {
  flex: 0 0 14.2857142857%;
  width: 14.2857142857%;
  max-width: 14.2857142857%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-8 {
  flex: 0 0 66.6666666667%;
  width: 66.6666666667%;
  max-width: 66.6666666667%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-8-center {
  flex: 0 0 66.6666666667%;
  width: 66.6666666667%;
  max-width: 66.6666666667%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-8-offset {
  flex: 0 0 66.6666666667%;
  width: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 66.6666666667%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-8 {
  flex: 0 0 12.5%;
  width: 12.5%;
  max-width: 12.5%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-9 {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-9-center {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-9-offset {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  margin-left: 75%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-9 {
  flex: 0 0 11.1111111111%;
  width: 11.1111111111%;
  max-width: 11.1111111111%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-10 {
  flex: 0 0 83.3333333333%;
  width: 83.3333333333%;
  max-width: 83.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-10-center {
  flex: 0 0 83.3333333333%;
  width: 83.3333333333%;
  max-width: 83.3333333333%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-10-offset {
  flex: 0 0 83.3333333333%;
  width: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 83.3333333333%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-10 {
  flex: 0 0 10%;
  width: 10%;
  max-width: 10%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-11 {
  flex: 0 0 91.6666666667%;
  width: 91.6666666667%;
  max-width: 91.6666666667%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-11-center {
  flex: 0 0 91.6666666667%;
  width: 91.6666666667%;
  max-width: 91.6666666667%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-11-offset {
  flex: 0 0 91.6666666667%;
  width: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 91.6666666667%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-11 {
  flex: 0 0 9.0909090909%;
  width: 9.0909090909%;
  max-width: 9.0909090909%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-12 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-12-center {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-12-offset {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin-left: 100%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex .xs-col-12 {
  flex: 0 0 8.3333333333%;
  width: 8.3333333333%;
  max-width: 8.3333333333%;
  margin: 0;
  padding: calc(var(--grid-gutter) * 0.5);
}
@media (min-width: 576px) {
  .grid-flex .sm-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-1-center {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-1-offset {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin-left: 8.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-2-center {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-2-offset {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin-left: 16.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-2 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-3-center {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-3-offset {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin-left: 25%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-3 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-4-center {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-4-offset {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 33.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-4 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-5-center {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-5-offset {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 41.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-5 {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-6-center {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-6-offset {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-left: 50%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-6 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-7-center {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-7-offset {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 58.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-7 {
    flex: 0 0 14.2857142857%;
    width: 14.2857142857%;
    max-width: 14.2857142857%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-8-center {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-8-offset {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 66.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-8 {
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-9-center {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-9-offset {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin-left: 75%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-9 {
    flex: 0 0 11.1111111111%;
    width: 11.1111111111%;
    max-width: 11.1111111111%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-10-center {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-10-offset {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 83.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-10 {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-11-center {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-11-offset {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin-left: 91.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-11 {
    flex: 0 0 9.0909090909%;
    width: 9.0909090909%;
    max-width: 9.0909090909%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-12-center {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-12-offset {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-left: 100%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .sm-col-12 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
}
@media (min-width: 768px) {
  .grid-flex .md-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-1-center {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-1-offset {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin-left: 8.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-2-center {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-2-offset {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin-left: 16.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-2 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-3-center {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-3-offset {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin-left: 25%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-3 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-4-center {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-4-offset {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 33.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-4 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-5-center {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-5-offset {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 41.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-5 {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-6-center {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-6-offset {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-left: 50%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-6 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-7-center {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-7-offset {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 58.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-7 {
    flex: 0 0 14.2857142857%;
    width: 14.2857142857%;
    max-width: 14.2857142857%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-8-center {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-8-offset {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 66.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-8 {
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-9-center {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-9-offset {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin-left: 75%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-9 {
    flex: 0 0 11.1111111111%;
    width: 11.1111111111%;
    max-width: 11.1111111111%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-10-center {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-10-offset {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 83.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-10 {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-11-center {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-11-offset {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin-left: 91.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-11 {
    flex: 0 0 9.0909090909%;
    width: 9.0909090909%;
    max-width: 9.0909090909%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-12-center {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-12-offset {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-left: 100%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .md-col-12 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
}
@media (min-width: 992px) {
  .grid-flex .lg-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-1-center {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-1-offset {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin-left: 8.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-2-center {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-2-offset {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin-left: 16.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-2 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-3-center {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-3-offset {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin-left: 25%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-3 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-4-center {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-4-offset {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 33.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-4 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-5-center {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-5-offset {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 41.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-5 {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-6-center {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-6-offset {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-left: 50%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-6 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-7-center {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-7-offset {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 58.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-7 {
    flex: 0 0 14.2857142857%;
    width: 14.2857142857%;
    max-width: 14.2857142857%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-8-center {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-8-offset {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 66.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-8 {
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-9-center {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-9-offset {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin-left: 75%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-9 {
    flex: 0 0 11.1111111111%;
    width: 11.1111111111%;
    max-width: 11.1111111111%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-10-center {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-10-offset {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 83.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-10 {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-11-center {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-11-offset {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin-left: 91.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-11 {
    flex: 0 0 9.0909090909%;
    width: 9.0909090909%;
    max-width: 9.0909090909%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-12-center {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-12-offset {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-left: 100%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .lg-col-12 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
}
@media (min-width: 1200px) {
  .grid-flex .xl-1 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-1-center {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-1-offset {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin-left: 8.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-2 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-2-center {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-2-offset {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin-left: 16.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-2 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-3-center {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-3-offset {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin-left: 25%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-3 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-4 {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-4-center {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-4-offset {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: 33.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-4 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-5 {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-5-center {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-5-offset {
    flex: 0 0 41.6666666667%;
    width: 41.6666666667%;
    max-width: 41.6666666667%;
    margin-left: 41.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-5 {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-6-center {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-6-offset {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-left: 50%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-6 {
    flex: 0 0 16.6666666667%;
    width: 16.6666666667%;
    max-width: 16.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-7 {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-7-center {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-7-offset {
    flex: 0 0 58.3333333333%;
    width: 58.3333333333%;
    max-width: 58.3333333333%;
    margin-left: 58.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-7 {
    flex: 0 0 14.2857142857%;
    width: 14.2857142857%;
    max-width: 14.2857142857%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-8 {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-8-center {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-8-offset {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 66.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-8 {
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-9-center {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-9-offset {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin-left: 75%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-9 {
    flex: 0 0 11.1111111111%;
    width: 11.1111111111%;
    max-width: 11.1111111111%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-10 {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-10-center {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-10-offset {
    flex: 0 0 83.3333333333%;
    width: 83.3333333333%;
    max-width: 83.3333333333%;
    margin-left: 83.3333333333%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-10 {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-11 {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-11-center {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-11-offset {
    flex: 0 0 91.6666666667%;
    width: 91.6666666667%;
    max-width: 91.6666666667%;
    margin-left: 91.6666666667%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-11 {
    flex: 0 0 9.0909090909%;
    width: 9.0909090909%;
    max-width: 9.0909090909%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-12-center {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-12-offset {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-left: 100%;
    padding: calc(var(--grid-gutter) * 0.5);
  }
  .grid-flex .xl-col-12 {
    flex: 0 0 8.3333333333%;
    width: 8.3333333333%;
    max-width: 8.3333333333%;
    margin: 0;
    padding: calc(var(--grid-gutter) * 0.5);
  }
}
.grid-flex > * {
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%;
  padding: calc(var(--grid-gutter) * 0.5);
}
.grid-flex:not(:last-child) {
  margin-bottom: calc(var(--grid-gutter) * 0.5);
}
.grid-flex.gapless {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}
.grid-flex.gapless > * {
  padding: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end-vertical {
  align-content: flex-end;
}

.justify-center-vertical {
  align-content: center;
}

.justify-between-vertical {
  align-content: space-between;
}

.justify-around-vertical {
  align-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1rem 1rem;
  width: 100%;
  max-width: 1000px;
}
@media (min-width: 992px) {
  .header-container {
    flex-direction: column;
  }
}

.header-brand-column {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.header-brand {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
@media (hover: hover) and (pointer: fine) {
  .header-brand:hover .logo {
    opacity: 0.8;
  }
}
.header-brand .mark {
  flex: 0 0 42px;
}
.header-brand .mark img {
  display: block;
}
.header-brand .logo {
  color: var(--white-color);
  flex: 1;
  line-height: 1;
  transition: opacity var(--base-transition);
}
.header-brand .logo .amg {
  font-size: 0.75em;
  margin-bottom: 0.5em;
}
.header-brand .logo .prior {
  font-size: 1.142857em;
  font-weight: 700;
}

.header-burger-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-left: 1rem;
}
@media (min-width: 992px) {
  .header-burger-column {
    display: none;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  padding: 3rem 1rem;
  width: 100%;
  max-width: 1000px;
}

.footer-left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 0 0 auto;
}

.footer-brand {
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
}
@media (hover: hover) and (pointer: fine) {
  .footer-brand:hover .prior {
    color: var(--white-color);
    opacity: 0.8;
  }
}
.footer-brand .mark {
  flex: 0 0 42px;
}
.footer-brand .mark img {
  display: block;
}
.footer-brand .prior {
  font-size: 1.142857em;
  font-weight: 700;
  transition: opacity var(--base-transition);
}

.footer-address {
  color: var(--white-color);
  font-size: 0.875rem;
  text-align: center;
}

.footer-contact {
  color: var(--white-color);
  font-size: 1.125rem;
}
.footer-contact li {
  display: flex;
  align-items: center;
  gap: 0.25em;
}
.footer-contact li::before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1em;
  height: 1.25em;
}
.footer-contact li.tel::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M7.6,10.8C7.4,10.2,7.6,9.6,8,9.1l0.1-0.1c0.4-0.4,1.1-0.8,1.2-1.4c0.1-0.6,0-1.3-0.2-1.8C9,5.5,8.9,5.1,8.8,4.7C8.7,4,8.5,3.1,8.2,2.6C7.7,2,6.9,1.8,6.2,2.1c0,0-4.1,1.4-3.4,5.2c0,0,1,10.8,12.3,14.4c0,0,4.1,1.1,5.5-1.3c0.3-0.5,0.6-1.3,0.6-1.9c0-0.6-0.3-1-0.8-1.3c-0.6-0.4-1.1-0.8-1.7-1.3c-0.6-0.4-1.2-0.9-1.9-0.8c-0.5,0.1-1,0.5-1.3,0.8c-0.3,0.2-0.5,0.4-0.8,0.6C14.3,17,13.6,17,13,16.7C11.6,15.8,8.7,13.7,7.6,10.8z"/%3e%3cpath d="M15.5,2c-0.5-0.1-1,0.3-1,0.9l0,0.1c0,0.4,0.3,0.8,0.8,0.9c2.6,0.3,4.5,2.3,4.9,4.9c0.1,0.4,0.4,0.8,0.9,0.8l0.1,0c0.5,0,0.9-0.4,0.9-1C21.7,5.1,19,2.4,15.5,2z"/%3e%3cpath d="M14.5,6.7l0,0.1c0,0.4,0.2,0.7,0.5,0.8c0.6,0.2,1.1,0.7,1.3,1.3c0.1,0.3,0.4,0.5,0.8,0.5h0.1c0.6,0,1-0.5,0.8-1.1c-0.4-1.2-1.3-2.1-2.5-2.5C15.1,5.7,14.5,6.1,14.5,6.7z"/%3e%3c/svg%3e');
}
.footer-contact li.fax {
  margin: 0.5em 0 0;
}
.footer-contact li.fax::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M20.8,8.2V5c0-0.3-0.1-0.6-0.4-0.9l-1.8-1.8C18.4,2.1,18.1,2,17.7,2H8.2C7.6,2,7,2.6,7,3.2v17.5C7,21.4,7.6,22,8.2,22h12.5c0.7,0,1.2-0.6,1.2-1.2V9.5C22,8.8,21.4,8.2,20.8,8.2z M13.2,18.9c0,0.3-0.3,0.6-0.6,0.6h-1.2c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6V18.9z M13.2,13.9c0,0.3-0.3,0.6-0.6,0.6h-1.2c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6V13.9z M18.2,18.9c0,0.3-0.3,0.6-0.6,0.6h-1.2c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6V18.9z M18.2,13.9c0,0.3-0.3,0.6-0.6,0.6h-1.2c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.2c0.3,0,0.6,0.3,0.6,0.6V13.9z M18.2,9.5H9.5v-5h6.2v1.9C15.8,6.7,16,7,16.4,7h1.9V9.5z M4.5,7H3.2C2.6,7,2,7.6,2,8.2v12.5C2,21.4,2.6,22,3.2,22h1.2c0.7,0,1.2-0.6,1.2-1.2V8.2C5.8,7.6,5.2,7,4.5,7z"/%3e%3c/svg%3e');
}

.footer-right-column {
  display: block;
}

.footer-utilities-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
}
.footer-utilities-list li a {
  color: var(--white-color);
  font-weight: 500;
  transition: opacity var(--base-transition);
}
@media (hover: hover) and (pointer: fine) {
  .footer-utilities-list li a:hover {
    opacity: 0.8;
  }
}

.footer-legal {
  background-color: var(--accent-color);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.footer-legal-copyright {
  color: var(--white-color);
  font-size: 0.75rem;
}

.burger {
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M3,17h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,19H3c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,17h18H3z M3,11l18,0c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,13L3,13c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,11l18,0L3,11z M3,5h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,7H3C2.4,7,2,6.6,2,6c0-0.5,0.4-0.9,0.9-1L3,5h18H3z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  box-shadow: var(--box-shadow);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color var(--base-transition), background-image var(--base-transition);
  width: 40px;
  height: 40px;
}
@media (hover: hover) and (pointer: fine) {
  .burger:hover {
    background-color: #f8f9fa;
  }
}
.burger[aria-pressed=true] {
  background-color: #2e2f33;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M4.4,4.6L4.4,4.6c0.3-0.4,0.8-0.4,1-0.2l0.1,0.1l6.5,6.5l6.5-6.5c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1L13.1,12l6.5,6.5c0.3,0.3,0.3,0.7,0.1,1l-0.1,0.1c-0.3,0.3-0.7,0.3-1,0.1l-0.1-0.1L12,13.1l-6.5,6.5c-0.3,0.3-0.8,0.3-1.1,0c-0.3-0.3-0.3-0.8,0-1.1l6.5-6.5L4.5,5.5C4.2,5.3,4.2,4.8,4.4,4.6L4.4,4.6L4.4,4.6z"/%3e%3c/svg%3e');
}

.globalnav-container {
  width: 100%;
}
@media (min-width: 992px) {
  .globalnav-container {
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    max-width: 1000px;
  }
}

ul.globalnav-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 992px) {
  ul.globalnav-list {
    flex-direction: row;
    justify-content: space-between;
  }
}

li.globalnav-item {
  position: relative;
}
@media (min-width: 992px) {
  li.globalnav-item {
    flex: 1;
  }
  li.globalnav-item::before, li.globalnav-item::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    height: 60%;
    transform: translateY(-50%);
  }
  li.globalnav-item::before {
    border-left: 1px solid #fff;
  }
  li.globalnav-item:last-child::after {
    border-right: 1px solid #fff;
    right: 0;
  }
}

.globalnav-link {
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
  padding: 1em;
  transition: opacity var(--base-transition);
}
@media (min-width: 992px) {
  .globalnav-link {
    justify-content: center;
  }
}
@media (hover: hover) and (pointer: fine) {
  .globalnav-link:hover {
    color: var(--white-color);
    opacity: 0.8;
  }
}
.globalnav-link::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M11.7,3c0.4,0,0.7,0.3,0.7,0.6l0,0.1l0,7.3h7.3c0.4,0,0.8,0.3,0.8,0.8	c0,0.4-0.3,0.7-0.6,0.7l-0.1,0h-7.3l0,7.2c0,0.4-0.3,0.8-0.7,0.8c-0.4,0-0.7-0.3-0.7-0.6l0-0.1l0-7.2H3.8c-0.4,0-0.8-0.3-0.8-0.8 c0-0.4,0.3-0.7,0.6-0.7l0.1,0H11l0-7.2C11,3.3,11.3,3,11.7,3z"/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  display: inline-block;
  flex: 0 0 auto;
  width: 1em;
  height: 1em;
}
@media (min-width: 992px) {
  .globalnav-link::after {
    content: none;
  }
}
.globalnav-link:is(a)::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}

.globalnav-child-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
@media (min-width: 992px) {
  .globalnav-child-wrapper {
    cursor: pointer;
    display: none;
    max-height: none;
    overflow: visible;
    padding-top: 0.6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 136%;
  }
  .globalnav-item:hover .globalnav-child-wrapper {
    display: block;
    animation: show 0.2s ease-out;
  }
}

.globalnav-child li {
  position: relative;
}
.globalnav-child li a {
  background-color: #006f32e6;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1em 1em 2em;
  transition: background-color var(--base-transition);
}
@media (min-width: 992px) {
  .globalnav-child li a {
    background-color: #000000e6;
  }
}
@media (min-width: 992px) and (hover: hover) and (pointer: fine) {
  .globalnav-child li a:hover {
    background-color: #000000f5;
  }
}
.globalnav-child li a::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M5.7,14.1l0-2l8.2,0l-3.7-3.7L11.7,7l7.1,7.1L5.7,14.1z"/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  display: inline-block;
  flex: 0 0 auto;
  width: 1em;
  height: 1em;
}
@media (min-width: 992px) {
  .globalnav-child::before {
    content: "";
    border: 10px solid transparent;
    border-bottom: 10px solid #000000e6;
    position: absolute;
    top: -0.6rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
  }
}

.breadlist-wrapper {
  background-color: #fff;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 1rem;
  padding: 1em 0;
}

.breadlist-container {
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 1000px;
}

ol.breadlist {
  display: flex;
  align-items: center;
  font-size: 0.725rem;
}
ol.breadlist li {
  color: inherit;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ol.breadlist li + li::before {
  content: "＞";
  color: #6c757d;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
ol.breadlist li a {
  color: #6c757d;
}
ol.breadlist li a::active, ol.breadlist li a::visited {
  color: inherit;
}
ol.breadlist li.current {
  color: #333;
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}

.heading {
  margin-bottom: 2rem;
}
.heading:not(:first-child) {
  margin-top: var(--xlarge-spacing);
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}
h2.top-heading {
  border-top: 1px solid var(--base-color);
  border-bottom: 1px solid var(--base-color);
  color: var(--base-color);
  margin: 0 0 1em;
  padding: 0.5em;
}
h2.heading {
  background-color: var(--gray-200-color);
  border-radius: 8px;
  padding: 0.75em;
}
h2.heading:not(:first-child) {
  margin-top: var(--exlarge-spacing);
}

h3 {
  font-size: 1.75rem;
}
h3.heading {
  border-bottom: 2px solid var(--base-color);
  padding: 0 0 0.5em;
  position: relative;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.lead {
  font-size: inherit;
  letter-spacing: 0;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}
.lead:not(:last-child) {
  margin-bottom: var(--spacing);
}

.image:not(:last-child) {
  margin-bottom: var(--spacing);
}

.notes {
  padding-left: 1em;
  text-indent: -1em;
}
.notes::before {
  content: "※";
  font-family: sans-serif;
}

.code-block-container {
  margin: 2rem 0;
  position: relative;
}
.code-block-container .type {
  background-color: var(--gray-100-color);
  color: var(--gray-dark-color);
  font-size: 0.75em;
  font-weight: 700;
  padding: 0.375em 1em;
  position: absolute;
  top: 0;
  left: 0;
}

code {
  background-color: rgba(33, 90, 160, 0.0705882353);
  border-radius: 4px;
  color: var(--gray-dark-color);
  font-family: "SFMono-Regular", "Consolas", "Liberation Mono", "Menlo", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.9;
  padding: 0.2em 0.4em;
  vertical-align: 0.08em;
}

pre {
  background-color: #1a2638;
  overflow-x: auto;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}
pre code {
  background-color: transparent;
  color: var(--white-color);
  padding: 0;
}

pre.dev {
  background-color: var(--gray-100-color);
}
pre.dev code {
  background-color: transparent;
  color: var(--gray-700-color);
  font-size: 0.75rem;
}

.btn {
  background-color: var(--accent-color);
  border: 1px solid transparent;
  border-radius: 2em;
  color: var(--white-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--base-transition), color var(--base-transition);
  vertical-align: middle;
  padding: 0.825em 1.25em;
}
.btn::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: 0 0 auto;
  margin-left: 0.75rem;
  width: 1em;
  height: 1em;
}
.btn.small {
  font-size: 0.8125rem;
  padding: 0.575em 1.25em;
}
.btn.normal {
  font-size: 1rem;
  padding: 0.825em 1.25em;
}
.btn.large {
  font-size: 1.25rem;
  padding: 0.825em 1.125em;
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    background-color: var(--gray-700-color);
    color: var(--white-color);
  }
}
.btn.base {
  background-color: var(--base-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.base:hover {
    background: var(--base-light-color);
  }
}
.btn.accent {
  background-color: var(--accent-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.accent:hover {
    background: var(--accent-light-color);
    color: #fff;
  }
}
.btn.uno {
  background-color: var(--uno-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.uno:hover {
    background: var(--uno-light-color);
    color: #fff;
  }
}
.btn.dos {
  background-color: var(--dos-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.dos:hover {
    background: var(--dos-light-color);
    color: #fff;
  }
}
.btn.tres {
  background-color: var(--tres-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.tres:hover {
    background: var(--tres-light-color);
    color: #fff;
  }
}
.btn.cuatro {
  background-color: var(--cuatro-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.cuatro:hover {
    background: var(--cuatro-light-color);
    color: #fff;
  }
}
.btn.cinco {
  background-color: var(--cinco-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.cinco:hover {
    background: var(--cinco-light-color);
    color: #fff;
  }
}
.btn.first {
  background-color: var(--first-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.first:hover {
    background: var(--first-light-color);
  }
}
.btn.second {
  background-color: var(--second-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.second:hover {
    background: var(--second-light-color);
  }
}
.btn.third {
  background-color: var(--third-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.third:hover {
    background: var(--third-light-color);
  }
}
.btn.fourth {
  background-color: var(--fourth-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.fourth:hover {
    background: var(--fourth-light-color);
  }
}
.btn.five {
  background-color: var(--five-color);
  color: var(--white-color);
}
@media (hover: hover) and (pointer: fine) {
  .btn.five:hover {
    background: var(--five-light-color);
  }
}
.btn.outline {
  background-color: var(--white-color);
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
}
.btn.outline::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
@media (hover: hover) and (pointer: fine) {
  .btn.outline:hover {
    background-color: var(--accent-color);
    color: var(--white-color);
  }
  .btn.outline:hover::after {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
  }
}
.btn.arrow-right::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-base::after, .btn.arrow-right.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-accent::after, .btn.arrow-right.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-uno::after, .btn.arrow-right.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-dos::after, .btn.arrow-right.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-tres::after, .btn.arrow-right.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cuatro::after, .btn.arrow-right.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cinco::after, .btn.arrow-right.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-first::after, .btn.arrow-right.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-second::after, .btn.arrow-right.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-third::after, .btn.arrow-right.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-fourth::after, .btn.arrow-right.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-five::after, .btn.arrow-right.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-base-light::after, .btn.arrow-right.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-accent-light::after, .btn.arrow-right.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-uno-light::after, .btn.arrow-right.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-dos-light::after, .btn.arrow-right.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-tres-light::after, .btn.arrow-right.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cuatro-light::after, .btn.arrow-right.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cinco-light::after, .btn.arrow-right.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-first-light::after, .btn.arrow-right.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-second-light::after, .btn.arrow-right.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-third-light::after, .btn.arrow-right.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-fourth-light::after, .btn.arrow-right.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-five-light::after, .btn.arrow-right.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-base-dark::after, .btn.arrow-right.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-accent-dark::after, .btn.arrow-right.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-uno-dark::after, .btn.arrow-right.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-dos-dark::after, .btn.arrow-right.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-tres-dark::after, .btn.arrow-right.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cuatro-dark::after, .btn.arrow-right.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cinco-dark::after, .btn.arrow-right.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-first-dark::after, .btn.arrow-right.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-second-dark::after, .btn.arrow-right.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-third-dark::after, .btn.arrow-right.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-fourth-dark::after, .btn.arrow-right.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-five-dark::after, .btn.arrow-right.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-100::after, .btn.arrow-right.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-200::after, .btn.arrow-right.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-300::after, .btn.arrow-right.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-400::after, .btn.arrow-right.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-500::after, .btn.arrow-right.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-600::after, .btn.arrow-right.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-700::after, .btn.arrow-right.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-800::after, .btn.arrow-right.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-900::after, .btn.arrow-right.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-gray-dark::after, .btn.arrow-right.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-black::after, .btn.arrow-right.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-white::after, .btn.arrow-right.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-red::after, .btn.arrow-right.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-yellow::after, .btn.arrow-right.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-orange::after, .btn.arrow-right.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-green::after, .btn.arrow-right.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-blue::after, .btn.arrow-right.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-pink::after, .btn.arrow-right.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-purple::after, .btn.arrow-right.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.arrow-right.ic-cyan::after, .btn.arrow-right.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.btn.pdf::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-base::after, .btn.pdf.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-accent::after, .btn.pdf.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-uno::after, .btn.pdf.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-dos::after, .btn.pdf.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-tres::after, .btn.pdf.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cuatro::after, .btn.pdf.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cinco::after, .btn.pdf.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-first::after, .btn.pdf.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-second::after, .btn.pdf.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-third::after, .btn.pdf.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-fourth::after, .btn.pdf.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-five::after, .btn.pdf.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-base-light::after, .btn.pdf.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-accent-light::after, .btn.pdf.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-uno-light::after, .btn.pdf.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-dos-light::after, .btn.pdf.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-tres-light::after, .btn.pdf.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cuatro-light::after, .btn.pdf.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cinco-light::after, .btn.pdf.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-first-light::after, .btn.pdf.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-second-light::after, .btn.pdf.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-third-light::after, .btn.pdf.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-fourth-light::after, .btn.pdf.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-five-light::after, .btn.pdf.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-base-dark::after, .btn.pdf.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-accent-dark::after, .btn.pdf.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-uno-dark::after, .btn.pdf.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-dos-dark::after, .btn.pdf.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-tres-dark::after, .btn.pdf.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cuatro-dark::after, .btn.pdf.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cinco-dark::after, .btn.pdf.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-first-dark::after, .btn.pdf.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-second-dark::after, .btn.pdf.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-third-dark::after, .btn.pdf.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-fourth-dark::after, .btn.pdf.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-five-dark::after, .btn.pdf.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-100::after, .btn.pdf.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-200::after, .btn.pdf.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-300::after, .btn.pdf.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-400::after, .btn.pdf.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-500::after, .btn.pdf.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-600::after, .btn.pdf.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-700::after, .btn.pdf.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-800::after, .btn.pdf.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-900::after, .btn.pdf.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-gray-dark::after, .btn.pdf.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-black::after, .btn.pdf.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-white::after, .btn.pdf.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-red::after, .btn.pdf.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-yellow::after, .btn.pdf.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-orange::after, .btn.pdf.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-green::after, .btn.pdf.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-blue::after, .btn.pdf.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-pink::after, .btn.pdf.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-purple::after, .btn.pdf.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.pdf.ic-cyan::after, .btn.pdf.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.btn.word::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-base::after, .btn.word.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-accent::after, .btn.word.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-uno::after, .btn.word.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-dos::after, .btn.word.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-tres::after, .btn.word.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cuatro::after, .btn.word.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cinco::after, .btn.word.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-first::after, .btn.word.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-second::after, .btn.word.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-third::after, .btn.word.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-fourth::after, .btn.word.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-five::after, .btn.word.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-base-light::after, .btn.word.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-accent-light::after, .btn.word.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-uno-light::after, .btn.word.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-dos-light::after, .btn.word.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-tres-light::after, .btn.word.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cuatro-light::after, .btn.word.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cinco-light::after, .btn.word.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-first-light::after, .btn.word.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-second-light::after, .btn.word.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-third-light::after, .btn.word.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-fourth-light::after, .btn.word.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-five-light::after, .btn.word.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-base-dark::after, .btn.word.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-accent-dark::after, .btn.word.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-uno-dark::after, .btn.word.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-dos-dark::after, .btn.word.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-tres-dark::after, .btn.word.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cuatro-dark::after, .btn.word.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cinco-dark::after, .btn.word.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-first-dark::after, .btn.word.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-second-dark::after, .btn.word.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-third-dark::after, .btn.word.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-fourth-dark::after, .btn.word.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-five-dark::after, .btn.word.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-100::after, .btn.word.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-200::after, .btn.word.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-300::after, .btn.word.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-400::after, .btn.word.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-500::after, .btn.word.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-600::after, .btn.word.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-700::after, .btn.word.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-800::after, .btn.word.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-900::after, .btn.word.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-gray-dark::after, .btn.word.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-black::after, .btn.word.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-white::after, .btn.word.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-red::after, .btn.word.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-yellow::after, .btn.word.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-orange::after, .btn.word.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-green::after, .btn.word.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-blue::after, .btn.word.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-pink::after, .btn.word.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-purple::after, .btn.word.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.word.ic-cyan::after, .btn.word.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.btn.excel::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-base::after, .btn.excel.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-accent::after, .btn.excel.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-uno::after, .btn.excel.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-dos::after, .btn.excel.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-tres::after, .btn.excel.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cuatro::after, .btn.excel.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cinco::after, .btn.excel.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-first::after, .btn.excel.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-second::after, .btn.excel.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-third::after, .btn.excel.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-fourth::after, .btn.excel.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-five::after, .btn.excel.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-base-light::after, .btn.excel.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-accent-light::after, .btn.excel.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-uno-light::after, .btn.excel.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-dos-light::after, .btn.excel.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-tres-light::after, .btn.excel.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cuatro-light::after, .btn.excel.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cinco-light::after, .btn.excel.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-first-light::after, .btn.excel.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-second-light::after, .btn.excel.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-third-light::after, .btn.excel.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-fourth-light::after, .btn.excel.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-five-light::after, .btn.excel.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-base-dark::after, .btn.excel.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-accent-dark::after, .btn.excel.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-uno-dark::after, .btn.excel.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-dos-dark::after, .btn.excel.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-tres-dark::after, .btn.excel.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cuatro-dark::after, .btn.excel.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cinco-dark::after, .btn.excel.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-first-dark::after, .btn.excel.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-second-dark::after, .btn.excel.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-third-dark::after, .btn.excel.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-fourth-dark::after, .btn.excel.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-five-dark::after, .btn.excel.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-100::after, .btn.excel.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-200::after, .btn.excel.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-300::after, .btn.excel.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-400::after, .btn.excel.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-500::after, .btn.excel.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-600::after, .btn.excel.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-700::after, .btn.excel.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-800::after, .btn.excel.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-900::after, .btn.excel.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-gray-dark::after, .btn.excel.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-black::after, .btn.excel.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-white::after, .btn.excel.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-red::after, .btn.excel.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-yellow::after, .btn.excel.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-orange::after, .btn.excel.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-green::after, .btn.excel.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-blue::after, .btn.excel.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-pink::after, .btn.excel.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-purple::after, .btn.excel.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.excel.ic-cyan::after, .btn.excel.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.btn.document::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-base::after, .btn.document.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-accent::after, .btn.document.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-uno::after, .btn.document.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-dos::after, .btn.document.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-tres::after, .btn.document.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cuatro::after, .btn.document.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cinco::after, .btn.document.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-first::after, .btn.document.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-second::after, .btn.document.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-third::after, .btn.document.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-fourth::after, .btn.document.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-five::after, .btn.document.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-base-light::after, .btn.document.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-accent-light::after, .btn.document.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-uno-light::after, .btn.document.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-dos-light::after, .btn.document.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-tres-light::after, .btn.document.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cuatro-light::after, .btn.document.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cinco-light::after, .btn.document.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-first-light::after, .btn.document.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-second-light::after, .btn.document.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-third-light::after, .btn.document.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-fourth-light::after, .btn.document.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-five-light::after, .btn.document.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-base-dark::after, .btn.document.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-accent-dark::after, .btn.document.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-uno-dark::after, .btn.document.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-dos-dark::after, .btn.document.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-tres-dark::after, .btn.document.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cuatro-dark::after, .btn.document.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cinco-dark::after, .btn.document.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-first-dark::after, .btn.document.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-second-dark::after, .btn.document.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-third-dark::after, .btn.document.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-fourth-dark::after, .btn.document.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-five-dark::after, .btn.document.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-100::after, .btn.document.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-200::after, .btn.document.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-300::after, .btn.document.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-400::after, .btn.document.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-500::after, .btn.document.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-600::after, .btn.document.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-700::after, .btn.document.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-800::after, .btn.document.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-900::after, .btn.document.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-gray-dark::after, .btn.document.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-black::after, .btn.document.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-white::after, .btn.document.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-red::after, .btn.document.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-yellow::after, .btn.document.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-orange::after, .btn.document.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-green::after, .btn.document.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-blue::after, .btn.document.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-pink::after, .btn.document.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-purple::after, .btn.document.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.document.ic-cyan::after, .btn.document.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.btn.open-new-window::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-base::after, .btn.open-new-window.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-accent::after, .btn.open-new-window.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-uno::after, .btn.open-new-window.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-dos::after, .btn.open-new-window.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-tres::after, .btn.open-new-window.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cuatro::after, .btn.open-new-window.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cinco::after, .btn.open-new-window.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-first::after, .btn.open-new-window.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-second::after, .btn.open-new-window.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-third::after, .btn.open-new-window.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-fourth::after, .btn.open-new-window.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-five::after, .btn.open-new-window.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-base-light::after, .btn.open-new-window.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-accent-light::after, .btn.open-new-window.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-uno-light::after, .btn.open-new-window.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-dos-light::after, .btn.open-new-window.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-tres-light::after, .btn.open-new-window.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cuatro-light::after, .btn.open-new-window.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cinco-light::after, .btn.open-new-window.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-first-light::after, .btn.open-new-window.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-second-light::after, .btn.open-new-window.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-third-light::after, .btn.open-new-window.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-fourth-light::after, .btn.open-new-window.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-five-light::after, .btn.open-new-window.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-base-dark::after, .btn.open-new-window.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-accent-dark::after, .btn.open-new-window.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-uno-dark::after, .btn.open-new-window.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-dos-dark::after, .btn.open-new-window.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-tres-dark::after, .btn.open-new-window.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cuatro-dark::after, .btn.open-new-window.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cinco-dark::after, .btn.open-new-window.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-first-dark::after, .btn.open-new-window.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-second-dark::after, .btn.open-new-window.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-third-dark::after, .btn.open-new-window.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-fourth-dark::after, .btn.open-new-window.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-five-dark::after, .btn.open-new-window.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-100::after, .btn.open-new-window.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-200::after, .btn.open-new-window.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-300::after, .btn.open-new-window.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-400::after, .btn.open-new-window.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-500::after, .btn.open-new-window.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-600::after, .btn.open-new-window.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-700::after, .btn.open-new-window.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-800::after, .btn.open-new-window.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-900::after, .btn.open-new-window.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-gray-dark::after, .btn.open-new-window.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-black::after, .btn.open-new-window.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-white::after, .btn.open-new-window.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-red::after, .btn.open-new-window.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-yellow::after, .btn.open-new-window.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-orange::after, .btn.open-new-window.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-green::after, .btn.open-new-window.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-blue::after, .btn.open-new-window.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-pink::after, .btn.open-new-window.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-purple::after, .btn.open-new-window.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.open-new-window.ic-cyan::after, .btn.open-new-window.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.btn.mail::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-base::after, .btn.mail.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-accent::after, .btn.mail.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-uno::after, .btn.mail.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-dos::after, .btn.mail.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-tres::after, .btn.mail.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cuatro::after, .btn.mail.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cinco::after, .btn.mail.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-first::after, .btn.mail.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-second::after, .btn.mail.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-third::after, .btn.mail.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-fourth::after, .btn.mail.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-five::after, .btn.mail.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-base-light::after, .btn.mail.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-accent-light::after, .btn.mail.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-uno-light::after, .btn.mail.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-dos-light::after, .btn.mail.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-tres-light::after, .btn.mail.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cuatro-light::after, .btn.mail.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cinco-light::after, .btn.mail.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-first-light::after, .btn.mail.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-second-light::after, .btn.mail.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-third-light::after, .btn.mail.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-fourth-light::after, .btn.mail.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-five-light::after, .btn.mail.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-base-dark::after, .btn.mail.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-accent-dark::after, .btn.mail.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-uno-dark::after, .btn.mail.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-dos-dark::after, .btn.mail.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-tres-dark::after, .btn.mail.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cuatro-dark::after, .btn.mail.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cinco-dark::after, .btn.mail.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-first-dark::after, .btn.mail.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-second-dark::after, .btn.mail.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-third-dark::after, .btn.mail.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-fourth-dark::after, .btn.mail.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-five-dark::after, .btn.mail.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-100::after, .btn.mail.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-200::after, .btn.mail.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-300::after, .btn.mail.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-400::after, .btn.mail.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-500::after, .btn.mail.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-600::after, .btn.mail.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-700::after, .btn.mail.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-800::after, .btn.mail.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-900::after, .btn.mail.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-gray-dark::after, .btn.mail.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-black::after, .btn.mail.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-white::after, .btn.mail.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-red::after, .btn.mail.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-yellow::after, .btn.mail.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-orange::after, .btn.mail.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-green::after, .btn.mail.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-blue::after, .btn.mail.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-pink::after, .btn.mail.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-purple::after, .btn.mail.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.mail.ic-cyan::after, .btn.mail.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.btn.calendar::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-base::after, .btn.calendar.ic-base::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-accent::after, .btn.calendar.ic-accent::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-uno::after, .btn.calendar.ic-uno::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-dos::after, .btn.calendar.ic-dos::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-tres::after, .btn.calendar.ic-tres::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cuatro::after, .btn.calendar.ic-cuatro::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cinco::after, .btn.calendar.ic-cinco::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-first::after, .btn.calendar.ic-first::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-second::after, .btn.calendar.ic-second::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-third::after, .btn.calendar.ic-third::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-fourth::after, .btn.calendar.ic-fourth::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-five::after, .btn.calendar.ic-five::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-base-light::after, .btn.calendar.ic-base-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-accent-light::after, .btn.calendar.ic-accent-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-uno-light::after, .btn.calendar.ic-uno-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-dos-light::after, .btn.calendar.ic-dos-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-tres-light::after, .btn.calendar.ic-tres-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cuatro-light::after, .btn.calendar.ic-cuatro-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cinco-light::after, .btn.calendar.ic-cinco-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-first-light::after, .btn.calendar.ic-first-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-second-light::after, .btn.calendar.ic-second-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-third-light::after, .btn.calendar.ic-third-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-fourth-light::after, .btn.calendar.ic-fourth-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-five-light::after, .btn.calendar.ic-five-light::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-base-dark::after, .btn.calendar.ic-base-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-accent-dark::after, .btn.calendar.ic-accent-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-uno-dark::after, .btn.calendar.ic-uno-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-dos-dark::after, .btn.calendar.ic-dos-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-tres-dark::after, .btn.calendar.ic-tres-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cuatro-dark::after, .btn.calendar.ic-cuatro-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cinco-dark::after, .btn.calendar.ic-cinco-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-first-dark::after, .btn.calendar.ic-first-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-second-dark::after, .btn.calendar.ic-second-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-third-dark::after, .btn.calendar.ic-third-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-fourth-dark::after, .btn.calendar.ic-fourth-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-five-dark::after, .btn.calendar.ic-five-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-100::after, .btn.calendar.ic-gray-100::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-200::after, .btn.calendar.ic-gray-200::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-300::after, .btn.calendar.ic-gray-300::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-400::after, .btn.calendar.ic-gray-400::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-500::after, .btn.calendar.ic-gray-500::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-600::after, .btn.calendar.ic-gray-600::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-700::after, .btn.calendar.ic-gray-700::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-800::after, .btn.calendar.ic-gray-800::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-900::after, .btn.calendar.ic-gray-900::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-gray-dark::after, .btn.calendar.ic-gray-dark::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-black::after, .btn.calendar.ic-black::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-white::after, .btn.calendar.ic-white::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-red::after, .btn.calendar.ic-red::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-yellow::after, .btn.calendar.ic-yellow::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-orange::after, .btn.calendar.ic-orange::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-green::after, .btn.calendar.ic-green::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-blue::after, .btn.calendar.ic-blue::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-pink::after, .btn.calendar.ic-pink::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-purple::after, .btn.calendar.ic-purple::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.calendar.ic-cyan::after, .btn.calendar.ic-cyan::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.btn.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.input {
  background-color: var(--white-color);
  border: 1px solid var(--gray-500-color);
  border-radius: 6px;
  box-shadow: none;
  color: var(--gray-dark-color);
  font-size: var(--form-font-size);
  line-height: 1;
  padding: 0.75em 1em;
  width: 100%;
}
.input::placeholder {
  font-size: 0.75em;
  transition: color var(--base-transition);
}
.input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.input:-ms-input-placeholder {
  color: #6c757d;
}
.input::-webkit-input-placeholder {
  color: #6c757d;
}
.input::-ms-expand {
  background-color: var(--white-color);
  border: 0;
}
.input:focus, .input:focus:not(:placeholder-shown) {
  background-color: var(--white-color);
  border-color: var(--base-color);
  box-shadow: 0 0 0 0.25rem #005fcc40;
  outline: 0;
}
.input:focus::placeholder, .input:focus:not(:placeholder-shown)::placeholder {
  color: transparent;
}
.input:not(:placeholder-shown):not([type=file]) {
  background-color: var(--gray-100-color);
  border-color: var(--base-color);
}
.input:disabled {
  background-color: var(--gray-100-color);
  cursor: not-allowed;
  color: var(--gray-600-color);
  border-color: var(--gray-400-color);
}
textarea.input {
  font-family: system-ui, -apple-system, Robot, "Helvetica Neue", "Helvetica", "Hiragino Sans", "Noto Sans CJK JP", "メイリオ", Meiryo, Arial, sans-serif;
  line-height: 1.6;
  height: auto;
  overflow-wrap: break-word;
  padding: 1em;
  resize: vertical;
  width: 100%;
}

.input[type=file] {
  font-size: 1rem;
  overflow: hidden;
  padding: 0.75em 0.5em;
}
.input[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.input::file-selector-button {
  background-color: #f8f9fa;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #333;
  margin: -1em -0.75em;
  margin-inline-end: 1em;
  padding: 0.75em 1em;
  pointer-events: none;
}
.input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--gray-200-color);
}
.input::-webkit-file-upload-button {
  background-color: #f8f9fa;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #333;
  margin: -1em -0.75em;
  margin-inline-end: 1em;
  padding: 0.75em 1em;
  pointer-events: none;
}
.input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--gray-200-color);
}

select.select {
  background-color: var(--white-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M17.9,10c1.1,0,1.6-1.1,0.9-1.8l-5.5-5.6c-0.7-0.7-1.9-0.7-2.6,0L5.2,8.2C4.5,8.9,5,10,6.1,10H17.9z"/%3e%3cpath d="M6.1,14c-1.1,0-1.6,1.1-0.9,1.8l5.5,5.6c0.7,0.7,1.9,0.7,2.6,0l5.5-5.6c0.7-0.7,0.1-1.8-0.9-1.8H6.1z"/%3e%3c/svg%3e');
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 0.75em;
  border: 1px solid var(--gray-500-color);
  border-radius: 6px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: var(--form-font-size);
  outline: none;
  padding: 0.75em 3em 0.75em 1em;
  position: relative;
  transition: border-color var(--base-transition);
  width: auto;
}
select.select::-moz-focus-inner {
  border: 0;
}
select.select:focus {
  background-color: var(--white-color);
  border-color: var(--base-color);
  box-shadow: 0 0 0 0.25rem #005fcc40;
  outline: 0;
}
select.select:disabled {
  background-color: var(--gray-100-color);
  cursor: not-allowed;
  color: var(--gray-600-color);
  border-color: var(--gray-400-color);
}

label.checkbox,
label.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--form-font-size);
}
label.checkbox:has(:disabled),
label.radio:has(:disabled) {
  cursor: not-allowed;
}
label.checkbox input,
label.radio input {
  all: revert;
  cursor: pointer;
  margin: 0 0.5em 0 0;
  width: 1.25em;
  height: 1.25em;
}
label.checkbox input:disabled,
label.radio input:disabled {
  cursor: not-allowed;
}
label.checkbox input:focus,
label.radio input:focus {
  box-shadow: 0 0 0 0.125rem #005fcc40;
}

label.form-name {
  display: block;
  margin-bottom: 0.5em;
}

.form-field + .form-field {
  margin-top: 2rem;
}

.form-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.form-row.vertical {
  flex-direction: column;
}

.form-grid {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  gap: var(--form-grid-gutter);
}
.form-grid > .xs-1 {
  grid-column: auto/span 1;
}
.form-grid > .xs-2 {
  grid-column: auto/span 2;
}
.form-grid > .xs-3 {
  grid-column: auto/span 3;
}
.form-grid > .xs-4 {
  grid-column: auto/span 4;
}
.form-grid > .xs-5 {
  grid-column: auto/span 5;
}
.form-grid > .xs-6 {
  grid-column: auto/span 6;
}
.form-grid > .xs-7 {
  grid-column: auto/span 7;
}
.form-grid > .xs-8 {
  grid-column: auto/span 8;
}
.form-grid > .xs-9 {
  grid-column: auto/span 9;
}
.form-grid > .xs-10 {
  grid-column: auto/span 10;
}
.form-grid > .xs-11 {
  grid-column: auto/span 11;
}
.form-grid > .xs-12 {
  grid-column: auto/span 12;
}
@media (min-width: 576px) {
  .form-grid > .sm-1 {
    grid-column: auto/span 1;
  }
  .form-grid > .sm-2 {
    grid-column: auto/span 2;
  }
  .form-grid > .sm-3 {
    grid-column: auto/span 3;
  }
  .form-grid > .sm-4 {
    grid-column: auto/span 4;
  }
  .form-grid > .sm-5 {
    grid-column: auto/span 5;
  }
  .form-grid > .sm-6 {
    grid-column: auto/span 6;
  }
  .form-grid > .sm-7 {
    grid-column: auto/span 7;
  }
  .form-grid > .sm-8 {
    grid-column: auto/span 8;
  }
  .form-grid > .sm-9 {
    grid-column: auto/span 9;
  }
  .form-grid > .sm-10 {
    grid-column: auto/span 10;
  }
  .form-grid > .sm-11 {
    grid-column: auto/span 11;
  }
  .form-grid > .sm-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 768px) {
  .form-grid > .md-1 {
    grid-column: auto/span 1;
  }
  .form-grid > .md-2 {
    grid-column: auto/span 2;
  }
  .form-grid > .md-3 {
    grid-column: auto/span 3;
  }
  .form-grid > .md-4 {
    grid-column: auto/span 4;
  }
  .form-grid > .md-5 {
    grid-column: auto/span 5;
  }
  .form-grid > .md-6 {
    grid-column: auto/span 6;
  }
  .form-grid > .md-7 {
    grid-column: auto/span 7;
  }
  .form-grid > .md-8 {
    grid-column: auto/span 8;
  }
  .form-grid > .md-9 {
    grid-column: auto/span 9;
  }
  .form-grid > .md-10 {
    grid-column: auto/span 10;
  }
  .form-grid > .md-11 {
    grid-column: auto/span 11;
  }
  .form-grid > .md-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 992px) {
  .form-grid > .lg-1 {
    grid-column: auto/span 1;
  }
  .form-grid > .lg-2 {
    grid-column: auto/span 2;
  }
  .form-grid > .lg-3 {
    grid-column: auto/span 3;
  }
  .form-grid > .lg-4 {
    grid-column: auto/span 4;
  }
  .form-grid > .lg-5 {
    grid-column: auto/span 5;
  }
  .form-grid > .lg-6 {
    grid-column: auto/span 6;
  }
  .form-grid > .lg-7 {
    grid-column: auto/span 7;
  }
  .form-grid > .lg-8 {
    grid-column: auto/span 8;
  }
  .form-grid > .lg-9 {
    grid-column: auto/span 9;
  }
  .form-grid > .lg-10 {
    grid-column: auto/span 10;
  }
  .form-grid > .lg-11 {
    grid-column: auto/span 11;
  }
  .form-grid > .lg-12 {
    grid-column: auto/span 12;
  }
}
@media (min-width: 1200px) {
  .form-grid > .xl-1 {
    grid-column: auto/span 1;
  }
  .form-grid > .xl-2 {
    grid-column: auto/span 2;
  }
  .form-grid > .xl-3 {
    grid-column: auto/span 3;
  }
  .form-grid > .xl-4 {
    grid-column: auto/span 4;
  }
  .form-grid > .xl-5 {
    grid-column: auto/span 5;
  }
  .form-grid > .xl-6 {
    grid-column: auto/span 6;
  }
  .form-grid > .xl-7 {
    grid-column: auto/span 7;
  }
  .form-grid > .xl-8 {
    grid-column: auto/span 8;
  }
  .form-grid > .xl-9 {
    grid-column: auto/span 9;
  }
  .form-grid > .xl-10 {
    grid-column: auto/span 10;
  }
  .form-grid > .xl-11 {
    grid-column: auto/span 11;
  }
  .form-grid > .xl-12 {
    grid-column: auto/span 12;
  }
}
.form-grid > * {
  grid-column: auto/span 12;
}
.form-grid:not(:last-child) {
  margin-bottom: var(--grid-gutter);
}
.form-grid.gapless {
  gap: 0;
}

ul.list li:nth-of-type(n+2),
ol.list li:nth-of-type(n+2) {
  margin-top: 0.8em;
}
ul.list:not(:last-child),
ol.list:not(:last-child) {
  margin-bottom: var(--spacing);
}
ul.list ul, ul.list ol,
ol.list ul,
ol.list ol {
  margin-left: 1em;
}
ul.list.indent,
ol.list.indent {
  margin-left: 1em;
}
ul.list.indent ul, ul.list.indent ol,
ol.list.indent ul,
ol.list.indent ol {
  margin-top: 0.8em;
}

ul.list.disc {
  list-style: disc outside;
  padding-left: 1.125em;
}
ul.list.disc ul {
  list-style: circle outside;
}
ul.list.square li, ul.list.circle li {
  line-height: inherit;
  padding-left: 1.25em;
  position: relative;
}
ul.list.square li:before, ul.list.circle li:before {
  content: "";
  color: inherit;
  display: inline-block;
  font-family: sans-serif;
  position: absolute;
  top: 0;
  left: 0;
}
ul.list.square li:before {
  content: "■";
}
ul.list.circle li:before {
  content: "●";
}
ul.list.notes li {
  line-height: inherit;
  padding-left: 1.25em;
  position: relative;
}
ul.list.notes li:before {
  content: "※";
  color: inherit;
  display: inline-block;
  font-family: sans-serif;
  position: absolute;
  top: 0;
  left: 0;
}

ol.list {
  list-style: revert-layer;
  padding-left: 1.25em;
}

dl.list dt {
  font-weight: 700;
  margin-bottom: var(--spacing);
}
dl.list dt:not(:first-child) {
  margin-top: var(--large-spacing);
}
dl.list:not(:last-child) {
  margin-bottom: var(--spacing);
}

.table thead th {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #333;
  padding: 0.6em 1em;
  text-align: center;
}
.table tbody th, .table tbody td {
  border: 1px solid #dee2e6;
}
.table tbody th {
  background-color: #f8f9fa;
  padding: 0.6em 1em;
}
.table tbody td {
  background-color: #fff;
  overflow-wrap: break-word;
  padding: 0.6em 1em;
}
.table.table-wide {
  width: 100%;
}
.table.table-fixed {
  table-layout: fixed;
}

.table-block, .th-block {
  border-bottom: 1px solid #dee2e6;
  width: 100%;
}
@media (min-width: 992px) {
  .table-block, .th-block {
    border: none;
    width: auto;
  }
}
.table-block tbody th, .table-block tbody td, .th-block tbody th, .th-block tbody td {
  display: block;
}
@media (min-width: 992px) {
  .table-block tbody th, .table-block tbody td, .th-block tbody th, .th-block tbody td {
    display: table-cell;
  }
}
.table-block tbody th, .th-block tbody th {
  border-bottom: none;
  width: 100%;
}
@media (min-width: 992px) {
  .table-block tbody th, .th-block tbody th {
    border-bottom: 1px solid #dee2e6;
    width: auto;
  }
}
.table-block tbody td, .th-block tbody td {
  border-bottom: none;
}
@media (min-width: 992px) {
  .table-block tbody td, .th-block tbody td {
    border-bottom: 1px solid #dee2e6;
  }
}

.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .table-scroll-wrapper {
    white-space: normal;
  }
}

.label {
  background-color: var(--base-color);
  border: 1px solid transparent;
  border-radius: 0px;
  color: var(--white-color);
  display: inline-block;
  font-size: 0.75em;
  line-height: 1 !important;
  padding: 0.425em 0.6em;
  text-align: center;
  position: relative;
  padding: 0.425em 0.6em;
}
@media (min-width: 576px) {
  .label {
    padding: 0.425em 0.6em;
  }
}
@media (min-width: 768px) {
  .label {
    padding: 0.5em 0.6em;
  }
}
@media (min-width: 992px) {
  .label {
    padding: 0.4em 0.6em;
  }
}
@media (min-width: 1200px) {
  .label {
    padding: 0.4em 0.6em;
  }
}
.label.base {
  background-color: var(--base-color);
  color: var(--white-color);
}
.label.accent {
  background-color: var(--accent-color);
  color: var(--white-color);
}
.label.uno {
  background-color: var(--uno-color);
  color: var(--white-color);
}
.label.dos {
  background-color: var(--dos-color);
  color: var(--white-color);
}
.label.tres {
  background-color: var(--tres-color);
  color: var(--white-color);
}
.label.cuatro {
  background-color: var(--cuatro-color);
  color: var(--white-color);
}
.label.cinco {
  background-color: var(--cinco-color);
  color: var(--white-color);
}
.label.first {
  background-color: var(--first-color);
  color: var(--white-color);
}
.label.second {
  background-color: var(--second-color);
  color: var(--white-color);
}
.label.third {
  background-color: var(--third-color);
  color: var(--white-color);
}
.label.fourth {
  background-color: var(--fourth-color);
  color: var(--white-color);
}
.label.five {
  background-color: var(--five-color);
  color: var(--white-color);
}
.label.base-light {
  background-color: var(--base-light-color);
  color: var(--white-color);
}
.label.accent-light {
  background-color: var(--accent-light-color);
  color: var(--white-color);
}
.label.uno-light {
  background-color: var(--uno-light-color);
  color: var(--white-color);
}
.label.dos-light {
  background-color: var(--dos-light-color);
  color: var(--white-color);
}
.label.tres-light {
  background-color: var(--tres-light-color);
  color: var(--white-color);
}
.label.cuatro-light {
  background-color: var(--cuatro-light-color);
  color: var(--white-color);
}
.label.cinco-light {
  background-color: var(--cinco-light-color);
  color: var(--white-color);
}
.label.first-light {
  background-color: var(--first-light-color);
  color: var(--white-color);
}
.label.second-light {
  background-color: var(--second-light-color);
  color: var(--white-color);
}
.label.third-light {
  background-color: var(--third-light-color);
  color: var(--white-color);
}
.label.fourth-light {
  background-color: var(--fourth-light-color);
  color: var(--white-color);
}
.label.five-light {
  background-color: var(--five-light-color);
  color: var(--white-color);
}
.label.base-dark {
  background-color: var(--base-dark-color);
  color: var(--white-color);
}
.label.accent-dark {
  background-color: var(--accent-dark-color);
  color: var(--white-color);
}
.label.uno-dark {
  background-color: var(--uno-dark-color);
  color: var(--white-color);
}
.label.dos-dark {
  background-color: var(--dos-dark-color);
  color: var(--white-color);
}
.label.tres-dark {
  background-color: var(--tres-dark-color);
  color: var(--white-color);
}
.label.cuatro-dark {
  background-color: var(--cuatro-dark-color);
  color: var(--white-color);
}
.label.cinco-dark {
  background-color: var(--cinco-dark-color);
  color: var(--white-color);
}
.label.first-dark {
  background-color: var(--first-dark-color);
  color: var(--white-color);
}
.label.second-dark {
  background-color: var(--second-dark-color);
  color: var(--white-color);
}
.label.third-dark {
  background-color: var(--third-dark-color);
  color: var(--white-color);
}
.label.fourth-dark {
  background-color: var(--fourth-dark-color);
  color: var(--white-color);
}
.label.five-dark {
  background-color: var(--five-dark-color);
  color: var(--white-color);
}
.label.gray-100 {
  background-color: var(--gray-100-color);
  color: var(--white-color);
}
.label.gray-200 {
  background-color: var(--gray-200-color);
  color: var(--white-color);
}
.label.gray-300 {
  background-color: var(--gray-300-color);
  color: var(--white-color);
}
.label.gray-400 {
  background-color: var(--gray-400-color);
  color: var(--white-color);
}
.label.gray-500 {
  background-color: var(--gray-500-color);
  color: var(--white-color);
}
.label.gray-600 {
  background-color: var(--gray-600-color);
  color: var(--white-color);
}
.label.gray-700 {
  background-color: var(--gray-700-color);
  color: var(--white-color);
}
.label.gray-800 {
  background-color: var(--gray-800-color);
  color: var(--white-color);
}
.label.gray-900 {
  background-color: var(--gray-900-color);
  color: var(--white-color);
}
.label.gray-dark {
  background-color: var(--gray-dark-color);
  color: var(--white-color);
}
.label.black {
  background-color: var(--black-color);
  color: var(--white-color);
}
.label.white {
  background-color: var(--white-color);
  color: var(--white-color);
}
.label.red {
  background-color: var(--red-color);
  color: var(--white-color);
}
.label.yellow {
  background-color: var(--yellow-color);
  color: var(--white-color);
}
.label.orange {
  background-color: var(--orange-color);
  color: var(--white-color);
}
.label.green {
  background-color: var(--green-color);
  color: var(--white-color);
}
.label.blue {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.label.pink {
  background-color: var(--pink-color);
  color: var(--white-color);
}
.label.purple {
  background-color: var(--purple-color);
  color: var(--white-color);
}
.label.cyan {
  background-color: var(--cyan-color);
  color: var(--white-color);
}
.label.outline {
  background-color: var(--white-color);
  border: 1px solid var(--base-color);
  color: var(--base-color);
}
.label.outline.base {
  border-color: var(--base-color);
  color: var(--base-color);
}
.label.outline.accent {
  border-color: var(--accent-color);
  color: var(--accent-color);
}
.label.outline.uno {
  border-color: var(--uno-color);
  color: var(--uno-color);
}
.label.outline.dos {
  border-color: var(--dos-color);
  color: var(--dos-color);
}
.label.outline.tres {
  border-color: var(--tres-color);
  color: var(--tres-color);
}
.label.outline.cuatro {
  border-color: var(--cuatro-color);
  color: var(--cuatro-color);
}
.label.outline.cinco {
  border-color: var(--cinco-color);
  color: var(--cinco-color);
}
.label.outline.first {
  border-color: var(--first-color);
  color: var(--first-color);
}
.label.outline.second {
  border-color: var(--second-color);
  color: var(--second-color);
}
.label.outline.third {
  border-color: var(--third-color);
  color: var(--third-color);
}
.label.outline.fourth {
  border-color: var(--fourth-color);
  color: var(--fourth-color);
}
.label.outline.five {
  border-color: var(--five-color);
  color: var(--five-color);
}
.label.outline.base-light {
  border-color: var(--base-light-color);
  color: var(--base-light-color);
}
.label.outline.accent-light {
  border-color: var(--accent-light-color);
  color: var(--accent-light-color);
}
.label.outline.uno-light {
  border-color: var(--uno-light-color);
  color: var(--uno-light-color);
}
.label.outline.dos-light {
  border-color: var(--dos-light-color);
  color: var(--dos-light-color);
}
.label.outline.tres-light {
  border-color: var(--tres-light-color);
  color: var(--tres-light-color);
}
.label.outline.cuatro-light {
  border-color: var(--cuatro-light-color);
  color: var(--cuatro-light-color);
}
.label.outline.cinco-light {
  border-color: var(--cinco-light-color);
  color: var(--cinco-light-color);
}
.label.outline.first-light {
  border-color: var(--first-light-color);
  color: var(--first-light-color);
}
.label.outline.second-light {
  border-color: var(--second-light-color);
  color: var(--second-light-color);
}
.label.outline.third-light {
  border-color: var(--third-light-color);
  color: var(--third-light-color);
}
.label.outline.fourth-light {
  border-color: var(--fourth-light-color);
  color: var(--fourth-light-color);
}
.label.outline.five-light {
  border-color: var(--five-light-color);
  color: var(--five-light-color);
}
.label.outline.base-dark {
  border-color: var(--base-dark-color);
  color: var(--base-dark-color);
}
.label.outline.accent-dark {
  border-color: var(--accent-dark-color);
  color: var(--accent-dark-color);
}
.label.outline.uno-dark {
  border-color: var(--uno-dark-color);
  color: var(--uno-dark-color);
}
.label.outline.dos-dark {
  border-color: var(--dos-dark-color);
  color: var(--dos-dark-color);
}
.label.outline.tres-dark {
  border-color: var(--tres-dark-color);
  color: var(--tres-dark-color);
}
.label.outline.cuatro-dark {
  border-color: var(--cuatro-dark-color);
  color: var(--cuatro-dark-color);
}
.label.outline.cinco-dark {
  border-color: var(--cinco-dark-color);
  color: var(--cinco-dark-color);
}
.label.outline.first-dark {
  border-color: var(--first-dark-color);
  color: var(--first-dark-color);
}
.label.outline.second-dark {
  border-color: var(--second-dark-color);
  color: var(--second-dark-color);
}
.label.outline.third-dark {
  border-color: var(--third-dark-color);
  color: var(--third-dark-color);
}
.label.outline.fourth-dark {
  border-color: var(--fourth-dark-color);
  color: var(--fourth-dark-color);
}
.label.outline.five-dark {
  border-color: var(--five-dark-color);
  color: var(--five-dark-color);
}
.label.outline.gray-100 {
  border-color: var(--gray-100-color);
  color: var(--gray-100-color);
}
.label.outline.gray-200 {
  border-color: var(--gray-200-color);
  color: var(--gray-200-color);
}
.label.outline.gray-300 {
  border-color: var(--gray-300-color);
  color: var(--gray-300-color);
}
.label.outline.gray-400 {
  border-color: var(--gray-400-color);
  color: var(--gray-400-color);
}
.label.outline.gray-500 {
  border-color: var(--gray-500-color);
  color: var(--gray-500-color);
}
.label.outline.gray-600 {
  border-color: var(--gray-600-color);
  color: var(--gray-600-color);
}
.label.outline.gray-700 {
  border-color: var(--gray-700-color);
  color: var(--gray-700-color);
}
.label.outline.gray-800 {
  border-color: var(--gray-800-color);
  color: var(--gray-800-color);
}
.label.outline.gray-900 {
  border-color: var(--gray-900-color);
  color: var(--gray-900-color);
}
.label.outline.gray-dark {
  border-color: var(--gray-dark-color);
  color: var(--gray-dark-color);
}
.label.outline.black {
  border-color: var(--black-color);
  color: var(--black-color);
}
.label.outline.white {
  border-color: var(--white-color);
  color: var(--white-color);
}
.label.outline.red {
  border-color: var(--red-color);
  color: var(--red-color);
}
.label.outline.yellow {
  border-color: var(--yellow-color);
  color: var(--yellow-color);
}
.label.outline.orange {
  border-color: var(--orange-color);
  color: var(--orange-color);
}
.label.outline.green {
  border-color: var(--green-color);
  color: var(--green-color);
}
.label.outline.blue {
  border-color: var(--blue-color);
  color: var(--blue-color);
}
.label.outline.pink {
  border-color: var(--pink-color);
  color: var(--pink-color);
}
.label.outline.purple {
  border-color: var(--purple-color);
  color: var(--purple-color);
}
.label.outline.cyan {
  border-color: var(--cyan-color);
  color: var(--cyan-color);
}
.label.expanded {
  display: block;
}

span.label {
  margin-left: 0.5em;
  margin-right: 0;
}
span.label.left {
  margin-left: 0;
  margin-right: 0.5em;
}

.accordion-wrapper {
  position: relative;
  width: 100%;
}

.accordion-item {
  border: 1px solid var(--base-color);
  overflow: hidden;
}
.accordion-item:first-of-type {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: none;
}

.accordion-heading {
  background-color: var(--base-color);
  border-bottom: 1px solid var(--base-dark-color);
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding: 1em;
  position: relative;
  transition: background-color var(--base-transition);
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .accordion-heading:hover {
    background-color: var(--base-dark-color);
  }
}
.accordion-heading::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M17,13h-4v4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h4V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4h4c0.6,0,1,0.4,1,1S17.6,13,17,13z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
}
.accordion-heading[aria-pressed=true]::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8s8,3.6,8,8C20,16.4,16.4,20,12,20z"/%3e%3cpath d="M17,11H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S17.6,11,17,11z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
}

.accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height var(--base-transition);
}

.accordion-contents-inner {
  background-color: var(--white-color);
  padding: 1em;
}

.tab-wrapper {
  width: 100%;
}

.tab-labels {
  border-bottom: 1px solid var(--gray-300-color);
  display: flex;
  flex-flow: row wrap;
}

.tab-label-column {
  border-top: 1px solid var(--gray-300-color);
  border-left: 1px solid var(--gray-300-color);
  flex: 1 0 auto;
  position: relative;
}
.tab-label-column:last-child {
  border-right: 1px solid var(--gray-300-color);
}

.tab-label-item {
  background-color: var(--gray-200-color);
  cursor: pointer;
  color: var(--gray-400-color);
  display: block;
  padding: 1em 0;
  text-align: center;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .tab-label-item:hover {
    color: var(--gray-600-color);
  }
}
.tab-label-item[aria-selected=true] {
  background-color: var(--white-color);
  color: var(--gray-dark-color);
}

.tab-contents {
  border-left: 1px solid var(--gray-300-color);
  border-right: 1px solid var(--gray-300-color);
  border-bottom: 1px solid var(--gray-300-color);
}

.tab-contents-item {
  animation: show 0.4s linear 0s;
  padding: 1em;
}

.frame-border, .frame-mat {
  border-radius: var(--border-radius);
  padding: calc(1rem + 0.75vw);
}
.frame-border:not(:last-child), .frame-mat:not(:last-child) {
  margin-bottom: var(--spacing);
}

.frame-border {
  background-color: var(--white-color);
  border: 1px solid var(--gray-400-color);
}
.frame-border.base {
  border-color: var(--base-color);
}
.frame-border.accent {
  border-color: var(--accent-color);
}
.frame-border.uno {
  border-color: var(--uno-color);
}
.frame-border.dos {
  border-color: var(--dos-color);
}
.frame-border.tres {
  border-color: var(--tres-color);
}
.frame-border.cuatro {
  border-color: var(--cuatro-color);
}
.frame-border.cinco {
  border-color: var(--cinco-color);
}
.frame-border.first {
  border-color: var(--first-color);
}
.frame-border.second {
  border-color: var(--second-color);
}
.frame-border.third {
  border-color: var(--third-color);
}
.frame-border.fourth {
  border-color: var(--fourth-color);
}
.frame-border.five {
  border-color: var(--five-color);
}
.frame-border.base-light {
  border-color: var(--base-light-color);
}
.frame-border.accent-light {
  border-color: var(--accent-light-color);
}
.frame-border.uno-light {
  border-color: var(--uno-light-color);
}
.frame-border.dos-light {
  border-color: var(--dos-light-color);
}
.frame-border.tres-light {
  border-color: var(--tres-light-color);
}
.frame-border.cuatro-light {
  border-color: var(--cuatro-light-color);
}
.frame-border.cinco-light {
  border-color: var(--cinco-light-color);
}
.frame-border.first-light {
  border-color: var(--first-light-color);
}
.frame-border.second-light {
  border-color: var(--second-light-color);
}
.frame-border.third-light {
  border-color: var(--third-light-color);
}
.frame-border.fourth-light {
  border-color: var(--fourth-light-color);
}
.frame-border.five-light {
  border-color: var(--five-light-color);
}
.frame-border.base-dark {
  border-color: var(--base-dark-color);
}
.frame-border.accent-dark {
  border-color: var(--accent-dark-color);
}
.frame-border.uno-dark {
  border-color: var(--uno-dark-color);
}
.frame-border.dos-dark {
  border-color: var(--dos-dark-color);
}
.frame-border.tres-dark {
  border-color: var(--tres-dark-color);
}
.frame-border.cuatro-dark {
  border-color: var(--cuatro-dark-color);
}
.frame-border.cinco-dark {
  border-color: var(--cinco-dark-color);
}
.frame-border.first-dark {
  border-color: var(--first-dark-color);
}
.frame-border.second-dark {
  border-color: var(--second-dark-color);
}
.frame-border.third-dark {
  border-color: var(--third-dark-color);
}
.frame-border.fourth-dark {
  border-color: var(--fourth-dark-color);
}
.frame-border.five-dark {
  border-color: var(--five-dark-color);
}
.frame-border.gray-100 {
  border-color: var(--gray-100-color);
}
.frame-border.gray-200 {
  border-color: var(--gray-200-color);
}
.frame-border.gray-300 {
  border-color: var(--gray-300-color);
}
.frame-border.gray-400 {
  border-color: var(--gray-400-color);
}
.frame-border.gray-500 {
  border-color: var(--gray-500-color);
}
.frame-border.gray-600 {
  border-color: var(--gray-600-color);
}
.frame-border.gray-700 {
  border-color: var(--gray-700-color);
}
.frame-border.gray-800 {
  border-color: var(--gray-800-color);
}
.frame-border.gray-900 {
  border-color: var(--gray-900-color);
}
.frame-border.gray-dark {
  border-color: var(--gray-dark-color);
}
.frame-border.black {
  border-color: var(--black-color);
}
.frame-border.white {
  border-color: var(--white-color);
}
.frame-border.red {
  border-color: var(--red-color);
}
.frame-border.yellow {
  border-color: var(--yellow-color);
}
.frame-border.orange {
  border-color: var(--orange-color);
}
.frame-border.green {
  border-color: var(--green-color);
}
.frame-border.blue {
  border-color: var(--blue-color);
}
.frame-border.pink {
  border-color: var(--pink-color);
}
.frame-border.purple {
  border-color: var(--purple-color);
}
.frame-border.cyan {
  border-color: var(--cyan-color);
}

.frame-mat {
  background-color: var(--gray-100-color);
}
.frame-mat.base {
  background-color: var(--base-color);
}
.frame-mat.accent {
  background-color: var(--accent-color);
}
.frame-mat.uno {
  background-color: var(--uno-color);
}
.frame-mat.dos {
  background-color: var(--dos-color);
}
.frame-mat.tres {
  background-color: var(--tres-color);
}
.frame-mat.cuatro {
  background-color: var(--cuatro-color);
}
.frame-mat.cinco {
  background-color: var(--cinco-color);
}
.frame-mat.first {
  background-color: var(--first-color);
}
.frame-mat.second {
  background-color: var(--second-color);
}
.frame-mat.third {
  background-color: var(--third-color);
}
.frame-mat.fourth {
  background-color: var(--fourth-color);
}
.frame-mat.five {
  background-color: var(--five-color);
}
.frame-mat.base-light {
  background-color: var(--base-light-color);
}
.frame-mat.accent-light {
  background-color: var(--accent-light-color);
}
.frame-mat.uno-light {
  background-color: var(--uno-light-color);
}
.frame-mat.dos-light {
  background-color: var(--dos-light-color);
}
.frame-mat.tres-light {
  background-color: var(--tres-light-color);
}
.frame-mat.cuatro-light {
  background-color: var(--cuatro-light-color);
}
.frame-mat.cinco-light {
  background-color: var(--cinco-light-color);
}
.frame-mat.first-light {
  background-color: var(--first-light-color);
}
.frame-mat.second-light {
  background-color: var(--second-light-color);
}
.frame-mat.third-light {
  background-color: var(--third-light-color);
}
.frame-mat.fourth-light {
  background-color: var(--fourth-light-color);
}
.frame-mat.five-light {
  background-color: var(--five-light-color);
}
.frame-mat.base-dark {
  background-color: var(--base-dark-color);
}
.frame-mat.accent-dark {
  background-color: var(--accent-dark-color);
}
.frame-mat.uno-dark {
  background-color: var(--uno-dark-color);
}
.frame-mat.dos-dark {
  background-color: var(--dos-dark-color);
}
.frame-mat.tres-dark {
  background-color: var(--tres-dark-color);
}
.frame-mat.cuatro-dark {
  background-color: var(--cuatro-dark-color);
}
.frame-mat.cinco-dark {
  background-color: var(--cinco-dark-color);
}
.frame-mat.first-dark {
  background-color: var(--first-dark-color);
}
.frame-mat.second-dark {
  background-color: var(--second-dark-color);
}
.frame-mat.third-dark {
  background-color: var(--third-dark-color);
}
.frame-mat.fourth-dark {
  background-color: var(--fourth-dark-color);
}
.frame-mat.five-dark {
  background-color: var(--five-dark-color);
}
.frame-mat.gray-100 {
  background-color: var(--gray-100-color);
}
.frame-mat.gray-200 {
  background-color: var(--gray-200-color);
}
.frame-mat.gray-300 {
  background-color: var(--gray-300-color);
}
.frame-mat.gray-400 {
  background-color: var(--gray-400-color);
}
.frame-mat.gray-500 {
  background-color: var(--gray-500-color);
}
.frame-mat.gray-600 {
  background-color: var(--gray-600-color);
}
.frame-mat.gray-700 {
  background-color: var(--gray-700-color);
}
.frame-mat.gray-800 {
  background-color: var(--gray-800-color);
}
.frame-mat.gray-900 {
  background-color: var(--gray-900-color);
}
.frame-mat.gray-dark {
  background-color: var(--gray-dark-color);
}
.frame-mat.black {
  background-color: var(--black-color);
}
.frame-mat.white {
  background-color: var(--white-color);
}
.frame-mat.red {
  background-color: var(--red-color);
}
.frame-mat.yellow {
  background-color: var(--yellow-color);
}
.frame-mat.orange {
  background-color: var(--orange-color);
}
.frame-mat.green {
  background-color: var(--green-color);
}
.frame-mat.blue {
  background-color: var(--blue-color);
}
.frame-mat.pink {
  background-color: var(--pink-color);
}
.frame-mat.purple {
  background-color: var(--purple-color);
}
.frame-mat.cyan {
  background-color: var(--cyan-color);
}

.icon {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.icon::before, .icon::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  margin: 0 0.25em;
}
.icon::after {
  content: "";
}
.icon::before {
  content: none;
}
.icon.left::before {
  content: "";
}
.icon.left::after {
  content: none;
}
.icon.arrow-right::before, .icon.arrow-right::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-base::before, .icon.arrow-right.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-accent::before, .icon.arrow-right.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-uno::before, .icon.arrow-right.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-dos::before, .icon.arrow-right.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-tres::before, .icon.arrow-right.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cuatro::before, .icon.arrow-right.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cinco::before, .icon.arrow-right.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-first::before, .icon.arrow-right.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-second::before, .icon.arrow-right.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-third::before, .icon.arrow-right.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-fourth::before, .icon.arrow-right.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-five::before, .icon.arrow-right.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-base-light::before, .icon.arrow-right.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-accent-light::before, .icon.arrow-right.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-uno-light::before, .icon.arrow-right.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-dos-light::before, .icon.arrow-right.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-tres-light::before, .icon.arrow-right.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cuatro-light::before, .icon.arrow-right.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cinco-light::before, .icon.arrow-right.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-first-light::before, .icon.arrow-right.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-second-light::before, .icon.arrow-right.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-third-light::before, .icon.arrow-right.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-fourth-light::before, .icon.arrow-right.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-five-light::before, .icon.arrow-right.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-base-dark::before, .icon.arrow-right.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-accent-dark::before, .icon.arrow-right.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-uno-dark::before, .icon.arrow-right.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-dos-dark::before, .icon.arrow-right.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-tres-dark::before, .icon.arrow-right.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cuatro-dark::before, .icon.arrow-right.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cinco-dark::before, .icon.arrow-right.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-first-dark::before, .icon.arrow-right.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-second-dark::before, .icon.arrow-right.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-third-dark::before, .icon.arrow-right.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-fourth-dark::before, .icon.arrow-right.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-five-dark::before, .icon.arrow-right.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-100::before, .icon.arrow-right.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-200::before, .icon.arrow-right.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-300::before, .icon.arrow-right.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-400::before, .icon.arrow-right.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-500::before, .icon.arrow-right.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-600::before, .icon.arrow-right.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-700::before, .icon.arrow-right.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-800::before, .icon.arrow-right.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-900::before, .icon.arrow-right.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-gray-dark::before, .icon.arrow-right.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-black::before, .icon.arrow-right.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-white::before, .icon.arrow-right.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-red::before, .icon.arrow-right.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-yellow::before, .icon.arrow-right.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-orange::before, .icon.arrow-right.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-green::before, .icon.arrow-right.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-blue::before, .icon.arrow-right.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-pink::before, .icon.arrow-right.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-purple::before, .icon.arrow-right.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.arrow-right.ic-cyan::before, .icon.arrow-right.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpolygon points="2,16 22,16 12.1,6 10.7,7.4 17.2,14 2,14 "/%3e%3c/svg%3e');
}
.icon.pdf::before, .icon.pdf::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-base::before, .icon.pdf.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-accent::before, .icon.pdf.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-uno::before, .icon.pdf.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-dos::before, .icon.pdf.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-tres::before, .icon.pdf.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cuatro::before, .icon.pdf.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cinco::before, .icon.pdf.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-first::before, .icon.pdf.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-second::before, .icon.pdf.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-third::before, .icon.pdf.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-fourth::before, .icon.pdf.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-five::before, .icon.pdf.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-base-light::before, .icon.pdf.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-accent-light::before, .icon.pdf.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-uno-light::before, .icon.pdf.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-dos-light::before, .icon.pdf.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-tres-light::before, .icon.pdf.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cuatro-light::before, .icon.pdf.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cinco-light::before, .icon.pdf.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-first-light::before, .icon.pdf.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-second-light::before, .icon.pdf.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-third-light::before, .icon.pdf.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-fourth-light::before, .icon.pdf.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-five-light::before, .icon.pdf.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-base-dark::before, .icon.pdf.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-accent-dark::before, .icon.pdf.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-uno-dark::before, .icon.pdf.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-dos-dark::before, .icon.pdf.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-tres-dark::before, .icon.pdf.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cuatro-dark::before, .icon.pdf.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cinco-dark::before, .icon.pdf.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-first-dark::before, .icon.pdf.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-second-dark::before, .icon.pdf.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-third-dark::before, .icon.pdf.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-fourth-dark::before, .icon.pdf.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-five-dark::before, .icon.pdf.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-100::before, .icon.pdf.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-200::before, .icon.pdf.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-300::before, .icon.pdf.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-400::before, .icon.pdf.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-500::before, .icon.pdf.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-600::before, .icon.pdf.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-700::before, .icon.pdf.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-800::before, .icon.pdf.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-900::before, .icon.pdf.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-gray-dark::before, .icon.pdf.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-black::before, .icon.pdf.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-white::before, .icon.pdf.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-red::before, .icon.pdf.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-yellow::before, .icon.pdf.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-orange::before, .icon.pdf.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-green::before, .icon.pdf.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-blue::before, .icon.pdf.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-pink::before, .icon.pdf.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-purple::before, .icon.pdf.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.pdf.ic-cyan::before, .icon.pdf.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M0,24h24V3.9L18.8,0H0V24z M1.7,1.4h16.4c0.4,0.3,3.6,2.7,4.1,3.1c0,0.6,0,16.8,0,18.1l0,0H1.7V1.4z"/%3e%3cpath d="M5.9,16.3H3.7V8.2h2.1c1.1,0,1.8,0.2,2.4,0.6C8.8,9.3,9.1,10,9.1,11c0,1-0.3,1.7-1,2.2c-0.4,0.3-1.1,0.5-1.9,0.5H5.9V16.3zM5.9,11.8l0.1,0c0.3,0,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7C6.5,10,6.3,10,6,10H5.9V11.8z"/%3e%3cpath d="M9.8,16.3V8.2h1.9c1.1,0,1.9,0.3,2.5,0.9c0.8,0.8,1.2,1.8,1.2,3.2c0,1.4-0.3,2.4-1,3.1c-0.6,0.6-1.4,0.9-2.6,0.9H9.8z M12,14.4c0.8,0,1.2-0.7,1.2-2c0-0.7-0.1-1.2-0.3-1.5c-0.2-0.4-0.5-0.6-0.8-0.6H12V14.4z"/%3e%3cpath d="M18.6,16.3h-2.2V8.2h3.9v1.9h-1.7v1.2h1.6v1.8h-1.6V16.3z"/%3e%3c/svg%3e');
}
.icon.word::before, .icon.word::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-base::before, .icon.word.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-accent::before, .icon.word.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-uno::before, .icon.word.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-dos::before, .icon.word.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-tres::before, .icon.word.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cuatro::before, .icon.word.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cinco::before, .icon.word.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-first::before, .icon.word.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-second::before, .icon.word.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-third::before, .icon.word.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-fourth::before, .icon.word.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-five::before, .icon.word.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-base-light::before, .icon.word.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-accent-light::before, .icon.word.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-uno-light::before, .icon.word.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-dos-light::before, .icon.word.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-tres-light::before, .icon.word.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cuatro-light::before, .icon.word.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cinco-light::before, .icon.word.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-first-light::before, .icon.word.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-second-light::before, .icon.word.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-third-light::before, .icon.word.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-fourth-light::before, .icon.word.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-five-light::before, .icon.word.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-base-dark::before, .icon.word.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-accent-dark::before, .icon.word.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-uno-dark::before, .icon.word.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-dos-dark::before, .icon.word.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-tres-dark::before, .icon.word.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cuatro-dark::before, .icon.word.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cinco-dark::before, .icon.word.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-first-dark::before, .icon.word.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-second-dark::before, .icon.word.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-third-dark::before, .icon.word.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-fourth-dark::before, .icon.word.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-five-dark::before, .icon.word.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-100::before, .icon.word.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-200::before, .icon.word.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-300::before, .icon.word.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-400::before, .icon.word.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-500::before, .icon.word.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-600::before, .icon.word.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-700::before, .icon.word.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-800::before, .icon.word.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-900::before, .icon.word.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-gray-dark::before, .icon.word.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-black::before, .icon.word.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-white::before, .icon.word.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-red::before, .icon.word.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-yellow::before, .icon.word.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-orange::before, .icon.word.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-green::before, .icon.word.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-blue::before, .icon.word.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-pink::before, .icon.word.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-purple::before, .icon.word.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.word.ic-cyan::before, .icon.word.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.7-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.2,0,2.1-0.9,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="10.5,13.9 9.3,10 8,10 6.7,13.9 5.6,10 4,10 5.8,16 7.4,16 8.6,12.5 9.8,16 11.4,16 13.4,10 11.7,10"/%3e%3c/svg%3e');
}
.icon.excel::before, .icon.excel::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-base::before, .icon.excel.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-accent::before, .icon.excel.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-uno::before, .icon.excel.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-dos::before, .icon.excel.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-tres::before, .icon.excel.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cuatro::before, .icon.excel.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cinco::before, .icon.excel.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-first::before, .icon.excel.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-second::before, .icon.excel.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-third::before, .icon.excel.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-fourth::before, .icon.excel.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-five::before, .icon.excel.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-base-light::before, .icon.excel.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-accent-light::before, .icon.excel.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-uno-light::before, .icon.excel.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-dos-light::before, .icon.excel.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-tres-light::before, .icon.excel.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cuatro-light::before, .icon.excel.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cinco-light::before, .icon.excel.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-first-light::before, .icon.excel.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-second-light::before, .icon.excel.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-third-light::before, .icon.excel.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-fourth-light::before, .icon.excel.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-five-light::before, .icon.excel.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-base-dark::before, .icon.excel.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-accent-dark::before, .icon.excel.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-uno-dark::before, .icon.excel.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-dos-dark::before, .icon.excel.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-tres-dark::before, .icon.excel.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cuatro-dark::before, .icon.excel.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cinco-dark::before, .icon.excel.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-first-dark::before, .icon.excel.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-second-dark::before, .icon.excel.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-third-dark::before, .icon.excel.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-fourth-dark::before, .icon.excel.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-five-dark::before, .icon.excel.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-100::before, .icon.excel.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-200::before, .icon.excel.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-300::before, .icon.excel.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-400::before, .icon.excel.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-500::before, .icon.excel.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-600::before, .icon.excel.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-700::before, .icon.excel.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-800::before, .icon.excel.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-900::before, .icon.excel.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-gray-dark::before, .icon.excel.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-black::before, .icon.excel.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-white::before, .icon.excel.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-red::before, .icon.excel.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-yellow::before, .icon.excel.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-orange::before, .icon.excel.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-green::before, .icon.excel.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-blue::before, .icon.excel.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-pink::before, .icon.excel.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-purple::before, .icon.excel.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.excel.ic-cyan::before, .icon.excel.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M21.2,7.1L21.2,7.1l-3.6-4.2L16.8,2H8.2C7,2,6.1,2.9,6.1,4.1v2H2V19h4.1v0.6C6,20.8,6.8,21.9,8,22h11.9c1.1,0,2.1-1,2.1-2.1V8.1L21.2,7.1z M3.3,7.4h10.9v10.3H3.3V7.4z M20.7,19.4c0,0.7-0.5,1.2-1.2,1.2H8.5c-0.7-0.1-1.1-0.6-1.1-1.3V19h8.1V6.1H7.4V4.6c0-0.7,0.5-1.2,1.2-1.2h7.4v3.5c0,1.3,1.1,2.3,2.3,2.4c0,0,0,0,0,0h2.6L20.7,19.4z"/%3e%3cpolygon points="12.2,9.3 9.8,9.3 8.7,11 7.6,9.3 5.3,9.3 7.6,12.4 4.7,16 7,16 8.7,13.8 10.2,16 12.5,16 9.8,12.4"/%3e%3c/svg%3e');
}
.icon.document::before, .icon.document::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-base::before, .icon.document.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-accent::before, .icon.document.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-uno::before, .icon.document.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-dos::before, .icon.document.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-tres::before, .icon.document.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cuatro::before, .icon.document.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cinco::before, .icon.document.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-first::before, .icon.document.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-second::before, .icon.document.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-third::before, .icon.document.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-fourth::before, .icon.document.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-five::before, .icon.document.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-base-light::before, .icon.document.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-accent-light::before, .icon.document.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-uno-light::before, .icon.document.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-dos-light::before, .icon.document.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-tres-light::before, .icon.document.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cuatro-light::before, .icon.document.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cinco-light::before, .icon.document.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-first-light::before, .icon.document.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-second-light::before, .icon.document.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-third-light::before, .icon.document.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-fourth-light::before, .icon.document.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-five-light::before, .icon.document.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-base-dark::before, .icon.document.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-accent-dark::before, .icon.document.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-uno-dark::before, .icon.document.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-dos-dark::before, .icon.document.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-tres-dark::before, .icon.document.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cuatro-dark::before, .icon.document.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cinco-dark::before, .icon.document.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-first-dark::before, .icon.document.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-second-dark::before, .icon.document.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-third-dark::before, .icon.document.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-fourth-dark::before, .icon.document.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-five-dark::before, .icon.document.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-100::before, .icon.document.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-200::before, .icon.document.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-300::before, .icon.document.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-400::before, .icon.document.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-500::before, .icon.document.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-600::before, .icon.document.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-700::before, .icon.document.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-800::before, .icon.document.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-900::before, .icon.document.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-gray-dark::before, .icon.document.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-black::before, .icon.document.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-white::before, .icon.document.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-red::before, .icon.document.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-yellow::before, .icon.document.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-orange::before, .icon.document.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-green::before, .icon.document.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-blue::before, .icon.document.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-pink::before, .icon.document.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-purple::before, .icon.document.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.document.ic-cyan::before, .icon.document.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M18.5,20 C18.5,20.275 18.276,20.5 18,20.5 L6,20.5 C5.724,20.5 5.5,20.275 5.5,20 L5.5,4 C5.5,3.725 5.724,3.5 6,3.5 L12,3.5 L12,8 C12,9.104 12.896,10 14,10 L18.5,10 L18.5,20 Z M13.5,4.621 L17.378,8.5 L14,8.5 C13.724,8.5 13.5,8.275 13.5,8 L13.5,4.621 Z M19.414,8.414 L13.585,2.586 C13.559,2.56 13.527,2.54 13.5,2.516 C13.429,2.452 13.359,2.389 13.281,2.336 C13.241,2.309 13.195,2.291 13.153,2.268 C13.082,2.228 13.012,2.184 12.937,2.152 C12.74,2.07 12.528,2.029 12.313,2.014 C12.266,2.011 12.22,2 12.172,2 L12.171,2 L12,2 L6,2 C4.896,2 4,2.896 4,4 L4,20 C4,21.104 4.896,22 6,22 L18,22 C19.104,22 20,21.104 20,20 L20,10 L20,9.828 C20,9.298 19.789,8.789 19.414,8.414 L19.414,8.414 Z"/%3e%3c/svg%3e');
}
.icon.open-new-window::before, .icon.open-new-window::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-base::before, .icon.open-new-window.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-accent::before, .icon.open-new-window.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-uno::before, .icon.open-new-window.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-dos::before, .icon.open-new-window.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-tres::before, .icon.open-new-window.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cuatro::before, .icon.open-new-window.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cinco::before, .icon.open-new-window.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-first::before, .icon.open-new-window.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-second::before, .icon.open-new-window.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-third::before, .icon.open-new-window.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-fourth::before, .icon.open-new-window.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-five::before, .icon.open-new-window.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-base-light::before, .icon.open-new-window.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-accent-light::before, .icon.open-new-window.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-uno-light::before, .icon.open-new-window.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-dos-light::before, .icon.open-new-window.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-tres-light::before, .icon.open-new-window.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cuatro-light::before, .icon.open-new-window.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cinco-light::before, .icon.open-new-window.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-first-light::before, .icon.open-new-window.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-second-light::before, .icon.open-new-window.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-third-light::before, .icon.open-new-window.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-fourth-light::before, .icon.open-new-window.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-five-light::before, .icon.open-new-window.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-base-dark::before, .icon.open-new-window.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-accent-dark::before, .icon.open-new-window.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-uno-dark::before, .icon.open-new-window.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-dos-dark::before, .icon.open-new-window.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-tres-dark::before, .icon.open-new-window.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cuatro-dark::before, .icon.open-new-window.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cinco-dark::before, .icon.open-new-window.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-first-dark::before, .icon.open-new-window.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-second-dark::before, .icon.open-new-window.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-third-dark::before, .icon.open-new-window.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-fourth-dark::before, .icon.open-new-window.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-five-dark::before, .icon.open-new-window.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-100::before, .icon.open-new-window.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-200::before, .icon.open-new-window.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-300::before, .icon.open-new-window.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-400::before, .icon.open-new-window.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-500::before, .icon.open-new-window.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-600::before, .icon.open-new-window.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-700::before, .icon.open-new-window.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-800::before, .icon.open-new-window.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-900::before, .icon.open-new-window.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-gray-dark::before, .icon.open-new-window.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-black::before, .icon.open-new-window.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-white::before, .icon.open-new-window.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-red::before, .icon.open-new-window.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-yellow::before, .icon.open-new-window.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-orange::before, .icon.open-new-window.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-green::before, .icon.open-new-window.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-blue::before, .icon.open-new-window.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-pink::before, .icon.open-new-window.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-purple::before, .icon.open-new-window.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.open-new-window.ic-cyan::before, .icon.open-new-window.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10h2V3H14z"/%3e%3c/svg%3e');
}
.icon.mail::before, .icon.mail::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-base::before, .icon.mail.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-accent::before, .icon.mail.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-uno::before, .icon.mail.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-dos::before, .icon.mail.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-tres::before, .icon.mail.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cuatro::before, .icon.mail.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cinco::before, .icon.mail.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-first::before, .icon.mail.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-second::before, .icon.mail.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-third::before, .icon.mail.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-fourth::before, .icon.mail.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-five::before, .icon.mail.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-base-light::before, .icon.mail.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-accent-light::before, .icon.mail.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-uno-light::before, .icon.mail.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-dos-light::before, .icon.mail.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-tres-light::before, .icon.mail.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cuatro-light::before, .icon.mail.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cinco-light::before, .icon.mail.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-first-light::before, .icon.mail.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-second-light::before, .icon.mail.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-third-light::before, .icon.mail.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-fourth-light::before, .icon.mail.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-five-light::before, .icon.mail.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-base-dark::before, .icon.mail.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-accent-dark::before, .icon.mail.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-uno-dark::before, .icon.mail.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-dos-dark::before, .icon.mail.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-tres-dark::before, .icon.mail.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cuatro-dark::before, .icon.mail.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cinco-dark::before, .icon.mail.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-first-dark::before, .icon.mail.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-second-dark::before, .icon.mail.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-third-dark::before, .icon.mail.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-fourth-dark::before, .icon.mail.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-five-dark::before, .icon.mail.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-100::before, .icon.mail.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-200::before, .icon.mail.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-300::before, .icon.mail.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-400::before, .icon.mail.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-500::before, .icon.mail.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-600::before, .icon.mail.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-700::before, .icon.mail.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-800::before, .icon.mail.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-900::before, .icon.mail.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-gray-dark::before, .icon.mail.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-black::before, .icon.mail.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-white::before, .icon.mail.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-red::before, .icon.mail.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-yellow::before, .icon.mail.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-orange::before, .icon.mail.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-green::before, .icon.mail.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-blue::before, .icon.mail.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-pink::before, .icon.mail.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-purple::before, .icon.mail.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.mail.ic-cyan::before, .icon.mail.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath id="_xD83C__xDFA8_-Color" class="st0" d="M22,8.6v8.1c0,1.7-1.4,3.1-3.1,3.2l-0.2,0H5.2c-1.7,0-3.1-1.4-3.2-3.1l0-0.2V8.6l9.7,5.1c0.2,0.1,0.5,0.1,0.7,0L22,8.6z M5.2,4h13.5c1.7,0,3.1,1.3,3.2,2.9l-10,5.2L2,6.9c0.2-1.6,1.4-2.8,3-2.9L5.2,4h13.5H5.2z"/%3e%3c/svg%3e');
}
.icon.calendar::before, .icon.calendar::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="inherit"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-base::before, .icon.calendar.ic-base::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-accent::before, .icon.calendar.ic-accent::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232e2f33"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-uno::before, .icon.calendar.ic-uno::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-dos::before, .icon.calendar.ic-dos::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-tres::before, .icon.calendar.ic-tres::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cuatro::before, .icon.calendar.ic-cuatro::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cinco::before, .icon.calendar.ic-cinco::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-first::before, .icon.calendar.ic-first::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-second::before, .icon.calendar.ic-second::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d63384"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-third::before, .icon.calendar.ic-third::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fd7e14"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-fourth::before, .icon.calendar.ic-fourth::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232da8a8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-five::before, .icon.calendar.ic-five::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%235f6987"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-base-light::before, .icon.calendar.ic-base-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b3d8c4"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-accent-light::before, .icon.calendar.ic-accent-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0c1c2"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-uno-light::before, .icon.calendar.ic-uno-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-dos-light::before, .icon.calendar.ic-dos-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-tres-light::before, .icon.calendar.ic-tres-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cuatro-light::before, .icon.calendar.ic-cuatro-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cinco-light::before, .icon.calendar.ic-cinco-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-first-light::before, .icon.calendar.ic-first-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d4c6ec"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-second-light::before, .icon.calendar.ic-second-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f3c2da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-third-light::before, .icon.calendar.ic-third-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fed8b9"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-fourth-light::before, .icon.calendar.ic-fourth-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23c0e5e5"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-five-light::before, .icon.calendar.ic-five-light::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23cfd2db"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-base-dark::before, .icon.calendar.ic-base-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23004c22"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-accent-dark::before, .icon.calendar.ic-accent-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231c1c1f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-uno-dark::before, .icon.calendar.ic-uno-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-dos-dark::before, .icon.calendar.ic-dos-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-tres-dark::before, .icon.calendar.ic-tres-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cuatro-dark::before, .icon.calendar.ic-cuatro-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cinco-dark::before, .icon.calendar.ic-cinco-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-first-dark::before, .icon.calendar.ic-first-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23432874"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-second-dark::before, .icon.calendar.ic-second-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23801f4f"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-third-dark::before, .icon.calendar.ic-third-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23984c0c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-fourth-dark::before, .icon.calendar.ic-fourth-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%231b6565"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-five-dark::before, .icon.calendar.ic-five-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23393f51"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-100::before, .icon.calendar.ic-gray-100::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f8f9fa"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-200::before, .icon.calendar.ic-gray-200::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23e9ecef"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-300::before, .icon.calendar.ic-gray-300::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23dee2e6"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-400::before, .icon.calendar.ic-gray-400::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ced4da"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-500::before, .icon.calendar.ic-gray-500::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23adb5bd"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-600::before, .icon.calendar.ic-gray-600::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-700::before, .icon.calendar.ic-gray-700::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23495057"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-800::before, .icon.calendar.ic-gray-800::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23343a40"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-900::before, .icon.calendar.ic-gray-900::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23212529"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-gray-dark::before, .icon.calendar.ic-gray-dark::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-black::before, .icon.calendar.ic-black::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-white::before, .icon.calendar.ic-white::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-red::before, .icon.calendar.ic-red::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23b0120a"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-yellow::before, .icon.calendar.ic-yellow::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff000"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-orange::before, .icon.calendar.ic-orange::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23f18f2c"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-green::before, .icon.calendar.ic-green::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2363a764"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-blue::before, .icon.calendar.ic-blue::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23005fcc"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-pink::before, .icon.calendar.ic-pink::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ff0066"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-purple::before, .icon.calendar.ic-purple::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f42c1"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}
.icon.calendar.ic-cyan::before, .icon.calendar.ic-cyan::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%2317a2b8"%3e%3cpath d="M22,8.1v10.3c0,2-1.6,3.6-3.6,3.6H5.6c-2,0-3.6-1.6-3.6-3.6V8.1H22z M6.7,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C8.1,16,7.5,15.3,6.7,15.3z M12,15.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C13.4,16,12.8,15.3,12,15.3z M6.7,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S7.5,10.3,6.7,10.3z M12,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,10.3,12,10.3z M17.3,10.3c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S18,10.3,17.3,10.3z M18.4,2c2,0,3.6,1.6,3.6,3.6v0.8H2V5.6C2,3.6,3.6,2,5.6,2H18.4z"/%3e%3c/svg%3e');
}

.pagination-container {
  margin: 4rem 0 0;
  width: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  text-align: center;
}
.pagination li {
  display: block;
}
.pagination li:nth-of-type(n+2) {
  margin-left: 0.5rem;
}
.pagination .prev,
.pagination .next,
.pagination .page,
.pagination .current {
  border-radius: 3px;
  border: 1px solid transparent;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 0.7em;
  transition: background-color var(--base-transition);
}
.pagination .prev:not(.current),
.pagination .next:not(.current),
.pagination .page:not(.current),
.pagination .current:not(.current) {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .pagination .prev:not(.current):hover,
  .pagination .next:not(.current):hover,
  .pagination .page:not(.current):hover,
  .pagination .current:not(.current):hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}
.pagination .prev,
.pagination .next {
  color: #333;
}
.pagination .page {
  background-color: #fff;
  color: #333;
}
.pagination .current {
  background-color: #007e39;
  border-color: #007e39;
  color: #fff;
  font-weight: 700;
}
.pagination .disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

.pagetop {
  background-color: #fff;
  border: 1px solid #007e39;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all var(--base-transition);
  width: 38px;
  height: 38px;
  z-index: 2;
}
.pagetop:after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23007e39"%3e%3cpath d="M4,12l1.4,1.4L11,7.8V20h2V7.8l5.6,5.6L20,12l-8-8L4,12z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
@media (min-width: 992px) {
  .pagetop {
    bottom: 2rem;
    right: 2rem;
    width: 52px;
    height: 52px;
  }
}
.pagetop.pagetop-active {
  opacity: 1;
  visibility: visible;
}

.loading-wrapper {
  background-color: #007e39;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity var(--base-transition), visibility var(--base-transition);
  z-index: 5;
}
.loading-wrapper.disabled {
  opacity: 0;
  visibility: hidden;
}

.loading-container {
  margin: 0 auto;
  width: 90%;
}

.loading {
  animation: rotate-loading 1.5s linear 0s infinite normal;
  border-radius: 100%;
  border: 2px solid transparent;
  border-color: transparent #fff transparent #fff;
  margin: 0 auto;
  position: relative;
  width: 100px;
  height: 100px;
  transform-origin: 50% 50%;
}

.loading-text {
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.mt4 {
  margin-top: 4px !important;
  margin-top: 4px !important;
}
@media (min-width: 576px) {
  .mt4 {
    margin-top: 4px !important;
  }
}
@media (min-width: 768px) {
  .mt4 {
    margin-top: 4px !important;
  }
}
@media (min-width: 992px) {
  .mt4 {
    margin-top: 5px !important;
  }
}
@media (min-width: 1200px) {
  .mt4 {
    margin-top: 5px !important;
  }
}

.mt8 {
  margin-top: 8px !important;
  margin-top: 8px !important;
}
@media (min-width: 576px) {
  .mt8 {
    margin-top: 8px !important;
  }
}
@media (min-width: 768px) {
  .mt8 {
    margin-top: 8px !important;
  }
}
@media (min-width: 992px) {
  .mt8 {
    margin-top: 10px !important;
  }
}
@media (min-width: 1200px) {
  .mt8 {
    margin-top: 10px !important;
  }
}

.mt12 {
  margin-top: 12px !important;
  margin-top: 12px !important;
}
@media (min-width: 576px) {
  .mt12 {
    margin-top: 12px !important;
  }
}
@media (min-width: 768px) {
  .mt12 {
    margin-top: 12px !important;
  }
}
@media (min-width: 992px) {
  .mt12 {
    margin-top: 14px !important;
  }
}
@media (min-width: 1200px) {
  .mt12 {
    margin-top: 14px !important;
  }
}

.mt16 {
  margin-top: 16px !important;
  margin-top: 16px !important;
}
@media (min-width: 576px) {
  .mt16 {
    margin-top: 16px !important;
  }
}
@media (min-width: 768px) {
  .mt16 {
    margin-top: 16px !important;
  }
}
@media (min-width: 992px) {
  .mt16 {
    margin-top: 19px !important;
  }
}
@media (min-width: 1200px) {
  .mt16 {
    margin-top: 19px !important;
  }
}

.mt20 {
  margin-top: 20px !important;
  margin-top: 20px !important;
}
@media (min-width: 576px) {
  .mt20 {
    margin-top: 20px !important;
  }
}
@media (min-width: 768px) {
  .mt20 {
    margin-top: 20px !important;
  }
}
@media (min-width: 992px) {
  .mt20 {
    margin-top: 23px !important;
  }
}
@media (min-width: 1200px) {
  .mt20 {
    margin-top: 23px !important;
  }
}

.mt24 {
  margin-top: 24px !important;
  margin-top: 24px !important;
}
@media (min-width: 576px) {
  .mt24 {
    margin-top: 24px !important;
  }
}
@media (min-width: 768px) {
  .mt24 {
    margin-top: 24px !important;
  }
}
@media (min-width: 992px) {
  .mt24 {
    margin-top: 28px !important;
  }
}
@media (min-width: 1200px) {
  .mt24 {
    margin-top: 28px !important;
  }
}

.mt28 {
  margin-top: 28px !important;
  margin-top: 28px !important;
}
@media (min-width: 576px) {
  .mt28 {
    margin-top: 28px !important;
  }
}
@media (min-width: 768px) {
  .mt28 {
    margin-top: 28px !important;
  }
}
@media (min-width: 992px) {
  .mt28 {
    margin-top: 32px !important;
  }
}
@media (min-width: 1200px) {
  .mt28 {
    margin-top: 32px !important;
  }
}

.mt32 {
  margin-top: 32px !important;
  margin-top: 32px !important;
}
@media (min-width: 576px) {
  .mt32 {
    margin-top: 32px !important;
  }
}
@media (min-width: 768px) {
  .mt32 {
    margin-top: 32px !important;
  }
}
@media (min-width: 992px) {
  .mt32 {
    margin-top: 37px !important;
  }
}
@media (min-width: 1200px) {
  .mt32 {
    margin-top: 37px !important;
  }
}

.mt36 {
  margin-top: 36px !important;
  margin-top: 36px !important;
}
@media (min-width: 576px) {
  .mt36 {
    margin-top: 36px !important;
  }
}
@media (min-width: 768px) {
  .mt36 {
    margin-top: 36px !important;
  }
}
@media (min-width: 992px) {
  .mt36 {
    margin-top: 42px !important;
  }
}
@media (min-width: 1200px) {
  .mt36 {
    margin-top: 42px !important;
  }
}

.mt40 {
  margin-top: 40px !important;
  margin-top: 40px !important;
}
@media (min-width: 576px) {
  .mt40 {
    margin-top: 40px !important;
  }
}
@media (min-width: 768px) {
  .mt40 {
    margin-top: 40px !important;
  }
}
@media (min-width: 992px) {
  .mt40 {
    margin-top: 46px !important;
  }
}
@media (min-width: 1200px) {
  .mt40 {
    margin-top: 46px !important;
  }
}

.mt44 {
  margin-top: 44px !important;
  margin-top: 44px !important;
}
@media (min-width: 576px) {
  .mt44 {
    margin-top: 44px !important;
  }
}
@media (min-width: 768px) {
  .mt44 {
    margin-top: 44px !important;
  }
}
@media (min-width: 992px) {
  .mt44 {
    margin-top: 51px !important;
  }
}
@media (min-width: 1200px) {
  .mt44 {
    margin-top: 51px !important;
  }
}

.mt48 {
  margin-top: 48px !important;
  margin-top: 48px !important;
}
@media (min-width: 576px) {
  .mt48 {
    margin-top: 48px !important;
  }
}
@media (min-width: 768px) {
  .mt48 {
    margin-top: 48px !important;
  }
}
@media (min-width: 992px) {
  .mt48 {
    margin-top: 55px !important;
  }
}
@media (min-width: 1200px) {
  .mt48 {
    margin-top: 55px !important;
  }
}

.mt52 {
  margin-top: 52px !important;
  margin-top: 52px !important;
}
@media (min-width: 576px) {
  .mt52 {
    margin-top: 52px !important;
  }
}
@media (min-width: 768px) {
  .mt52 {
    margin-top: 52px !important;
  }
}
@media (min-width: 992px) {
  .mt52 {
    margin-top: 60px !important;
  }
}
@media (min-width: 1200px) {
  .mt52 {
    margin-top: 60px !important;
  }
}

.mt56 {
  margin-top: 56px !important;
  margin-top: 56px !important;
}
@media (min-width: 576px) {
  .mt56 {
    margin-top: 56px !important;
  }
}
@media (min-width: 768px) {
  .mt56 {
    margin-top: 56px !important;
  }
}
@media (min-width: 992px) {
  .mt56 {
    margin-top: 64px !important;
  }
}
@media (min-width: 1200px) {
  .mt56 {
    margin-top: 64px !important;
  }
}

.mt60 {
  margin-top: 60px !important;
  margin-top: 60px !important;
}
@media (min-width: 576px) {
  .mt60 {
    margin-top: 60px !important;
  }
}
@media (min-width: 768px) {
  .mt60 {
    margin-top: 60px !important;
  }
}
@media (min-width: 992px) {
  .mt60 {
    margin-top: 69px !important;
  }
}
@media (min-width: 1200px) {
  .mt60 {
    margin-top: 69px !important;
  }
}

.mt64 {
  margin-top: 64px !important;
  margin-top: 64px !important;
}
@media (min-width: 576px) {
  .mt64 {
    margin-top: 64px !important;
  }
}
@media (min-width: 768px) {
  .mt64 {
    margin-top: 64px !important;
  }
}
@media (min-width: 992px) {
  .mt64 {
    margin-top: 74px !important;
  }
}
@media (min-width: 1200px) {
  .mt64 {
    margin-top: 74px !important;
  }
}

.mt68 {
  margin-top: 68px !important;
  margin-top: 68px !important;
}
@media (min-width: 576px) {
  .mt68 {
    margin-top: 68px !important;
  }
}
@media (min-width: 768px) {
  .mt68 {
    margin-top: 68px !important;
  }
}
@media (min-width: 992px) {
  .mt68 {
    margin-top: 78px !important;
  }
}
@media (min-width: 1200px) {
  .mt68 {
    margin-top: 78px !important;
  }
}

.mt72 {
  margin-top: 72px !important;
  margin-top: 72px !important;
}
@media (min-width: 576px) {
  .mt72 {
    margin-top: 72px !important;
  }
}
@media (min-width: 768px) {
  .mt72 {
    margin-top: 72px !important;
  }
}
@media (min-width: 992px) {
  .mt72 {
    margin-top: 83px !important;
  }
}
@media (min-width: 1200px) {
  .mt72 {
    margin-top: 83px !important;
  }
}

.mt76 {
  margin-top: 76px !important;
  margin-top: 76px !important;
}
@media (min-width: 576px) {
  .mt76 {
    margin-top: 76px !important;
  }
}
@media (min-width: 768px) {
  .mt76 {
    margin-top: 76px !important;
  }
}
@media (min-width: 992px) {
  .mt76 {
    margin-top: 87px !important;
  }
}
@media (min-width: 1200px) {
  .mt76 {
    margin-top: 87px !important;
  }
}

.mt80 {
  margin-top: 80px !important;
  margin-top: 80px !important;
}
@media (min-width: 576px) {
  .mt80 {
    margin-top: 80px !important;
  }
}
@media (min-width: 768px) {
  .mt80 {
    margin-top: 80px !important;
  }
}
@media (min-width: 992px) {
  .mt80 {
    margin-top: 92px !important;
  }
}
@media (min-width: 1200px) {
  .mt80 {
    margin-top: 92px !important;
  }
}

.mt84 {
  margin-top: 84px !important;
  margin-top: 84px !important;
}
@media (min-width: 576px) {
  .mt84 {
    margin-top: 84px !important;
  }
}
@media (min-width: 768px) {
  .mt84 {
    margin-top: 84px !important;
  }
}
@media (min-width: 992px) {
  .mt84 {
    margin-top: 96px !important;
  }
}
@media (min-width: 1200px) {
  .mt84 {
    margin-top: 96px !important;
  }
}

.mt88 {
  margin-top: 88px !important;
  margin-top: 88px !important;
}
@media (min-width: 576px) {
  .mt88 {
    margin-top: 88px !important;
  }
}
@media (min-width: 768px) {
  .mt88 {
    margin-top: 88px !important;
  }
}
@media (min-width: 992px) {
  .mt88 {
    margin-top: 101px !important;
  }
}
@media (min-width: 1200px) {
  .mt88 {
    margin-top: 101px !important;
  }
}

.mt92 {
  margin-top: 92px !important;
  margin-top: 92px !important;
}
@media (min-width: 576px) {
  .mt92 {
    margin-top: 92px !important;
  }
}
@media (min-width: 768px) {
  .mt92 {
    margin-top: 92px !important;
  }
}
@media (min-width: 992px) {
  .mt92 {
    margin-top: 106px !important;
  }
}
@media (min-width: 1200px) {
  .mt92 {
    margin-top: 106px !important;
  }
}

.mt96 {
  margin-top: 96px !important;
  margin-top: 96px !important;
}
@media (min-width: 576px) {
  .mt96 {
    margin-top: 96px !important;
  }
}
@media (min-width: 768px) {
  .mt96 {
    margin-top: 96px !important;
  }
}
@media (min-width: 992px) {
  .mt96 {
    margin-top: 110px !important;
  }
}
@media (min-width: 1200px) {
  .mt96 {
    margin-top: 110px !important;
  }
}

.mt100 {
  margin-top: 100px !important;
  margin-top: 100px !important;
}
@media (min-width: 576px) {
  .mt100 {
    margin-top: 100px !important;
  }
}
@media (min-width: 768px) {
  .mt100 {
    margin-top: 100px !important;
  }
}
@media (min-width: 992px) {
  .mt100 {
    margin-top: 115px !important;
  }
}
@media (min-width: 1200px) {
  .mt100 {
    margin-top: 115px !important;
  }
}

.mb4 {
  margin-bottom: 4px !important;
  margin-bottom: 4px !important;
}
@media (min-width: 576px) {
  .mb4 {
    margin-bottom: 4px !important;
  }
}
@media (min-width: 768px) {
  .mb4 {
    margin-bottom: 4px !important;
  }
}
@media (min-width: 992px) {
  .mb4 {
    margin-bottom: 5px !important;
  }
}
@media (min-width: 1200px) {
  .mb4 {
    margin-bottom: 5px !important;
  }
}

.mb8 {
  margin-bottom: 8px !important;
  margin-bottom: 8px !important;
}
@media (min-width: 576px) {
  .mb8 {
    margin-bottom: 8px !important;
  }
}
@media (min-width: 768px) {
  .mb8 {
    margin-bottom: 8px !important;
  }
}
@media (min-width: 992px) {
  .mb8 {
    margin-bottom: 10px !important;
  }
}
@media (min-width: 1200px) {
  .mb8 {
    margin-bottom: 10px !important;
  }
}

.mb12 {
  margin-bottom: 12px !important;
  margin-bottom: 12px !important;
}
@media (min-width: 576px) {
  .mb12 {
    margin-bottom: 12px !important;
  }
}
@media (min-width: 768px) {
  .mb12 {
    margin-bottom: 12px !important;
  }
}
@media (min-width: 992px) {
  .mb12 {
    margin-bottom: 14px !important;
  }
}
@media (min-width: 1200px) {
  .mb12 {
    margin-bottom: 14px !important;
  }
}

.mb16 {
  margin-bottom: 16px !important;
  margin-bottom: 16px !important;
}
@media (min-width: 576px) {
  .mb16 {
    margin-bottom: 16px !important;
  }
}
@media (min-width: 768px) {
  .mb16 {
    margin-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  .mb16 {
    margin-bottom: 19px !important;
  }
}
@media (min-width: 1200px) {
  .mb16 {
    margin-bottom: 19px !important;
  }
}

.mb20 {
  margin-bottom: 20px !important;
  margin-bottom: 20px !important;
}
@media (min-width: 576px) {
  .mb20 {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 768px) {
  .mb20 {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 992px) {
  .mb20 {
    margin-bottom: 23px !important;
  }
}
@media (min-width: 1200px) {
  .mb20 {
    margin-bottom: 23px !important;
  }
}

.mb24 {
  margin-bottom: 24px !important;
  margin-bottom: 24px !important;
}
@media (min-width: 576px) {
  .mb24 {
    margin-bottom: 24px !important;
  }
}
@media (min-width: 768px) {
  .mb24 {
    margin-bottom: 24px !important;
  }
}
@media (min-width: 992px) {
  .mb24 {
    margin-bottom: 28px !important;
  }
}
@media (min-width: 1200px) {
  .mb24 {
    margin-bottom: 28px !important;
  }
}

.mb28 {
  margin-bottom: 28px !important;
  margin-bottom: 28px !important;
}
@media (min-width: 576px) {
  .mb28 {
    margin-bottom: 28px !important;
  }
}
@media (min-width: 768px) {
  .mb28 {
    margin-bottom: 28px !important;
  }
}
@media (min-width: 992px) {
  .mb28 {
    margin-bottom: 32px !important;
  }
}
@media (min-width: 1200px) {
  .mb28 {
    margin-bottom: 32px !important;
  }
}

.mb32 {
  margin-bottom: 32px !important;
  margin-bottom: 32px !important;
}
@media (min-width: 576px) {
  .mb32 {
    margin-bottom: 32px !important;
  }
}
@media (min-width: 768px) {
  .mb32 {
    margin-bottom: 32px !important;
  }
}
@media (min-width: 992px) {
  .mb32 {
    margin-bottom: 37px !important;
  }
}
@media (min-width: 1200px) {
  .mb32 {
    margin-bottom: 37px !important;
  }
}

.mb36 {
  margin-bottom: 36px !important;
  margin-bottom: 36px !important;
}
@media (min-width: 576px) {
  .mb36 {
    margin-bottom: 36px !important;
  }
}
@media (min-width: 768px) {
  .mb36 {
    margin-bottom: 36px !important;
  }
}
@media (min-width: 992px) {
  .mb36 {
    margin-bottom: 42px !important;
  }
}
@media (min-width: 1200px) {
  .mb36 {
    margin-bottom: 42px !important;
  }
}

.mb40 {
  margin-bottom: 40px !important;
  margin-bottom: 40px !important;
}
@media (min-width: 576px) {
  .mb40 {
    margin-bottom: 40px !important;
  }
}
@media (min-width: 768px) {
  .mb40 {
    margin-bottom: 40px !important;
  }
}
@media (min-width: 992px) {
  .mb40 {
    margin-bottom: 46px !important;
  }
}
@media (min-width: 1200px) {
  .mb40 {
    margin-bottom: 46px !important;
  }
}

.mb44 {
  margin-bottom: 44px !important;
  margin-bottom: 44px !important;
}
@media (min-width: 576px) {
  .mb44 {
    margin-bottom: 44px !important;
  }
}
@media (min-width: 768px) {
  .mb44 {
    margin-bottom: 44px !important;
  }
}
@media (min-width: 992px) {
  .mb44 {
    margin-bottom: 51px !important;
  }
}
@media (min-width: 1200px) {
  .mb44 {
    margin-bottom: 51px !important;
  }
}

.mb48 {
  margin-bottom: 48px !important;
  margin-bottom: 48px !important;
}
@media (min-width: 576px) {
  .mb48 {
    margin-bottom: 48px !important;
  }
}
@media (min-width: 768px) {
  .mb48 {
    margin-bottom: 48px !important;
  }
}
@media (min-width: 992px) {
  .mb48 {
    margin-bottom: 55px !important;
  }
}
@media (min-width: 1200px) {
  .mb48 {
    margin-bottom: 55px !important;
  }
}

.mb52 {
  margin-bottom: 52px !important;
  margin-bottom: 52px !important;
}
@media (min-width: 576px) {
  .mb52 {
    margin-bottom: 52px !important;
  }
}
@media (min-width: 768px) {
  .mb52 {
    margin-bottom: 52px !important;
  }
}
@media (min-width: 992px) {
  .mb52 {
    margin-bottom: 60px !important;
  }
}
@media (min-width: 1200px) {
  .mb52 {
    margin-bottom: 60px !important;
  }
}

.mb56 {
  margin-bottom: 56px !important;
  margin-bottom: 56px !important;
}
@media (min-width: 576px) {
  .mb56 {
    margin-bottom: 56px !important;
  }
}
@media (min-width: 768px) {
  .mb56 {
    margin-bottom: 56px !important;
  }
}
@media (min-width: 992px) {
  .mb56 {
    margin-bottom: 64px !important;
  }
}
@media (min-width: 1200px) {
  .mb56 {
    margin-bottom: 64px !important;
  }
}

.mb60 {
  margin-bottom: 60px !important;
  margin-bottom: 60px !important;
}
@media (min-width: 576px) {
  .mb60 {
    margin-bottom: 60px !important;
  }
}
@media (min-width: 768px) {
  .mb60 {
    margin-bottom: 60px !important;
  }
}
@media (min-width: 992px) {
  .mb60 {
    margin-bottom: 69px !important;
  }
}
@media (min-width: 1200px) {
  .mb60 {
    margin-bottom: 69px !important;
  }
}

.mb64 {
  margin-bottom: 64px !important;
  margin-bottom: 64px !important;
}
@media (min-width: 576px) {
  .mb64 {
    margin-bottom: 64px !important;
  }
}
@media (min-width: 768px) {
  .mb64 {
    margin-bottom: 64px !important;
  }
}
@media (min-width: 992px) {
  .mb64 {
    margin-bottom: 74px !important;
  }
}
@media (min-width: 1200px) {
  .mb64 {
    margin-bottom: 74px !important;
  }
}

.mb68 {
  margin-bottom: 68px !important;
  margin-bottom: 68px !important;
}
@media (min-width: 576px) {
  .mb68 {
    margin-bottom: 68px !important;
  }
}
@media (min-width: 768px) {
  .mb68 {
    margin-bottom: 68px !important;
  }
}
@media (min-width: 992px) {
  .mb68 {
    margin-bottom: 78px !important;
  }
}
@media (min-width: 1200px) {
  .mb68 {
    margin-bottom: 78px !important;
  }
}

.mb72 {
  margin-bottom: 72px !important;
  margin-bottom: 72px !important;
}
@media (min-width: 576px) {
  .mb72 {
    margin-bottom: 72px !important;
  }
}
@media (min-width: 768px) {
  .mb72 {
    margin-bottom: 72px !important;
  }
}
@media (min-width: 992px) {
  .mb72 {
    margin-bottom: 83px !important;
  }
}
@media (min-width: 1200px) {
  .mb72 {
    margin-bottom: 83px !important;
  }
}

.mb76 {
  margin-bottom: 76px !important;
  margin-bottom: 76px !important;
}
@media (min-width: 576px) {
  .mb76 {
    margin-bottom: 76px !important;
  }
}
@media (min-width: 768px) {
  .mb76 {
    margin-bottom: 76px !important;
  }
}
@media (min-width: 992px) {
  .mb76 {
    margin-bottom: 87px !important;
  }
}
@media (min-width: 1200px) {
  .mb76 {
    margin-bottom: 87px !important;
  }
}

.mb80 {
  margin-bottom: 80px !important;
  margin-bottom: 80px !important;
}
@media (min-width: 576px) {
  .mb80 {
    margin-bottom: 80px !important;
  }
}
@media (min-width: 768px) {
  .mb80 {
    margin-bottom: 80px !important;
  }
}
@media (min-width: 992px) {
  .mb80 {
    margin-bottom: 92px !important;
  }
}
@media (min-width: 1200px) {
  .mb80 {
    margin-bottom: 92px !important;
  }
}

.mb84 {
  margin-bottom: 84px !important;
  margin-bottom: 84px !important;
}
@media (min-width: 576px) {
  .mb84 {
    margin-bottom: 84px !important;
  }
}
@media (min-width: 768px) {
  .mb84 {
    margin-bottom: 84px !important;
  }
}
@media (min-width: 992px) {
  .mb84 {
    margin-bottom: 96px !important;
  }
}
@media (min-width: 1200px) {
  .mb84 {
    margin-bottom: 96px !important;
  }
}

.mb88 {
  margin-bottom: 88px !important;
  margin-bottom: 88px !important;
}
@media (min-width: 576px) {
  .mb88 {
    margin-bottom: 88px !important;
  }
}
@media (min-width: 768px) {
  .mb88 {
    margin-bottom: 88px !important;
  }
}
@media (min-width: 992px) {
  .mb88 {
    margin-bottom: 101px !important;
  }
}
@media (min-width: 1200px) {
  .mb88 {
    margin-bottom: 101px !important;
  }
}

.mb92 {
  margin-bottom: 92px !important;
  margin-bottom: 92px !important;
}
@media (min-width: 576px) {
  .mb92 {
    margin-bottom: 92px !important;
  }
}
@media (min-width: 768px) {
  .mb92 {
    margin-bottom: 92px !important;
  }
}
@media (min-width: 992px) {
  .mb92 {
    margin-bottom: 106px !important;
  }
}
@media (min-width: 1200px) {
  .mb92 {
    margin-bottom: 106px !important;
  }
}

.mb96 {
  margin-bottom: 96px !important;
  margin-bottom: 96px !important;
}
@media (min-width: 576px) {
  .mb96 {
    margin-bottom: 96px !important;
  }
}
@media (min-width: 768px) {
  .mb96 {
    margin-bottom: 96px !important;
  }
}
@media (min-width: 992px) {
  .mb96 {
    margin-bottom: 110px !important;
  }
}
@media (min-width: 1200px) {
  .mb96 {
    margin-bottom: 110px !important;
  }
}

.mb100 {
  margin-bottom: 100px !important;
  margin-bottom: 100px !important;
}
@media (min-width: 576px) {
  .mb100 {
    margin-bottom: 100px !important;
  }
}
@media (min-width: 768px) {
  .mb100 {
    margin-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .mb100 {
    margin-bottom: 115px !important;
  }
}
@media (min-width: 1200px) {
  .mb100 {
    margin-bottom: 115px !important;
  }
}

.mr4 {
  margin-right: 4px !important;
  margin-right: 4px !important;
}
@media (min-width: 576px) {
  .mr4 {
    margin-right: 4px !important;
  }
}
@media (min-width: 768px) {
  .mr4 {
    margin-right: 4px !important;
  }
}
@media (min-width: 992px) {
  .mr4 {
    margin-right: 5px !important;
  }
}
@media (min-width: 1200px) {
  .mr4 {
    margin-right: 5px !important;
  }
}

.mr8 {
  margin-right: 8px !important;
  margin-right: 8px !important;
}
@media (min-width: 576px) {
  .mr8 {
    margin-right: 8px !important;
  }
}
@media (min-width: 768px) {
  .mr8 {
    margin-right: 8px !important;
  }
}
@media (min-width: 992px) {
  .mr8 {
    margin-right: 10px !important;
  }
}
@media (min-width: 1200px) {
  .mr8 {
    margin-right: 10px !important;
  }
}

.mr12 {
  margin-right: 12px !important;
  margin-right: 12px !important;
}
@media (min-width: 576px) {
  .mr12 {
    margin-right: 12px !important;
  }
}
@media (min-width: 768px) {
  .mr12 {
    margin-right: 12px !important;
  }
}
@media (min-width: 992px) {
  .mr12 {
    margin-right: 14px !important;
  }
}
@media (min-width: 1200px) {
  .mr12 {
    margin-right: 14px !important;
  }
}

.mr16 {
  margin-right: 16px !important;
  margin-right: 16px !important;
}
@media (min-width: 576px) {
  .mr16 {
    margin-right: 16px !important;
  }
}
@media (min-width: 768px) {
  .mr16 {
    margin-right: 16px !important;
  }
}
@media (min-width: 992px) {
  .mr16 {
    margin-right: 19px !important;
  }
}
@media (min-width: 1200px) {
  .mr16 {
    margin-right: 19px !important;
  }
}

.mr20 {
  margin-right: 20px !important;
  margin-right: 20px !important;
}
@media (min-width: 576px) {
  .mr20 {
    margin-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .mr20 {
    margin-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .mr20 {
    margin-right: 23px !important;
  }
}
@media (min-width: 1200px) {
  .mr20 {
    margin-right: 23px !important;
  }
}

.mr24 {
  margin-right: 24px !important;
  margin-right: 24px !important;
}
@media (min-width: 576px) {
  .mr24 {
    margin-right: 24px !important;
  }
}
@media (min-width: 768px) {
  .mr24 {
    margin-right: 24px !important;
  }
}
@media (min-width: 992px) {
  .mr24 {
    margin-right: 28px !important;
  }
}
@media (min-width: 1200px) {
  .mr24 {
    margin-right: 28px !important;
  }
}

.mr28 {
  margin-right: 28px !important;
  margin-right: 28px !important;
}
@media (min-width: 576px) {
  .mr28 {
    margin-right: 28px !important;
  }
}
@media (min-width: 768px) {
  .mr28 {
    margin-right: 28px !important;
  }
}
@media (min-width: 992px) {
  .mr28 {
    margin-right: 32px !important;
  }
}
@media (min-width: 1200px) {
  .mr28 {
    margin-right: 32px !important;
  }
}

.mr32 {
  margin-right: 32px !important;
  margin-right: 32px !important;
}
@media (min-width: 576px) {
  .mr32 {
    margin-right: 32px !important;
  }
}
@media (min-width: 768px) {
  .mr32 {
    margin-right: 32px !important;
  }
}
@media (min-width: 992px) {
  .mr32 {
    margin-right: 37px !important;
  }
}
@media (min-width: 1200px) {
  .mr32 {
    margin-right: 37px !important;
  }
}

.mr36 {
  margin-right: 36px !important;
  margin-right: 36px !important;
}
@media (min-width: 576px) {
  .mr36 {
    margin-right: 36px !important;
  }
}
@media (min-width: 768px) {
  .mr36 {
    margin-right: 36px !important;
  }
}
@media (min-width: 992px) {
  .mr36 {
    margin-right: 42px !important;
  }
}
@media (min-width: 1200px) {
  .mr36 {
    margin-right: 42px !important;
  }
}

.mr40 {
  margin-right: 40px !important;
  margin-right: 40px !important;
}
@media (min-width: 576px) {
  .mr40 {
    margin-right: 40px !important;
  }
}
@media (min-width: 768px) {
  .mr40 {
    margin-right: 40px !important;
  }
}
@media (min-width: 992px) {
  .mr40 {
    margin-right: 46px !important;
  }
}
@media (min-width: 1200px) {
  .mr40 {
    margin-right: 46px !important;
  }
}

.mr44 {
  margin-right: 44px !important;
  margin-right: 44px !important;
}
@media (min-width: 576px) {
  .mr44 {
    margin-right: 44px !important;
  }
}
@media (min-width: 768px) {
  .mr44 {
    margin-right: 44px !important;
  }
}
@media (min-width: 992px) {
  .mr44 {
    margin-right: 51px !important;
  }
}
@media (min-width: 1200px) {
  .mr44 {
    margin-right: 51px !important;
  }
}

.mr48 {
  margin-right: 48px !important;
  margin-right: 48px !important;
}
@media (min-width: 576px) {
  .mr48 {
    margin-right: 48px !important;
  }
}
@media (min-width: 768px) {
  .mr48 {
    margin-right: 48px !important;
  }
}
@media (min-width: 992px) {
  .mr48 {
    margin-right: 55px !important;
  }
}
@media (min-width: 1200px) {
  .mr48 {
    margin-right: 55px !important;
  }
}

.mr52 {
  margin-right: 52px !important;
  margin-right: 52px !important;
}
@media (min-width: 576px) {
  .mr52 {
    margin-right: 52px !important;
  }
}
@media (min-width: 768px) {
  .mr52 {
    margin-right: 52px !important;
  }
}
@media (min-width: 992px) {
  .mr52 {
    margin-right: 60px !important;
  }
}
@media (min-width: 1200px) {
  .mr52 {
    margin-right: 60px !important;
  }
}

.mr56 {
  margin-right: 56px !important;
  margin-right: 56px !important;
}
@media (min-width: 576px) {
  .mr56 {
    margin-right: 56px !important;
  }
}
@media (min-width: 768px) {
  .mr56 {
    margin-right: 56px !important;
  }
}
@media (min-width: 992px) {
  .mr56 {
    margin-right: 64px !important;
  }
}
@media (min-width: 1200px) {
  .mr56 {
    margin-right: 64px !important;
  }
}

.mr60 {
  margin-right: 60px !important;
  margin-right: 60px !important;
}
@media (min-width: 576px) {
  .mr60 {
    margin-right: 60px !important;
  }
}
@media (min-width: 768px) {
  .mr60 {
    margin-right: 60px !important;
  }
}
@media (min-width: 992px) {
  .mr60 {
    margin-right: 69px !important;
  }
}
@media (min-width: 1200px) {
  .mr60 {
    margin-right: 69px !important;
  }
}

.mr64 {
  margin-right: 64px !important;
  margin-right: 64px !important;
}
@media (min-width: 576px) {
  .mr64 {
    margin-right: 64px !important;
  }
}
@media (min-width: 768px) {
  .mr64 {
    margin-right: 64px !important;
  }
}
@media (min-width: 992px) {
  .mr64 {
    margin-right: 74px !important;
  }
}
@media (min-width: 1200px) {
  .mr64 {
    margin-right: 74px !important;
  }
}

.mr68 {
  margin-right: 68px !important;
  margin-right: 68px !important;
}
@media (min-width: 576px) {
  .mr68 {
    margin-right: 68px !important;
  }
}
@media (min-width: 768px) {
  .mr68 {
    margin-right: 68px !important;
  }
}
@media (min-width: 992px) {
  .mr68 {
    margin-right: 78px !important;
  }
}
@media (min-width: 1200px) {
  .mr68 {
    margin-right: 78px !important;
  }
}

.mr72 {
  margin-right: 72px !important;
  margin-right: 72px !important;
}
@media (min-width: 576px) {
  .mr72 {
    margin-right: 72px !important;
  }
}
@media (min-width: 768px) {
  .mr72 {
    margin-right: 72px !important;
  }
}
@media (min-width: 992px) {
  .mr72 {
    margin-right: 83px !important;
  }
}
@media (min-width: 1200px) {
  .mr72 {
    margin-right: 83px !important;
  }
}

.mr76 {
  margin-right: 76px !important;
  margin-right: 76px !important;
}
@media (min-width: 576px) {
  .mr76 {
    margin-right: 76px !important;
  }
}
@media (min-width: 768px) {
  .mr76 {
    margin-right: 76px !important;
  }
}
@media (min-width: 992px) {
  .mr76 {
    margin-right: 87px !important;
  }
}
@media (min-width: 1200px) {
  .mr76 {
    margin-right: 87px !important;
  }
}

.mr80 {
  margin-right: 80px !important;
  margin-right: 80px !important;
}
@media (min-width: 576px) {
  .mr80 {
    margin-right: 80px !important;
  }
}
@media (min-width: 768px) {
  .mr80 {
    margin-right: 80px !important;
  }
}
@media (min-width: 992px) {
  .mr80 {
    margin-right: 92px !important;
  }
}
@media (min-width: 1200px) {
  .mr80 {
    margin-right: 92px !important;
  }
}

.mr84 {
  margin-right: 84px !important;
  margin-right: 84px !important;
}
@media (min-width: 576px) {
  .mr84 {
    margin-right: 84px !important;
  }
}
@media (min-width: 768px) {
  .mr84 {
    margin-right: 84px !important;
  }
}
@media (min-width: 992px) {
  .mr84 {
    margin-right: 96px !important;
  }
}
@media (min-width: 1200px) {
  .mr84 {
    margin-right: 96px !important;
  }
}

.mr88 {
  margin-right: 88px !important;
  margin-right: 88px !important;
}
@media (min-width: 576px) {
  .mr88 {
    margin-right: 88px !important;
  }
}
@media (min-width: 768px) {
  .mr88 {
    margin-right: 88px !important;
  }
}
@media (min-width: 992px) {
  .mr88 {
    margin-right: 101px !important;
  }
}
@media (min-width: 1200px) {
  .mr88 {
    margin-right: 101px !important;
  }
}

.mr92 {
  margin-right: 92px !important;
  margin-right: 92px !important;
}
@media (min-width: 576px) {
  .mr92 {
    margin-right: 92px !important;
  }
}
@media (min-width: 768px) {
  .mr92 {
    margin-right: 92px !important;
  }
}
@media (min-width: 992px) {
  .mr92 {
    margin-right: 106px !important;
  }
}
@media (min-width: 1200px) {
  .mr92 {
    margin-right: 106px !important;
  }
}

.mr96 {
  margin-right: 96px !important;
  margin-right: 96px !important;
}
@media (min-width: 576px) {
  .mr96 {
    margin-right: 96px !important;
  }
}
@media (min-width: 768px) {
  .mr96 {
    margin-right: 96px !important;
  }
}
@media (min-width: 992px) {
  .mr96 {
    margin-right: 110px !important;
  }
}
@media (min-width: 1200px) {
  .mr96 {
    margin-right: 110px !important;
  }
}

.mr100 {
  margin-right: 100px !important;
  margin-right: 100px !important;
}
@media (min-width: 576px) {
  .mr100 {
    margin-right: 100px !important;
  }
}
@media (min-width: 768px) {
  .mr100 {
    margin-right: 100px !important;
  }
}
@media (min-width: 992px) {
  .mr100 {
    margin-right: 115px !important;
  }
}
@media (min-width: 1200px) {
  .mr100 {
    margin-right: 115px !important;
  }
}

.ml4 {
  margin-left: 4px !important;
  margin-left: 4px !important;
}
@media (min-width: 576px) {
  .ml4 {
    margin-left: 4px !important;
  }
}
@media (min-width: 768px) {
  .ml4 {
    margin-left: 4px !important;
  }
}
@media (min-width: 992px) {
  .ml4 {
    margin-left: 5px !important;
  }
}
@media (min-width: 1200px) {
  .ml4 {
    margin-left: 5px !important;
  }
}

.ml8 {
  margin-left: 8px !important;
  margin-left: 8px !important;
}
@media (min-width: 576px) {
  .ml8 {
    margin-left: 8px !important;
  }
}
@media (min-width: 768px) {
  .ml8 {
    margin-left: 8px !important;
  }
}
@media (min-width: 992px) {
  .ml8 {
    margin-left: 10px !important;
  }
}
@media (min-width: 1200px) {
  .ml8 {
    margin-left: 10px !important;
  }
}

.ml12 {
  margin-left: 12px !important;
  margin-left: 12px !important;
}
@media (min-width: 576px) {
  .ml12 {
    margin-left: 12px !important;
  }
}
@media (min-width: 768px) {
  .ml12 {
    margin-left: 12px !important;
  }
}
@media (min-width: 992px) {
  .ml12 {
    margin-left: 14px !important;
  }
}
@media (min-width: 1200px) {
  .ml12 {
    margin-left: 14px !important;
  }
}

.ml16 {
  margin-left: 16px !important;
  margin-left: 16px !important;
}
@media (min-width: 576px) {
  .ml16 {
    margin-left: 16px !important;
  }
}
@media (min-width: 768px) {
  .ml16 {
    margin-left: 16px !important;
  }
}
@media (min-width: 992px) {
  .ml16 {
    margin-left: 19px !important;
  }
}
@media (min-width: 1200px) {
  .ml16 {
    margin-left: 19px !important;
  }
}

.ml20 {
  margin-left: 20px !important;
  margin-left: 20px !important;
}
@media (min-width: 576px) {
  .ml20 {
    margin-left: 20px !important;
  }
}
@media (min-width: 768px) {
  .ml20 {
    margin-left: 20px !important;
  }
}
@media (min-width: 992px) {
  .ml20 {
    margin-left: 23px !important;
  }
}
@media (min-width: 1200px) {
  .ml20 {
    margin-left: 23px !important;
  }
}

.ml24 {
  margin-left: 24px !important;
  margin-left: 24px !important;
}
@media (min-width: 576px) {
  .ml24 {
    margin-left: 24px !important;
  }
}
@media (min-width: 768px) {
  .ml24 {
    margin-left: 24px !important;
  }
}
@media (min-width: 992px) {
  .ml24 {
    margin-left: 28px !important;
  }
}
@media (min-width: 1200px) {
  .ml24 {
    margin-left: 28px !important;
  }
}

.ml28 {
  margin-left: 28px !important;
  margin-left: 28px !important;
}
@media (min-width: 576px) {
  .ml28 {
    margin-left: 28px !important;
  }
}
@media (min-width: 768px) {
  .ml28 {
    margin-left: 28px !important;
  }
}
@media (min-width: 992px) {
  .ml28 {
    margin-left: 32px !important;
  }
}
@media (min-width: 1200px) {
  .ml28 {
    margin-left: 32px !important;
  }
}

.ml32 {
  margin-left: 32px !important;
  margin-left: 32px !important;
}
@media (min-width: 576px) {
  .ml32 {
    margin-left: 32px !important;
  }
}
@media (min-width: 768px) {
  .ml32 {
    margin-left: 32px !important;
  }
}
@media (min-width: 992px) {
  .ml32 {
    margin-left: 37px !important;
  }
}
@media (min-width: 1200px) {
  .ml32 {
    margin-left: 37px !important;
  }
}

.ml36 {
  margin-left: 36px !important;
  margin-left: 36px !important;
}
@media (min-width: 576px) {
  .ml36 {
    margin-left: 36px !important;
  }
}
@media (min-width: 768px) {
  .ml36 {
    margin-left: 36px !important;
  }
}
@media (min-width: 992px) {
  .ml36 {
    margin-left: 42px !important;
  }
}
@media (min-width: 1200px) {
  .ml36 {
    margin-left: 42px !important;
  }
}

.ml40 {
  margin-left: 40px !important;
  margin-left: 40px !important;
}
@media (min-width: 576px) {
  .ml40 {
    margin-left: 40px !important;
  }
}
@media (min-width: 768px) {
  .ml40 {
    margin-left: 40px !important;
  }
}
@media (min-width: 992px) {
  .ml40 {
    margin-left: 46px !important;
  }
}
@media (min-width: 1200px) {
  .ml40 {
    margin-left: 46px !important;
  }
}

.ml44 {
  margin-left: 44px !important;
  margin-left: 44px !important;
}
@media (min-width: 576px) {
  .ml44 {
    margin-left: 44px !important;
  }
}
@media (min-width: 768px) {
  .ml44 {
    margin-left: 44px !important;
  }
}
@media (min-width: 992px) {
  .ml44 {
    margin-left: 51px !important;
  }
}
@media (min-width: 1200px) {
  .ml44 {
    margin-left: 51px !important;
  }
}

.ml48 {
  margin-left: 48px !important;
  margin-left: 48px !important;
}
@media (min-width: 576px) {
  .ml48 {
    margin-left: 48px !important;
  }
}
@media (min-width: 768px) {
  .ml48 {
    margin-left: 48px !important;
  }
}
@media (min-width: 992px) {
  .ml48 {
    margin-left: 55px !important;
  }
}
@media (min-width: 1200px) {
  .ml48 {
    margin-left: 55px !important;
  }
}

.ml52 {
  margin-left: 52px !important;
  margin-left: 52px !important;
}
@media (min-width: 576px) {
  .ml52 {
    margin-left: 52px !important;
  }
}
@media (min-width: 768px) {
  .ml52 {
    margin-left: 52px !important;
  }
}
@media (min-width: 992px) {
  .ml52 {
    margin-left: 60px !important;
  }
}
@media (min-width: 1200px) {
  .ml52 {
    margin-left: 60px !important;
  }
}

.ml56 {
  margin-left: 56px !important;
  margin-left: 56px !important;
}
@media (min-width: 576px) {
  .ml56 {
    margin-left: 56px !important;
  }
}
@media (min-width: 768px) {
  .ml56 {
    margin-left: 56px !important;
  }
}
@media (min-width: 992px) {
  .ml56 {
    margin-left: 64px !important;
  }
}
@media (min-width: 1200px) {
  .ml56 {
    margin-left: 64px !important;
  }
}

.ml60 {
  margin-left: 60px !important;
  margin-left: 60px !important;
}
@media (min-width: 576px) {
  .ml60 {
    margin-left: 60px !important;
  }
}
@media (min-width: 768px) {
  .ml60 {
    margin-left: 60px !important;
  }
}
@media (min-width: 992px) {
  .ml60 {
    margin-left: 69px !important;
  }
}
@media (min-width: 1200px) {
  .ml60 {
    margin-left: 69px !important;
  }
}

.ml64 {
  margin-left: 64px !important;
  margin-left: 64px !important;
}
@media (min-width: 576px) {
  .ml64 {
    margin-left: 64px !important;
  }
}
@media (min-width: 768px) {
  .ml64 {
    margin-left: 64px !important;
  }
}
@media (min-width: 992px) {
  .ml64 {
    margin-left: 74px !important;
  }
}
@media (min-width: 1200px) {
  .ml64 {
    margin-left: 74px !important;
  }
}

.ml68 {
  margin-left: 68px !important;
  margin-left: 68px !important;
}
@media (min-width: 576px) {
  .ml68 {
    margin-left: 68px !important;
  }
}
@media (min-width: 768px) {
  .ml68 {
    margin-left: 68px !important;
  }
}
@media (min-width: 992px) {
  .ml68 {
    margin-left: 78px !important;
  }
}
@media (min-width: 1200px) {
  .ml68 {
    margin-left: 78px !important;
  }
}

.ml72 {
  margin-left: 72px !important;
  margin-left: 72px !important;
}
@media (min-width: 576px) {
  .ml72 {
    margin-left: 72px !important;
  }
}
@media (min-width: 768px) {
  .ml72 {
    margin-left: 72px !important;
  }
}
@media (min-width: 992px) {
  .ml72 {
    margin-left: 83px !important;
  }
}
@media (min-width: 1200px) {
  .ml72 {
    margin-left: 83px !important;
  }
}

.ml76 {
  margin-left: 76px !important;
  margin-left: 76px !important;
}
@media (min-width: 576px) {
  .ml76 {
    margin-left: 76px !important;
  }
}
@media (min-width: 768px) {
  .ml76 {
    margin-left: 76px !important;
  }
}
@media (min-width: 992px) {
  .ml76 {
    margin-left: 87px !important;
  }
}
@media (min-width: 1200px) {
  .ml76 {
    margin-left: 87px !important;
  }
}

.ml80 {
  margin-left: 80px !important;
  margin-left: 80px !important;
}
@media (min-width: 576px) {
  .ml80 {
    margin-left: 80px !important;
  }
}
@media (min-width: 768px) {
  .ml80 {
    margin-left: 80px !important;
  }
}
@media (min-width: 992px) {
  .ml80 {
    margin-left: 92px !important;
  }
}
@media (min-width: 1200px) {
  .ml80 {
    margin-left: 92px !important;
  }
}

.ml84 {
  margin-left: 84px !important;
  margin-left: 84px !important;
}
@media (min-width: 576px) {
  .ml84 {
    margin-left: 84px !important;
  }
}
@media (min-width: 768px) {
  .ml84 {
    margin-left: 84px !important;
  }
}
@media (min-width: 992px) {
  .ml84 {
    margin-left: 96px !important;
  }
}
@media (min-width: 1200px) {
  .ml84 {
    margin-left: 96px !important;
  }
}

.ml88 {
  margin-left: 88px !important;
  margin-left: 88px !important;
}
@media (min-width: 576px) {
  .ml88 {
    margin-left: 88px !important;
  }
}
@media (min-width: 768px) {
  .ml88 {
    margin-left: 88px !important;
  }
}
@media (min-width: 992px) {
  .ml88 {
    margin-left: 101px !important;
  }
}
@media (min-width: 1200px) {
  .ml88 {
    margin-left: 101px !important;
  }
}

.ml92 {
  margin-left: 92px !important;
  margin-left: 92px !important;
}
@media (min-width: 576px) {
  .ml92 {
    margin-left: 92px !important;
  }
}
@media (min-width: 768px) {
  .ml92 {
    margin-left: 92px !important;
  }
}
@media (min-width: 992px) {
  .ml92 {
    margin-left: 106px !important;
  }
}
@media (min-width: 1200px) {
  .ml92 {
    margin-left: 106px !important;
  }
}

.ml96 {
  margin-left: 96px !important;
  margin-left: 96px !important;
}
@media (min-width: 576px) {
  .ml96 {
    margin-left: 96px !important;
  }
}
@media (min-width: 768px) {
  .ml96 {
    margin-left: 96px !important;
  }
}
@media (min-width: 992px) {
  .ml96 {
    margin-left: 110px !important;
  }
}
@media (min-width: 1200px) {
  .ml96 {
    margin-left: 110px !important;
  }
}

.ml100 {
  margin-left: 100px !important;
  margin-left: 100px !important;
}
@media (min-width: 576px) {
  .ml100 {
    margin-left: 100px !important;
  }
}
@media (min-width: 768px) {
  .ml100 {
    margin-left: 100px !important;
  }
}
@media (min-width: 992px) {
  .ml100 {
    margin-left: 115px !important;
  }
}
@media (min-width: 1200px) {
  .ml100 {
    margin-left: 115px !important;
  }
}

.pt4 {
  padding-top: 4px !important;
  padding-top: 4px !important;
}
@media (min-width: 576px) {
  .pt4 {
    padding-top: 4px !important;
  }
}
@media (min-width: 768px) {
  .pt4 {
    padding-top: 4px !important;
  }
}
@media (min-width: 992px) {
  .pt4 {
    padding-top: 5px !important;
  }
}
@media (min-width: 1200px) {
  .pt4 {
    padding-top: 5px !important;
  }
}

.pt8 {
  padding-top: 8px !important;
  padding-top: 8px !important;
}
@media (min-width: 576px) {
  .pt8 {
    padding-top: 8px !important;
  }
}
@media (min-width: 768px) {
  .pt8 {
    padding-top: 8px !important;
  }
}
@media (min-width: 992px) {
  .pt8 {
    padding-top: 10px !important;
  }
}
@media (min-width: 1200px) {
  .pt8 {
    padding-top: 10px !important;
  }
}

.pt12 {
  padding-top: 12px !important;
  padding-top: 12px !important;
}
@media (min-width: 576px) {
  .pt12 {
    padding-top: 12px !important;
  }
}
@media (min-width: 768px) {
  .pt12 {
    padding-top: 12px !important;
  }
}
@media (min-width: 992px) {
  .pt12 {
    padding-top: 14px !important;
  }
}
@media (min-width: 1200px) {
  .pt12 {
    padding-top: 14px !important;
  }
}

.pt16 {
  padding-top: 16px !important;
  padding-top: 16px !important;
}
@media (min-width: 576px) {
  .pt16 {
    padding-top: 16px !important;
  }
}
@media (min-width: 768px) {
  .pt16 {
    padding-top: 16px !important;
  }
}
@media (min-width: 992px) {
  .pt16 {
    padding-top: 19px !important;
  }
}
@media (min-width: 1200px) {
  .pt16 {
    padding-top: 19px !important;
  }
}

.pt20 {
  padding-top: 20px !important;
  padding-top: 20px !important;
}
@media (min-width: 576px) {
  .pt20 {
    padding-top: 20px !important;
  }
}
@media (min-width: 768px) {
  .pt20 {
    padding-top: 20px !important;
  }
}
@media (min-width: 992px) {
  .pt20 {
    padding-top: 23px !important;
  }
}
@media (min-width: 1200px) {
  .pt20 {
    padding-top: 23px !important;
  }
}

.pt24 {
  padding-top: 24px !important;
  padding-top: 24px !important;
}
@media (min-width: 576px) {
  .pt24 {
    padding-top: 24px !important;
  }
}
@media (min-width: 768px) {
  .pt24 {
    padding-top: 24px !important;
  }
}
@media (min-width: 992px) {
  .pt24 {
    padding-top: 28px !important;
  }
}
@media (min-width: 1200px) {
  .pt24 {
    padding-top: 28px !important;
  }
}

.pt28 {
  padding-top: 28px !important;
  padding-top: 28px !important;
}
@media (min-width: 576px) {
  .pt28 {
    padding-top: 28px !important;
  }
}
@media (min-width: 768px) {
  .pt28 {
    padding-top: 28px !important;
  }
}
@media (min-width: 992px) {
  .pt28 {
    padding-top: 32px !important;
  }
}
@media (min-width: 1200px) {
  .pt28 {
    padding-top: 32px !important;
  }
}

.pt32 {
  padding-top: 32px !important;
  padding-top: 32px !important;
}
@media (min-width: 576px) {
  .pt32 {
    padding-top: 32px !important;
  }
}
@media (min-width: 768px) {
  .pt32 {
    padding-top: 32px !important;
  }
}
@media (min-width: 992px) {
  .pt32 {
    padding-top: 37px !important;
  }
}
@media (min-width: 1200px) {
  .pt32 {
    padding-top: 37px !important;
  }
}

.pt36 {
  padding-top: 36px !important;
  padding-top: 36px !important;
}
@media (min-width: 576px) {
  .pt36 {
    padding-top: 36px !important;
  }
}
@media (min-width: 768px) {
  .pt36 {
    padding-top: 36px !important;
  }
}
@media (min-width: 992px) {
  .pt36 {
    padding-top: 42px !important;
  }
}
@media (min-width: 1200px) {
  .pt36 {
    padding-top: 42px !important;
  }
}

.pt40 {
  padding-top: 40px !important;
  padding-top: 40px !important;
}
@media (min-width: 576px) {
  .pt40 {
    padding-top: 40px !important;
  }
}
@media (min-width: 768px) {
  .pt40 {
    padding-top: 40px !important;
  }
}
@media (min-width: 992px) {
  .pt40 {
    padding-top: 46px !important;
  }
}
@media (min-width: 1200px) {
  .pt40 {
    padding-top: 46px !important;
  }
}

.pt44 {
  padding-top: 44px !important;
  padding-top: 44px !important;
}
@media (min-width: 576px) {
  .pt44 {
    padding-top: 44px !important;
  }
}
@media (min-width: 768px) {
  .pt44 {
    padding-top: 44px !important;
  }
}
@media (min-width: 992px) {
  .pt44 {
    padding-top: 51px !important;
  }
}
@media (min-width: 1200px) {
  .pt44 {
    padding-top: 51px !important;
  }
}

.pt48 {
  padding-top: 48px !important;
  padding-top: 48px !important;
}
@media (min-width: 576px) {
  .pt48 {
    padding-top: 48px !important;
  }
}
@media (min-width: 768px) {
  .pt48 {
    padding-top: 48px !important;
  }
}
@media (min-width: 992px) {
  .pt48 {
    padding-top: 55px !important;
  }
}
@media (min-width: 1200px) {
  .pt48 {
    padding-top: 55px !important;
  }
}

.pt52 {
  padding-top: 52px !important;
  padding-top: 52px !important;
}
@media (min-width: 576px) {
  .pt52 {
    padding-top: 52px !important;
  }
}
@media (min-width: 768px) {
  .pt52 {
    padding-top: 52px !important;
  }
}
@media (min-width: 992px) {
  .pt52 {
    padding-top: 60px !important;
  }
}
@media (min-width: 1200px) {
  .pt52 {
    padding-top: 60px !important;
  }
}

.pt56 {
  padding-top: 56px !important;
  padding-top: 56px !important;
}
@media (min-width: 576px) {
  .pt56 {
    padding-top: 56px !important;
  }
}
@media (min-width: 768px) {
  .pt56 {
    padding-top: 56px !important;
  }
}
@media (min-width: 992px) {
  .pt56 {
    padding-top: 64px !important;
  }
}
@media (min-width: 1200px) {
  .pt56 {
    padding-top: 64px !important;
  }
}

.pt60 {
  padding-top: 60px !important;
  padding-top: 60px !important;
}
@media (min-width: 576px) {
  .pt60 {
    padding-top: 60px !important;
  }
}
@media (min-width: 768px) {
  .pt60 {
    padding-top: 60px !important;
  }
}
@media (min-width: 992px) {
  .pt60 {
    padding-top: 69px !important;
  }
}
@media (min-width: 1200px) {
  .pt60 {
    padding-top: 69px !important;
  }
}

.pt64 {
  padding-top: 64px !important;
  padding-top: 64px !important;
}
@media (min-width: 576px) {
  .pt64 {
    padding-top: 64px !important;
  }
}
@media (min-width: 768px) {
  .pt64 {
    padding-top: 64px !important;
  }
}
@media (min-width: 992px) {
  .pt64 {
    padding-top: 74px !important;
  }
}
@media (min-width: 1200px) {
  .pt64 {
    padding-top: 74px !important;
  }
}

.pt68 {
  padding-top: 68px !important;
  padding-top: 68px !important;
}
@media (min-width: 576px) {
  .pt68 {
    padding-top: 68px !important;
  }
}
@media (min-width: 768px) {
  .pt68 {
    padding-top: 68px !important;
  }
}
@media (min-width: 992px) {
  .pt68 {
    padding-top: 78px !important;
  }
}
@media (min-width: 1200px) {
  .pt68 {
    padding-top: 78px !important;
  }
}

.pt72 {
  padding-top: 72px !important;
  padding-top: 72px !important;
}
@media (min-width: 576px) {
  .pt72 {
    padding-top: 72px !important;
  }
}
@media (min-width: 768px) {
  .pt72 {
    padding-top: 72px !important;
  }
}
@media (min-width: 992px) {
  .pt72 {
    padding-top: 83px !important;
  }
}
@media (min-width: 1200px) {
  .pt72 {
    padding-top: 83px !important;
  }
}

.pt76 {
  padding-top: 76px !important;
  padding-top: 76px !important;
}
@media (min-width: 576px) {
  .pt76 {
    padding-top: 76px !important;
  }
}
@media (min-width: 768px) {
  .pt76 {
    padding-top: 76px !important;
  }
}
@media (min-width: 992px) {
  .pt76 {
    padding-top: 87px !important;
  }
}
@media (min-width: 1200px) {
  .pt76 {
    padding-top: 87px !important;
  }
}

.pt80 {
  padding-top: 80px !important;
  padding-top: 80px !important;
}
@media (min-width: 576px) {
  .pt80 {
    padding-top: 80px !important;
  }
}
@media (min-width: 768px) {
  .pt80 {
    padding-top: 80px !important;
  }
}
@media (min-width: 992px) {
  .pt80 {
    padding-top: 92px !important;
  }
}
@media (min-width: 1200px) {
  .pt80 {
    padding-top: 92px !important;
  }
}

.pt84 {
  padding-top: 84px !important;
  padding-top: 84px !important;
}
@media (min-width: 576px) {
  .pt84 {
    padding-top: 84px !important;
  }
}
@media (min-width: 768px) {
  .pt84 {
    padding-top: 84px !important;
  }
}
@media (min-width: 992px) {
  .pt84 {
    padding-top: 96px !important;
  }
}
@media (min-width: 1200px) {
  .pt84 {
    padding-top: 96px !important;
  }
}

.pt88 {
  padding-top: 88px !important;
  padding-top: 88px !important;
}
@media (min-width: 576px) {
  .pt88 {
    padding-top: 88px !important;
  }
}
@media (min-width: 768px) {
  .pt88 {
    padding-top: 88px !important;
  }
}
@media (min-width: 992px) {
  .pt88 {
    padding-top: 101px !important;
  }
}
@media (min-width: 1200px) {
  .pt88 {
    padding-top: 101px !important;
  }
}

.pt92 {
  padding-top: 92px !important;
  padding-top: 92px !important;
}
@media (min-width: 576px) {
  .pt92 {
    padding-top: 92px !important;
  }
}
@media (min-width: 768px) {
  .pt92 {
    padding-top: 92px !important;
  }
}
@media (min-width: 992px) {
  .pt92 {
    padding-top: 106px !important;
  }
}
@media (min-width: 1200px) {
  .pt92 {
    padding-top: 106px !important;
  }
}

.pt96 {
  padding-top: 96px !important;
  padding-top: 96px !important;
}
@media (min-width: 576px) {
  .pt96 {
    padding-top: 96px !important;
  }
}
@media (min-width: 768px) {
  .pt96 {
    padding-top: 96px !important;
  }
}
@media (min-width: 992px) {
  .pt96 {
    padding-top: 110px !important;
  }
}
@media (min-width: 1200px) {
  .pt96 {
    padding-top: 110px !important;
  }
}

.pt100 {
  padding-top: 100px !important;
  padding-top: 100px !important;
}
@media (min-width: 576px) {
  .pt100 {
    padding-top: 100px !important;
  }
}
@media (min-width: 768px) {
  .pt100 {
    padding-top: 100px !important;
  }
}
@media (min-width: 992px) {
  .pt100 {
    padding-top: 115px !important;
  }
}
@media (min-width: 1200px) {
  .pt100 {
    padding-top: 115px !important;
  }
}

.pb4 {
  padding-bottom: 4px !important;
  padding-bottom: 4px !important;
}
@media (min-width: 576px) {
  .pb4 {
    padding-bottom: 4px !important;
  }
}
@media (min-width: 768px) {
  .pb4 {
    padding-bottom: 4px !important;
  }
}
@media (min-width: 992px) {
  .pb4 {
    padding-bottom: 5px !important;
  }
}
@media (min-width: 1200px) {
  .pb4 {
    padding-bottom: 5px !important;
  }
}

.pb8 {
  padding-bottom: 8px !important;
  padding-bottom: 8px !important;
}
@media (min-width: 576px) {
  .pb8 {
    padding-bottom: 8px !important;
  }
}
@media (min-width: 768px) {
  .pb8 {
    padding-bottom: 8px !important;
  }
}
@media (min-width: 992px) {
  .pb8 {
    padding-bottom: 10px !important;
  }
}
@media (min-width: 1200px) {
  .pb8 {
    padding-bottom: 10px !important;
  }
}

.pb12 {
  padding-bottom: 12px !important;
  padding-bottom: 12px !important;
}
@media (min-width: 576px) {
  .pb12 {
    padding-bottom: 12px !important;
  }
}
@media (min-width: 768px) {
  .pb12 {
    padding-bottom: 12px !important;
  }
}
@media (min-width: 992px) {
  .pb12 {
    padding-bottom: 14px !important;
  }
}
@media (min-width: 1200px) {
  .pb12 {
    padding-bottom: 14px !important;
  }
}

.pb16 {
  padding-bottom: 16px !important;
  padding-bottom: 16px !important;
}
@media (min-width: 576px) {
  .pb16 {
    padding-bottom: 16px !important;
  }
}
@media (min-width: 768px) {
  .pb16 {
    padding-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  .pb16 {
    padding-bottom: 19px !important;
  }
}
@media (min-width: 1200px) {
  .pb16 {
    padding-bottom: 19px !important;
  }
}

.pb20 {
  padding-bottom: 20px !important;
  padding-bottom: 20px !important;
}
@media (min-width: 576px) {
  .pb20 {
    padding-bottom: 20px !important;
  }
}
@media (min-width: 768px) {
  .pb20 {
    padding-bottom: 20px !important;
  }
}
@media (min-width: 992px) {
  .pb20 {
    padding-bottom: 23px !important;
  }
}
@media (min-width: 1200px) {
  .pb20 {
    padding-bottom: 23px !important;
  }
}

.pb24 {
  padding-bottom: 24px !important;
  padding-bottom: 24px !important;
}
@media (min-width: 576px) {
  .pb24 {
    padding-bottom: 24px !important;
  }
}
@media (min-width: 768px) {
  .pb24 {
    padding-bottom: 24px !important;
  }
}
@media (min-width: 992px) {
  .pb24 {
    padding-bottom: 28px !important;
  }
}
@media (min-width: 1200px) {
  .pb24 {
    padding-bottom: 28px !important;
  }
}

.pb28 {
  padding-bottom: 28px !important;
  padding-bottom: 28px !important;
}
@media (min-width: 576px) {
  .pb28 {
    padding-bottom: 28px !important;
  }
}
@media (min-width: 768px) {
  .pb28 {
    padding-bottom: 28px !important;
  }
}
@media (min-width: 992px) {
  .pb28 {
    padding-bottom: 32px !important;
  }
}
@media (min-width: 1200px) {
  .pb28 {
    padding-bottom: 32px !important;
  }
}

.pb32 {
  padding-bottom: 32px !important;
  padding-bottom: 32px !important;
}
@media (min-width: 576px) {
  .pb32 {
    padding-bottom: 32px !important;
  }
}
@media (min-width: 768px) {
  .pb32 {
    padding-bottom: 32px !important;
  }
}
@media (min-width: 992px) {
  .pb32 {
    padding-bottom: 37px !important;
  }
}
@media (min-width: 1200px) {
  .pb32 {
    padding-bottom: 37px !important;
  }
}

.pb36 {
  padding-bottom: 36px !important;
  padding-bottom: 36px !important;
}
@media (min-width: 576px) {
  .pb36 {
    padding-bottom: 36px !important;
  }
}
@media (min-width: 768px) {
  .pb36 {
    padding-bottom: 36px !important;
  }
}
@media (min-width: 992px) {
  .pb36 {
    padding-bottom: 42px !important;
  }
}
@media (min-width: 1200px) {
  .pb36 {
    padding-bottom: 42px !important;
  }
}

.pb40 {
  padding-bottom: 40px !important;
  padding-bottom: 40px !important;
}
@media (min-width: 576px) {
  .pb40 {
    padding-bottom: 40px !important;
  }
}
@media (min-width: 768px) {
  .pb40 {
    padding-bottom: 40px !important;
  }
}
@media (min-width: 992px) {
  .pb40 {
    padding-bottom: 46px !important;
  }
}
@media (min-width: 1200px) {
  .pb40 {
    padding-bottom: 46px !important;
  }
}

.pb44 {
  padding-bottom: 44px !important;
  padding-bottom: 44px !important;
}
@media (min-width: 576px) {
  .pb44 {
    padding-bottom: 44px !important;
  }
}
@media (min-width: 768px) {
  .pb44 {
    padding-bottom: 44px !important;
  }
}
@media (min-width: 992px) {
  .pb44 {
    padding-bottom: 51px !important;
  }
}
@media (min-width: 1200px) {
  .pb44 {
    padding-bottom: 51px !important;
  }
}

.pb48 {
  padding-bottom: 48px !important;
  padding-bottom: 48px !important;
}
@media (min-width: 576px) {
  .pb48 {
    padding-bottom: 48px !important;
  }
}
@media (min-width: 768px) {
  .pb48 {
    padding-bottom: 48px !important;
  }
}
@media (min-width: 992px) {
  .pb48 {
    padding-bottom: 55px !important;
  }
}
@media (min-width: 1200px) {
  .pb48 {
    padding-bottom: 55px !important;
  }
}

.pb52 {
  padding-bottom: 52px !important;
  padding-bottom: 52px !important;
}
@media (min-width: 576px) {
  .pb52 {
    padding-bottom: 52px !important;
  }
}
@media (min-width: 768px) {
  .pb52 {
    padding-bottom: 52px !important;
  }
}
@media (min-width: 992px) {
  .pb52 {
    padding-bottom: 60px !important;
  }
}
@media (min-width: 1200px) {
  .pb52 {
    padding-bottom: 60px !important;
  }
}

.pb56 {
  padding-bottom: 56px !important;
  padding-bottom: 56px !important;
}
@media (min-width: 576px) {
  .pb56 {
    padding-bottom: 56px !important;
  }
}
@media (min-width: 768px) {
  .pb56 {
    padding-bottom: 56px !important;
  }
}
@media (min-width: 992px) {
  .pb56 {
    padding-bottom: 64px !important;
  }
}
@media (min-width: 1200px) {
  .pb56 {
    padding-bottom: 64px !important;
  }
}

.pb60 {
  padding-bottom: 60px !important;
  padding-bottom: 60px !important;
}
@media (min-width: 576px) {
  .pb60 {
    padding-bottom: 60px !important;
  }
}
@media (min-width: 768px) {
  .pb60 {
    padding-bottom: 60px !important;
  }
}
@media (min-width: 992px) {
  .pb60 {
    padding-bottom: 69px !important;
  }
}
@media (min-width: 1200px) {
  .pb60 {
    padding-bottom: 69px !important;
  }
}

.pb64 {
  padding-bottom: 64px !important;
  padding-bottom: 64px !important;
}
@media (min-width: 576px) {
  .pb64 {
    padding-bottom: 64px !important;
  }
}
@media (min-width: 768px) {
  .pb64 {
    padding-bottom: 64px !important;
  }
}
@media (min-width: 992px) {
  .pb64 {
    padding-bottom: 74px !important;
  }
}
@media (min-width: 1200px) {
  .pb64 {
    padding-bottom: 74px !important;
  }
}

.pb68 {
  padding-bottom: 68px !important;
  padding-bottom: 68px !important;
}
@media (min-width: 576px) {
  .pb68 {
    padding-bottom: 68px !important;
  }
}
@media (min-width: 768px) {
  .pb68 {
    padding-bottom: 68px !important;
  }
}
@media (min-width: 992px) {
  .pb68 {
    padding-bottom: 78px !important;
  }
}
@media (min-width: 1200px) {
  .pb68 {
    padding-bottom: 78px !important;
  }
}

.pb72 {
  padding-bottom: 72px !important;
  padding-bottom: 72px !important;
}
@media (min-width: 576px) {
  .pb72 {
    padding-bottom: 72px !important;
  }
}
@media (min-width: 768px) {
  .pb72 {
    padding-bottom: 72px !important;
  }
}
@media (min-width: 992px) {
  .pb72 {
    padding-bottom: 83px !important;
  }
}
@media (min-width: 1200px) {
  .pb72 {
    padding-bottom: 83px !important;
  }
}

.pb76 {
  padding-bottom: 76px !important;
  padding-bottom: 76px !important;
}
@media (min-width: 576px) {
  .pb76 {
    padding-bottom: 76px !important;
  }
}
@media (min-width: 768px) {
  .pb76 {
    padding-bottom: 76px !important;
  }
}
@media (min-width: 992px) {
  .pb76 {
    padding-bottom: 87px !important;
  }
}
@media (min-width: 1200px) {
  .pb76 {
    padding-bottom: 87px !important;
  }
}

.pb80 {
  padding-bottom: 80px !important;
  padding-bottom: 80px !important;
}
@media (min-width: 576px) {
  .pb80 {
    padding-bottom: 80px !important;
  }
}
@media (min-width: 768px) {
  .pb80 {
    padding-bottom: 80px !important;
  }
}
@media (min-width: 992px) {
  .pb80 {
    padding-bottom: 92px !important;
  }
}
@media (min-width: 1200px) {
  .pb80 {
    padding-bottom: 92px !important;
  }
}

.pb84 {
  padding-bottom: 84px !important;
  padding-bottom: 84px !important;
}
@media (min-width: 576px) {
  .pb84 {
    padding-bottom: 84px !important;
  }
}
@media (min-width: 768px) {
  .pb84 {
    padding-bottom: 84px !important;
  }
}
@media (min-width: 992px) {
  .pb84 {
    padding-bottom: 96px !important;
  }
}
@media (min-width: 1200px) {
  .pb84 {
    padding-bottom: 96px !important;
  }
}

.pb88 {
  padding-bottom: 88px !important;
  padding-bottom: 88px !important;
}
@media (min-width: 576px) {
  .pb88 {
    padding-bottom: 88px !important;
  }
}
@media (min-width: 768px) {
  .pb88 {
    padding-bottom: 88px !important;
  }
}
@media (min-width: 992px) {
  .pb88 {
    padding-bottom: 101px !important;
  }
}
@media (min-width: 1200px) {
  .pb88 {
    padding-bottom: 101px !important;
  }
}

.pb92 {
  padding-bottom: 92px !important;
  padding-bottom: 92px !important;
}
@media (min-width: 576px) {
  .pb92 {
    padding-bottom: 92px !important;
  }
}
@media (min-width: 768px) {
  .pb92 {
    padding-bottom: 92px !important;
  }
}
@media (min-width: 992px) {
  .pb92 {
    padding-bottom: 106px !important;
  }
}
@media (min-width: 1200px) {
  .pb92 {
    padding-bottom: 106px !important;
  }
}

.pb96 {
  padding-bottom: 96px !important;
  padding-bottom: 96px !important;
}
@media (min-width: 576px) {
  .pb96 {
    padding-bottom: 96px !important;
  }
}
@media (min-width: 768px) {
  .pb96 {
    padding-bottom: 96px !important;
  }
}
@media (min-width: 992px) {
  .pb96 {
    padding-bottom: 110px !important;
  }
}
@media (min-width: 1200px) {
  .pb96 {
    padding-bottom: 110px !important;
  }
}

.pb100 {
  padding-bottom: 100px !important;
  padding-bottom: 100px !important;
}
@media (min-width: 576px) {
  .pb100 {
    padding-bottom: 100px !important;
  }
}
@media (min-width: 768px) {
  .pb100 {
    padding-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .pb100 {
    padding-bottom: 115px !important;
  }
}
@media (min-width: 1200px) {
  .pb100 {
    padding-bottom: 115px !important;
  }
}

.pr4 {
  padding-right: 4px !important;
  padding-right: 4px !important;
}
@media (min-width: 576px) {
  .pr4 {
    padding-right: 4px !important;
  }
}
@media (min-width: 768px) {
  .pr4 {
    padding-right: 4px !important;
  }
}
@media (min-width: 992px) {
  .pr4 {
    padding-right: 5px !important;
  }
}
@media (min-width: 1200px) {
  .pr4 {
    padding-right: 5px !important;
  }
}

.pr8 {
  padding-right: 8px !important;
  padding-right: 8px !important;
}
@media (min-width: 576px) {
  .pr8 {
    padding-right: 8px !important;
  }
}
@media (min-width: 768px) {
  .pr8 {
    padding-right: 8px !important;
  }
}
@media (min-width: 992px) {
  .pr8 {
    padding-right: 10px !important;
  }
}
@media (min-width: 1200px) {
  .pr8 {
    padding-right: 10px !important;
  }
}

.pr12 {
  padding-right: 12px !important;
  padding-right: 12px !important;
}
@media (min-width: 576px) {
  .pr12 {
    padding-right: 12px !important;
  }
}
@media (min-width: 768px) {
  .pr12 {
    padding-right: 12px !important;
  }
}
@media (min-width: 992px) {
  .pr12 {
    padding-right: 14px !important;
  }
}
@media (min-width: 1200px) {
  .pr12 {
    padding-right: 14px !important;
  }
}

.pr16 {
  padding-right: 16px !important;
  padding-right: 16px !important;
}
@media (min-width: 576px) {
  .pr16 {
    padding-right: 16px !important;
  }
}
@media (min-width: 768px) {
  .pr16 {
    padding-right: 16px !important;
  }
}
@media (min-width: 992px) {
  .pr16 {
    padding-right: 19px !important;
  }
}
@media (min-width: 1200px) {
  .pr16 {
    padding-right: 19px !important;
  }
}

.pr20 {
  padding-right: 20px !important;
  padding-right: 20px !important;
}
@media (min-width: 576px) {
  .pr20 {
    padding-right: 20px !important;
  }
}
@media (min-width: 768px) {
  .pr20 {
    padding-right: 20px !important;
  }
}
@media (min-width: 992px) {
  .pr20 {
    padding-right: 23px !important;
  }
}
@media (min-width: 1200px) {
  .pr20 {
    padding-right: 23px !important;
  }
}

.pr24 {
  padding-right: 24px !important;
  padding-right: 24px !important;
}
@media (min-width: 576px) {
  .pr24 {
    padding-right: 24px !important;
  }
}
@media (min-width: 768px) {
  .pr24 {
    padding-right: 24px !important;
  }
}
@media (min-width: 992px) {
  .pr24 {
    padding-right: 28px !important;
  }
}
@media (min-width: 1200px) {
  .pr24 {
    padding-right: 28px !important;
  }
}

.pr28 {
  padding-right: 28px !important;
  padding-right: 28px !important;
}
@media (min-width: 576px) {
  .pr28 {
    padding-right: 28px !important;
  }
}
@media (min-width: 768px) {
  .pr28 {
    padding-right: 28px !important;
  }
}
@media (min-width: 992px) {
  .pr28 {
    padding-right: 32px !important;
  }
}
@media (min-width: 1200px) {
  .pr28 {
    padding-right: 32px !important;
  }
}

.pr32 {
  padding-right: 32px !important;
  padding-right: 32px !important;
}
@media (min-width: 576px) {
  .pr32 {
    padding-right: 32px !important;
  }
}
@media (min-width: 768px) {
  .pr32 {
    padding-right: 32px !important;
  }
}
@media (min-width: 992px) {
  .pr32 {
    padding-right: 37px !important;
  }
}
@media (min-width: 1200px) {
  .pr32 {
    padding-right: 37px !important;
  }
}

.pr36 {
  padding-right: 36px !important;
  padding-right: 36px !important;
}
@media (min-width: 576px) {
  .pr36 {
    padding-right: 36px !important;
  }
}
@media (min-width: 768px) {
  .pr36 {
    padding-right: 36px !important;
  }
}
@media (min-width: 992px) {
  .pr36 {
    padding-right: 42px !important;
  }
}
@media (min-width: 1200px) {
  .pr36 {
    padding-right: 42px !important;
  }
}

.pr40 {
  padding-right: 40px !important;
  padding-right: 40px !important;
}
@media (min-width: 576px) {
  .pr40 {
    padding-right: 40px !important;
  }
}
@media (min-width: 768px) {
  .pr40 {
    padding-right: 40px !important;
  }
}
@media (min-width: 992px) {
  .pr40 {
    padding-right: 46px !important;
  }
}
@media (min-width: 1200px) {
  .pr40 {
    padding-right: 46px !important;
  }
}

.pr44 {
  padding-right: 44px !important;
  padding-right: 44px !important;
}
@media (min-width: 576px) {
  .pr44 {
    padding-right: 44px !important;
  }
}
@media (min-width: 768px) {
  .pr44 {
    padding-right: 44px !important;
  }
}
@media (min-width: 992px) {
  .pr44 {
    padding-right: 51px !important;
  }
}
@media (min-width: 1200px) {
  .pr44 {
    padding-right: 51px !important;
  }
}

.pr48 {
  padding-right: 48px !important;
  padding-right: 48px !important;
}
@media (min-width: 576px) {
  .pr48 {
    padding-right: 48px !important;
  }
}
@media (min-width: 768px) {
  .pr48 {
    padding-right: 48px !important;
  }
}
@media (min-width: 992px) {
  .pr48 {
    padding-right: 55px !important;
  }
}
@media (min-width: 1200px) {
  .pr48 {
    padding-right: 55px !important;
  }
}

.pr52 {
  padding-right: 52px !important;
  padding-right: 52px !important;
}
@media (min-width: 576px) {
  .pr52 {
    padding-right: 52px !important;
  }
}
@media (min-width: 768px) {
  .pr52 {
    padding-right: 52px !important;
  }
}
@media (min-width: 992px) {
  .pr52 {
    padding-right: 60px !important;
  }
}
@media (min-width: 1200px) {
  .pr52 {
    padding-right: 60px !important;
  }
}

.pr56 {
  padding-right: 56px !important;
  padding-right: 56px !important;
}
@media (min-width: 576px) {
  .pr56 {
    padding-right: 56px !important;
  }
}
@media (min-width: 768px) {
  .pr56 {
    padding-right: 56px !important;
  }
}
@media (min-width: 992px) {
  .pr56 {
    padding-right: 64px !important;
  }
}
@media (min-width: 1200px) {
  .pr56 {
    padding-right: 64px !important;
  }
}

.pr60 {
  padding-right: 60px !important;
  padding-right: 60px !important;
}
@media (min-width: 576px) {
  .pr60 {
    padding-right: 60px !important;
  }
}
@media (min-width: 768px) {
  .pr60 {
    padding-right: 60px !important;
  }
}
@media (min-width: 992px) {
  .pr60 {
    padding-right: 69px !important;
  }
}
@media (min-width: 1200px) {
  .pr60 {
    padding-right: 69px !important;
  }
}

.pr64 {
  padding-right: 64px !important;
  padding-right: 64px !important;
}
@media (min-width: 576px) {
  .pr64 {
    padding-right: 64px !important;
  }
}
@media (min-width: 768px) {
  .pr64 {
    padding-right: 64px !important;
  }
}
@media (min-width: 992px) {
  .pr64 {
    padding-right: 74px !important;
  }
}
@media (min-width: 1200px) {
  .pr64 {
    padding-right: 74px !important;
  }
}

.pr68 {
  padding-right: 68px !important;
  padding-right: 68px !important;
}
@media (min-width: 576px) {
  .pr68 {
    padding-right: 68px !important;
  }
}
@media (min-width: 768px) {
  .pr68 {
    padding-right: 68px !important;
  }
}
@media (min-width: 992px) {
  .pr68 {
    padding-right: 78px !important;
  }
}
@media (min-width: 1200px) {
  .pr68 {
    padding-right: 78px !important;
  }
}

.pr72 {
  padding-right: 72px !important;
  padding-right: 72px !important;
}
@media (min-width: 576px) {
  .pr72 {
    padding-right: 72px !important;
  }
}
@media (min-width: 768px) {
  .pr72 {
    padding-right: 72px !important;
  }
}
@media (min-width: 992px) {
  .pr72 {
    padding-right: 83px !important;
  }
}
@media (min-width: 1200px) {
  .pr72 {
    padding-right: 83px !important;
  }
}

.pr76 {
  padding-right: 76px !important;
  padding-right: 76px !important;
}
@media (min-width: 576px) {
  .pr76 {
    padding-right: 76px !important;
  }
}
@media (min-width: 768px) {
  .pr76 {
    padding-right: 76px !important;
  }
}
@media (min-width: 992px) {
  .pr76 {
    padding-right: 87px !important;
  }
}
@media (min-width: 1200px) {
  .pr76 {
    padding-right: 87px !important;
  }
}

.pr80 {
  padding-right: 80px !important;
  padding-right: 80px !important;
}
@media (min-width: 576px) {
  .pr80 {
    padding-right: 80px !important;
  }
}
@media (min-width: 768px) {
  .pr80 {
    padding-right: 80px !important;
  }
}
@media (min-width: 992px) {
  .pr80 {
    padding-right: 92px !important;
  }
}
@media (min-width: 1200px) {
  .pr80 {
    padding-right: 92px !important;
  }
}

.pr84 {
  padding-right: 84px !important;
  padding-right: 84px !important;
}
@media (min-width: 576px) {
  .pr84 {
    padding-right: 84px !important;
  }
}
@media (min-width: 768px) {
  .pr84 {
    padding-right: 84px !important;
  }
}
@media (min-width: 992px) {
  .pr84 {
    padding-right: 96px !important;
  }
}
@media (min-width: 1200px) {
  .pr84 {
    padding-right: 96px !important;
  }
}

.pr88 {
  padding-right: 88px !important;
  padding-right: 88px !important;
}
@media (min-width: 576px) {
  .pr88 {
    padding-right: 88px !important;
  }
}
@media (min-width: 768px) {
  .pr88 {
    padding-right: 88px !important;
  }
}
@media (min-width: 992px) {
  .pr88 {
    padding-right: 101px !important;
  }
}
@media (min-width: 1200px) {
  .pr88 {
    padding-right: 101px !important;
  }
}

.pr92 {
  padding-right: 92px !important;
  padding-right: 92px !important;
}
@media (min-width: 576px) {
  .pr92 {
    padding-right: 92px !important;
  }
}
@media (min-width: 768px) {
  .pr92 {
    padding-right: 92px !important;
  }
}
@media (min-width: 992px) {
  .pr92 {
    padding-right: 106px !important;
  }
}
@media (min-width: 1200px) {
  .pr92 {
    padding-right: 106px !important;
  }
}

.pr96 {
  padding-right: 96px !important;
  padding-right: 96px !important;
}
@media (min-width: 576px) {
  .pr96 {
    padding-right: 96px !important;
  }
}
@media (min-width: 768px) {
  .pr96 {
    padding-right: 96px !important;
  }
}
@media (min-width: 992px) {
  .pr96 {
    padding-right: 110px !important;
  }
}
@media (min-width: 1200px) {
  .pr96 {
    padding-right: 110px !important;
  }
}

.pr100 {
  padding-right: 100px !important;
  padding-right: 100px !important;
}
@media (min-width: 576px) {
  .pr100 {
    padding-right: 100px !important;
  }
}
@media (min-width: 768px) {
  .pr100 {
    padding-right: 100px !important;
  }
}
@media (min-width: 992px) {
  .pr100 {
    padding-right: 115px !important;
  }
}
@media (min-width: 1200px) {
  .pr100 {
    padding-right: 115px !important;
  }
}

.pl4 {
  padding-left: 4px !important;
  padding-left: 4px !important;
}
@media (min-width: 576px) {
  .pl4 {
    padding-left: 4px !important;
  }
}
@media (min-width: 768px) {
  .pl4 {
    padding-left: 4px !important;
  }
}
@media (min-width: 992px) {
  .pl4 {
    padding-left: 5px !important;
  }
}
@media (min-width: 1200px) {
  .pl4 {
    padding-left: 5px !important;
  }
}

.pl8 {
  padding-left: 8px !important;
  padding-left: 8px !important;
}
@media (min-width: 576px) {
  .pl8 {
    padding-left: 8px !important;
  }
}
@media (min-width: 768px) {
  .pl8 {
    padding-left: 8px !important;
  }
}
@media (min-width: 992px) {
  .pl8 {
    padding-left: 10px !important;
  }
}
@media (min-width: 1200px) {
  .pl8 {
    padding-left: 10px !important;
  }
}

.pl12 {
  padding-left: 12px !important;
  padding-left: 12px !important;
}
@media (min-width: 576px) {
  .pl12 {
    padding-left: 12px !important;
  }
}
@media (min-width: 768px) {
  .pl12 {
    padding-left: 12px !important;
  }
}
@media (min-width: 992px) {
  .pl12 {
    padding-left: 14px !important;
  }
}
@media (min-width: 1200px) {
  .pl12 {
    padding-left: 14px !important;
  }
}

.pl16 {
  padding-left: 16px !important;
  padding-left: 16px !important;
}
@media (min-width: 576px) {
  .pl16 {
    padding-left: 16px !important;
  }
}
@media (min-width: 768px) {
  .pl16 {
    padding-left: 16px !important;
  }
}
@media (min-width: 992px) {
  .pl16 {
    padding-left: 19px !important;
  }
}
@media (min-width: 1200px) {
  .pl16 {
    padding-left: 19px !important;
  }
}

.pl20 {
  padding-left: 20px !important;
  padding-left: 20px !important;
}
@media (min-width: 576px) {
  .pl20 {
    padding-left: 20px !important;
  }
}
@media (min-width: 768px) {
  .pl20 {
    padding-left: 20px !important;
  }
}
@media (min-width: 992px) {
  .pl20 {
    padding-left: 23px !important;
  }
}
@media (min-width: 1200px) {
  .pl20 {
    padding-left: 23px !important;
  }
}

.pl24 {
  padding-left: 24px !important;
  padding-left: 24px !important;
}
@media (min-width: 576px) {
  .pl24 {
    padding-left: 24px !important;
  }
}
@media (min-width: 768px) {
  .pl24 {
    padding-left: 24px !important;
  }
}
@media (min-width: 992px) {
  .pl24 {
    padding-left: 28px !important;
  }
}
@media (min-width: 1200px) {
  .pl24 {
    padding-left: 28px !important;
  }
}

.pl28 {
  padding-left: 28px !important;
  padding-left: 28px !important;
}
@media (min-width: 576px) {
  .pl28 {
    padding-left: 28px !important;
  }
}
@media (min-width: 768px) {
  .pl28 {
    padding-left: 28px !important;
  }
}
@media (min-width: 992px) {
  .pl28 {
    padding-left: 32px !important;
  }
}
@media (min-width: 1200px) {
  .pl28 {
    padding-left: 32px !important;
  }
}

.pl32 {
  padding-left: 32px !important;
  padding-left: 32px !important;
}
@media (min-width: 576px) {
  .pl32 {
    padding-left: 32px !important;
  }
}
@media (min-width: 768px) {
  .pl32 {
    padding-left: 32px !important;
  }
}
@media (min-width: 992px) {
  .pl32 {
    padding-left: 37px !important;
  }
}
@media (min-width: 1200px) {
  .pl32 {
    padding-left: 37px !important;
  }
}

.pl36 {
  padding-left: 36px !important;
  padding-left: 36px !important;
}
@media (min-width: 576px) {
  .pl36 {
    padding-left: 36px !important;
  }
}
@media (min-width: 768px) {
  .pl36 {
    padding-left: 36px !important;
  }
}
@media (min-width: 992px) {
  .pl36 {
    padding-left: 42px !important;
  }
}
@media (min-width: 1200px) {
  .pl36 {
    padding-left: 42px !important;
  }
}

.pl40 {
  padding-left: 40px !important;
  padding-left: 40px !important;
}
@media (min-width: 576px) {
  .pl40 {
    padding-left: 40px !important;
  }
}
@media (min-width: 768px) {
  .pl40 {
    padding-left: 40px !important;
  }
}
@media (min-width: 992px) {
  .pl40 {
    padding-left: 46px !important;
  }
}
@media (min-width: 1200px) {
  .pl40 {
    padding-left: 46px !important;
  }
}

.pl44 {
  padding-left: 44px !important;
  padding-left: 44px !important;
}
@media (min-width: 576px) {
  .pl44 {
    padding-left: 44px !important;
  }
}
@media (min-width: 768px) {
  .pl44 {
    padding-left: 44px !important;
  }
}
@media (min-width: 992px) {
  .pl44 {
    padding-left: 51px !important;
  }
}
@media (min-width: 1200px) {
  .pl44 {
    padding-left: 51px !important;
  }
}

.pl48 {
  padding-left: 48px !important;
  padding-left: 48px !important;
}
@media (min-width: 576px) {
  .pl48 {
    padding-left: 48px !important;
  }
}
@media (min-width: 768px) {
  .pl48 {
    padding-left: 48px !important;
  }
}
@media (min-width: 992px) {
  .pl48 {
    padding-left: 55px !important;
  }
}
@media (min-width: 1200px) {
  .pl48 {
    padding-left: 55px !important;
  }
}

.pl52 {
  padding-left: 52px !important;
  padding-left: 52px !important;
}
@media (min-width: 576px) {
  .pl52 {
    padding-left: 52px !important;
  }
}
@media (min-width: 768px) {
  .pl52 {
    padding-left: 52px !important;
  }
}
@media (min-width: 992px) {
  .pl52 {
    padding-left: 60px !important;
  }
}
@media (min-width: 1200px) {
  .pl52 {
    padding-left: 60px !important;
  }
}

.pl56 {
  padding-left: 56px !important;
  padding-left: 56px !important;
}
@media (min-width: 576px) {
  .pl56 {
    padding-left: 56px !important;
  }
}
@media (min-width: 768px) {
  .pl56 {
    padding-left: 56px !important;
  }
}
@media (min-width: 992px) {
  .pl56 {
    padding-left: 64px !important;
  }
}
@media (min-width: 1200px) {
  .pl56 {
    padding-left: 64px !important;
  }
}

.pl60 {
  padding-left: 60px !important;
  padding-left: 60px !important;
}
@media (min-width: 576px) {
  .pl60 {
    padding-left: 60px !important;
  }
}
@media (min-width: 768px) {
  .pl60 {
    padding-left: 60px !important;
  }
}
@media (min-width: 992px) {
  .pl60 {
    padding-left: 69px !important;
  }
}
@media (min-width: 1200px) {
  .pl60 {
    padding-left: 69px !important;
  }
}

.pl64 {
  padding-left: 64px !important;
  padding-left: 64px !important;
}
@media (min-width: 576px) {
  .pl64 {
    padding-left: 64px !important;
  }
}
@media (min-width: 768px) {
  .pl64 {
    padding-left: 64px !important;
  }
}
@media (min-width: 992px) {
  .pl64 {
    padding-left: 74px !important;
  }
}
@media (min-width: 1200px) {
  .pl64 {
    padding-left: 74px !important;
  }
}

.pl68 {
  padding-left: 68px !important;
  padding-left: 68px !important;
}
@media (min-width: 576px) {
  .pl68 {
    padding-left: 68px !important;
  }
}
@media (min-width: 768px) {
  .pl68 {
    padding-left: 68px !important;
  }
}
@media (min-width: 992px) {
  .pl68 {
    padding-left: 78px !important;
  }
}
@media (min-width: 1200px) {
  .pl68 {
    padding-left: 78px !important;
  }
}

.pl72 {
  padding-left: 72px !important;
  padding-left: 72px !important;
}
@media (min-width: 576px) {
  .pl72 {
    padding-left: 72px !important;
  }
}
@media (min-width: 768px) {
  .pl72 {
    padding-left: 72px !important;
  }
}
@media (min-width: 992px) {
  .pl72 {
    padding-left: 83px !important;
  }
}
@media (min-width: 1200px) {
  .pl72 {
    padding-left: 83px !important;
  }
}

.pl76 {
  padding-left: 76px !important;
  padding-left: 76px !important;
}
@media (min-width: 576px) {
  .pl76 {
    padding-left: 76px !important;
  }
}
@media (min-width: 768px) {
  .pl76 {
    padding-left: 76px !important;
  }
}
@media (min-width: 992px) {
  .pl76 {
    padding-left: 87px !important;
  }
}
@media (min-width: 1200px) {
  .pl76 {
    padding-left: 87px !important;
  }
}

.pl80 {
  padding-left: 80px !important;
  padding-left: 80px !important;
}
@media (min-width: 576px) {
  .pl80 {
    padding-left: 80px !important;
  }
}
@media (min-width: 768px) {
  .pl80 {
    padding-left: 80px !important;
  }
}
@media (min-width: 992px) {
  .pl80 {
    padding-left: 92px !important;
  }
}
@media (min-width: 1200px) {
  .pl80 {
    padding-left: 92px !important;
  }
}

.pl84 {
  padding-left: 84px !important;
  padding-left: 84px !important;
}
@media (min-width: 576px) {
  .pl84 {
    padding-left: 84px !important;
  }
}
@media (min-width: 768px) {
  .pl84 {
    padding-left: 84px !important;
  }
}
@media (min-width: 992px) {
  .pl84 {
    padding-left: 96px !important;
  }
}
@media (min-width: 1200px) {
  .pl84 {
    padding-left: 96px !important;
  }
}

.pl88 {
  padding-left: 88px !important;
  padding-left: 88px !important;
}
@media (min-width: 576px) {
  .pl88 {
    padding-left: 88px !important;
  }
}
@media (min-width: 768px) {
  .pl88 {
    padding-left: 88px !important;
  }
}
@media (min-width: 992px) {
  .pl88 {
    padding-left: 101px !important;
  }
}
@media (min-width: 1200px) {
  .pl88 {
    padding-left: 101px !important;
  }
}

.pl92 {
  padding-left: 92px !important;
  padding-left: 92px !important;
}
@media (min-width: 576px) {
  .pl92 {
    padding-left: 92px !important;
  }
}
@media (min-width: 768px) {
  .pl92 {
    padding-left: 92px !important;
  }
}
@media (min-width: 992px) {
  .pl92 {
    padding-left: 106px !important;
  }
}
@media (min-width: 1200px) {
  .pl92 {
    padding-left: 106px !important;
  }
}

.pl96 {
  padding-left: 96px !important;
  padding-left: 96px !important;
}
@media (min-width: 576px) {
  .pl96 {
    padding-left: 96px !important;
  }
}
@media (min-width: 768px) {
  .pl96 {
    padding-left: 96px !important;
  }
}
@media (min-width: 992px) {
  .pl96 {
    padding-left: 110px !important;
  }
}
@media (min-width: 1200px) {
  .pl96 {
    padding-left: 110px !important;
  }
}

.pl100 {
  padding-left: 100px !important;
  padding-left: 100px !important;
}
@media (min-width: 576px) {
  .pl100 {
    padding-left: 100px !important;
  }
}
@media (min-width: 768px) {
  .pl100 {
    padding-left: 100px !important;
  }
}
@media (min-width: 992px) {
  .pl100 {
    padding-left: 115px !important;
  }
}
@media (min-width: 1200px) {
  .pl100 {
    padding-left: 115px !important;
  }
}

.fc-base {
  color: #007e39 !important;
}

.fc-accent {
  color: #2e2f33 !important;
}

.fc-uno {
  color: #6f42c1 !important;
}

.fc-dos {
  color: #d63384 !important;
}

.fc-tres {
  color: #fd7e14 !important;
}

.fc-cuatro {
  color: #2da8a8 !important;
}

.fc-cinco {
  color: #5f6987 !important;
}

.fc-first {
  color: #6f42c1 !important;
}

.fc-second {
  color: #d63384 !important;
}

.fc-third {
  color: #fd7e14 !important;
}

.fc-fourth {
  color: #2da8a8 !important;
}

.fc-five {
  color: #5f6987 !important;
}

.fc-base-light {
  color: #b3d8c4 !important;
}

.fc-accent-light {
  color: #c0c1c2 !important;
}

.fc-uno-light {
  color: #d4c6ec !important;
}

.fc-dos-light {
  color: #f3c2da !important;
}

.fc-tres-light {
  color: #fed8b9 !important;
}

.fc-cuatro-light {
  color: #c0e5e5 !important;
}

.fc-cinco-light {
  color: #cfd2db !important;
}

.fc-first-light {
  color: #d4c6ec !important;
}

.fc-second-light {
  color: #f3c2da !important;
}

.fc-third-light {
  color: #fed8b9 !important;
}

.fc-fourth-light {
  color: #c0e5e5 !important;
}

.fc-five-light {
  color: #cfd2db !important;
}

.fc-base-dark {
  color: #004c22 !important;
}

.fc-accent-dark {
  color: #1c1c1f !important;
}

.fc-uno-dark {
  color: #432874 !important;
}

.fc-dos-dark {
  color: #801f4f !important;
}

.fc-tres-dark {
  color: #984c0c !important;
}

.fc-cuatro-dark {
  color: #1b6565 !important;
}

.fc-cinco-dark {
  color: #393f51 !important;
}

.fc-first-dark {
  color: #432874 !important;
}

.fc-second-dark {
  color: #801f4f !important;
}

.fc-third-dark {
  color: #984c0c !important;
}

.fc-fourth-dark {
  color: #1b6565 !important;
}

.fc-five-dark {
  color: #393f51 !important;
}

.fc-gray-100 {
  color: #f8f9fa !important;
}

.fc-gray-200 {
  color: #e9ecef !important;
}

.fc-gray-300 {
  color: #dee2e6 !important;
}

.fc-gray-400 {
  color: #ced4da !important;
}

.fc-gray-500 {
  color: #adb5bd !important;
}

.fc-gray-600 {
  color: #6c757d !important;
}

.fc-gray-700 {
  color: #495057 !important;
}

.fc-gray-800 {
  color: #343a40 !important;
}

.fc-gray-900 {
  color: #212529 !important;
}

.fc-gray-dark {
  color: #333 !important;
}

.fc-black {
  color: #000 !important;
}

.fc-white {
  color: #fff !important;
}

.fc-red {
  color: #b0120a !important;
}

.fc-yellow {
  color: #fff000 !important;
}

.fc-orange {
  color: #f18f2c !important;
}

.fc-green {
  color: #63a764 !important;
}

.fc-blue {
  color: #005fcc !important;
}

.fc-pink {
  color: #ff0066 !important;
}

.fc-purple {
  color: #6f42c1 !important;
}

.fc-cyan {
  color: #17a2b8 !important;
}

.fs-xsmall {
  font-size: 62.5% !important;
}

.fs-small {
  font-size: 75% !important;
}

.fs-medium {
  font-size: 87.5% !important;
}

.fs-large {
  font-size: 112.5% !important;
}

.fs-xlarge {
  font-size: 125% !important;
}

.fs-xxlarge {
  font-size: 150% !important;
}

.fs-exlarge {
  font-size: 200% !important;
}

.lh-10 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-20 {
  line-height: 2 !important;
}

.bg-base {
  background-color: #007e39 !important;
}

.bg-accent {
  background-color: #2e2f33 !important;
}

.bg-uno {
  background-color: #6f42c1 !important;
}

.bg-dos {
  background-color: #d63384 !important;
}

.bg-tres {
  background-color: #fd7e14 !important;
}

.bg-cuatro {
  background-color: #2da8a8 !important;
}

.bg-cinco {
  background-color: #5f6987 !important;
}

.bg-first {
  background-color: #6f42c1 !important;
}

.bg-second {
  background-color: #d63384 !important;
}

.bg-third {
  background-color: #fd7e14 !important;
}

.bg-fourth {
  background-color: #2da8a8 !important;
}

.bg-five {
  background-color: #5f6987 !important;
}

.bg-base-light {
  background-color: #b3d8c4 !important;
}

.bg-accent-light {
  background-color: #c0c1c2 !important;
}

.bg-uno-light {
  background-color: #d4c6ec !important;
}

.bg-dos-light {
  background-color: #f3c2da !important;
}

.bg-tres-light {
  background-color: #fed8b9 !important;
}

.bg-cuatro-light {
  background-color: #c0e5e5 !important;
}

.bg-cinco-light {
  background-color: #cfd2db !important;
}

.bg-first-light {
  background-color: #d4c6ec !important;
}

.bg-second-light {
  background-color: #f3c2da !important;
}

.bg-third-light {
  background-color: #fed8b9 !important;
}

.bg-fourth-light {
  background-color: #c0e5e5 !important;
}

.bg-five-light {
  background-color: #cfd2db !important;
}

.bg-base-dark {
  background-color: #004c22 !important;
}

.bg-accent-dark {
  background-color: #1c1c1f !important;
}

.bg-uno-dark {
  background-color: #432874 !important;
}

.bg-dos-dark {
  background-color: #801f4f !important;
}

.bg-tres-dark {
  background-color: #984c0c !important;
}

.bg-cuatro-dark {
  background-color: #1b6565 !important;
}

.bg-cinco-dark {
  background-color: #393f51 !important;
}

.bg-first-dark {
  background-color: #432874 !important;
}

.bg-second-dark {
  background-color: #801f4f !important;
}

.bg-third-dark {
  background-color: #984c0c !important;
}

.bg-fourth-dark {
  background-color: #1b6565 !important;
}

.bg-five-dark {
  background-color: #393f51 !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

.bg-gray-200 {
  background-color: #e9ecef !important;
}

.bg-gray-300 {
  background-color: #dee2e6 !important;
}

.bg-gray-400 {
  background-color: #ced4da !important;
}

.bg-gray-500 {
  background-color: #adb5bd !important;
}

.bg-gray-600 {
  background-color: #6c757d !important;
}

.bg-gray-700 {
  background-color: #495057 !important;
}

.bg-gray-800 {
  background-color: #343a40 !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

.bg-gray-dark {
  background-color: #333 !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-red {
  background-color: #b0120a !important;
}

.bg-yellow {
  background-color: #fff000 !important;
}

.bg-orange {
  background-color: #f18f2c !important;
}

.bg-green {
  background-color: #63a764 !important;
}

.bg-blue {
  background-color: #005fcc !important;
}

.bg-pink {
  background-color: #ff0066 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

.w5 {
  width: 5% !important;
}

.w10 {
  width: 10% !important;
}

.w15 {
  width: 15% !important;
}

.w20 {
  width: 20% !important;
}

.w25 {
  width: 25% !important;
}

.w30 {
  width: 30% !important;
}

.w35 {
  width: 35% !important;
}

.w40 {
  width: 40% !important;
}

.w45 {
  width: 45% !important;
}

.w50 {
  width: 50% !important;
}

.w55 {
  width: 55% !important;
}

.w60 {
  width: 60% !important;
}

.w65 {
  width: 65% !important;
}

.w70 {
  width: 70% !important;
}

.w75 {
  width: 75% !important;
}

.w80 {
  width: 80% !important;
}

.w85 {
  width: 85% !important;
}

.w90 {
  width: 90% !important;
}

.w95 {
  width: 95% !important;
}

.w100 {
  width: 100% !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align-last: justify;
  text-justify: inter-ideograph;
}

.spacer {
  margin-top: 1rem;
}

.spacer-large {
  margin-top: 2rem !important;
}

.spacer-xlarge {
  margin-top: 3rem !important;
}

.spacer-exlarge {
  margin-top: 4rem !important;
}

.spacer-small {
  margin-top: 0.5rem !important;
}

.spacer-top-bottom {
  margin: 1rem 0 !important;
}

.spacer-top-bottom-large {
  margin: 2rem 0 !important;
}

.spacer-top-bottom-xlarge {
  margin: 3rem 0 !important;
}

.spacer-top-bottom-exlarge {
  margin: 4rem 0 !important;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ul.news-list {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul.news-list.top li:nth-of-type(n+11) {
  display: none;
}
ul.news-list li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  position: relative;
}
ul.news-list li:not(:first-child) {
  border-top: 1px solid var(--gray-300-color);
}
ul.news-list li a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color var(--base-transition);
}
@media (hover: hover) and (pointer: fine) {
  ul.news-list li a:hover {
    background-color: #adb5bd1a;
  }
}
ul.news-list li:has(a)::after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M5.7,14.1l0-2l8.2,0l-3.7-3.7L11.7,7l7.1,7.1L5.7,14.1z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  flex: 0 0 auto;
  align-self: center;
}

.news-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}
@media (min-width: 576px) {
  .news-container {
    flex-direction: row;
  }
}

.news-date {
  color: var(--gray-600-color);
  font-weight: 500;
  flex: 0 0 auto;
}
@media (min-width: 576px) {
  .news-date {
    flex: 0 0 6rem;
  }
}

.news-body {
  flex: 1;
}

.under-hero-wrapper {
  background-color: var(--base-light-color);
  padding: 4rem 0;
  width: 100%;
}

.under-headline {
  font-size: 1.7857142rem;
}

.file-download-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.file-download-container span {
  display: inline-block;
}
.file-download-container a {
  background-image: url("/images/pdf.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 76%;
  border-radius: 50%;
  box-shadow: var(--box-shadow-small);
  display: block;
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  transition: var(--base-transition);
}
@media (hover: hover) and (pointer: fine) {
  .file-download-container a:hover {
    background-color: var(--gray-100-color);
  }
}

.map-wrapper {
  aspect-ratio: 16/9;
  position: relative;
}

.privacy-signature-wrapper {
  margin-top: var(--large-spacing);
  text-align: right;
}
.privacy-signature-wrapper ul.signature li:nth-of-type(1) {
  font-weight: 500;
}
.privacy-signature-wrapper ul.signature li:nth-of-type(2) {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.privacy-signature-wrapper ul.signature li:nth-of-type(2) span {
  color: var(--gray-800-color);
  display: inline-block;
  font-size: 0.75em;
  font-weight: 400;
  margin-right: 0.5em;
}
.privacy-signature-wrapper ul.signature li:nth-of-type(3), .privacy-signature-wrapper ul.signature li:nth-of-type(4) {
  font-size: 0.875em;
}