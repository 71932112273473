@charset 'UTF-8';
@use './global/' as *;



:root {

  // root font size
  @each $key, $size in $root-font-size {
    @include media($key){
      --root-font-size: #{$size};
    }
  }

  // All Color
  @each $name, $color in $all-colors {
    --#{$name}-color: #{$color};
  }

  // grid
  --grid-columns: #{$grid-columns};
  --grid-gutter: #{$grid-gutter};

  // form grid
  --form-grid-gutter: #{$form-grid-gutter};



  --base-font-size: #{$base-font-size};
  --form-font-size: #{$form-font-size};
  --base-font-color: #{$base-font-color};
  --base-font-family: #{$base-font-family};

  --box-shadow: #{$base-box-shadow};
  --box-shadow-small: #{$base-box-shadow-small};
  --box-shadow-large: #{$base-box-shadow-large};
  --base-transition: #{$base-transition};
  --border-radius: #{$base-border-radius};

  --spacing: #{$base-spacer};
  --large-spacing: #{$base-spacer * 2};
  --xlarge-spacing: #{$base-spacer * 4};
  --exlarge-spacing: #{$base-spacer * 8};
}





html {
  font-size: var(--root-font-size);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}



body {
  background-color: $body-background-color;
  color: var(--base-font-color);
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}



// Links
a {
  color: $base-link-color;
  cursor: revert;
  text-decoration: $base-link-decoration;
  @include hover() {
    color: $base-link-hover-color;
    text-decoration: $base-link-hover-decoration;
  }
}



// Horizontal line
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: $hr-y-gutter 0;
  width: 100%;
  height: 0;
}



b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}
