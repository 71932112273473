@charset 'UTF-8';
@use './global/' as *;



.frame-border, .frame-mat {
  border-radius: var(--border-radius);
  padding: calc(1rem + .75vw);

  &:not(:last-child) {
    margin-bottom: var(--spacing);
  }
}



.frame-border {
  background-color: var(--white-color);
  border: 1px solid var(--gray-400-color);

  @each $name, $color in $all-colors {
    &.#{$name} {
      border-color: var(--#{$name}-color);
    }
  }
}



.frame-mat {
  background-color: var(--gray-100-color);

  @each $name, $color in $all-colors {
    &.#{$name} {
      background-color: var(--#{$name}-color);
    }
  }
}
