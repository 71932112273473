@charset 'UTF-8';
@use './global/' as *;



.grid {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat($grid-columns, 1fr);
  gap: var(--grid-gutter);

  &:has([class$="-center"]) {
    place-items: center;
  }

  // Columns
  > {
    @include exportGridColumns();
  }

  // 子要素にカラムの指定がない場合はすべて1カラムになる
  > * {
    grid-column: auto / span $grid-columns;
  }

  // 隣接
  &:not(:last-child) {
    margin-bottom: var(--grid-gutter);
  }

  // gapless
  &.gapless {
    gap: 0;
  }
}
