@charset 'UTF-8';
@use './global/' as *;



.pagination-container {
  margin: 4rem 0 0;
  width: auto;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  text-align: center;

  li {
    display: block;
    &:nth-of-type(n+2){
      margin-left: .5rem;
    }
  }

  .prev,
  .next,
  .page,
  #{$pagination-active-class} {
    border-radius: 3px;
    border: 1px solid transparent;
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    padding: .7em;
    transition: background-color var(--base-transition);
    &:not(.current){
      cursor: pointer;
      @include hover(){
        background-color: $gray-100;
        border-color: $gray-100;
      }
    }
  }

  .prev,
  .next {
    color: $gray-dark;
  }

  .page {
    background-color: $white;
    color: $gray-dark;
  }

  #{$pagination-active-class} {
    background-color: $base;
    border-color: $base;
    color: $white;
    font-weight: 700;
  }

  .disabled {
    background-color: $gray-200;
    color: $gray-600;
  }
}
