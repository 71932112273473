@charset 'UTF-8';
@use './global/' as *;



.globalnav-container {
  width: 100%;
  @include media(lg) {
    @include baseContainer();
  }
}



ul.globalnav-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include media(lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}



li.globalnav-item {
  position: relative;
  @include media(lg) {
    flex: 1;
    &::before, &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      height: 60%;
      transform: translateY(-50%);
    }
    &::before {
      border-left: 1px solid $white;
    }
    &:last-child {
      &::after {
        border-right: 1px solid $white;
        right: 0;
      }
    }
  }
}



.globalnav-link {
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
  padding: 1em;
  transition: opacity var(--base-transition);
  @include media(lg) {
    justify-content: center;
  }
  @include hover() {
    color: var(--white-color);
    opacity: .8;
  }

  &::after {
    content: '';
    background-image: set-svg($plus, $white);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
    flex: 0 0 auto;
    width: 1em;
    height: 1em;
    @include media(lg){
      content: none;
    }
  }

  &:is(a) {
    &::after {
      background-image: set-svg($arrow-right, $white);
    }
  }
}





.globalnav-child-wrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height $base-transition;
  @include media(lg) {
    cursor: pointer;
    display: none;
    max-height: none;
    overflow: visible;
    padding-top: .6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 136%;
    @at-root .globalnav-item:hover & {
      display: block;
      animation: show $base-transition;
    }
  }
}



.globalnav-child {
  li {
    position: relative;
    a {
      background-color: colorCodeIncludingOpacity(darken($base, 3%), 90%);
      color: var(--white-color);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 1em 1em 2em;
      transition: background-color var(--base-transition);
      @include media(lg){
        background-color: colorCodeIncludingOpacity(#000000, 90%);
        @include hover() {
          background-color: colorCodeIncludingOpacity(#000000, 96%);
        }
      }
      &::after {
        content: '';
        background-image: set-svg($arrow-right-circle, $white);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        display: inline-block;
        flex: 0 0 auto;
        width: 1em;
        height: 1em;
      }
    }
  }
  // lg
  @include media(lg) {
    // 吹き出しの口
    &::before {
      content: '';
      border: 10px solid transparent;
      border-bottom: 10px solid colorCodeIncludingOpacity(#000000, 90%);
      position: absolute;
      top: -.6rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
    }
  }
}
