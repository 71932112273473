@charset 'UTF-8';
@use './global/' as *;



.tab-wrapper {
  width: 100%;
}



.tab-labels {
  border-bottom: 1px solid var(--gray-300-color);
  display: flex;
  flex-flow: row wrap;
}



.tab-label-column {
  border-top: 1px solid var(--gray-300-color);
  border-left: 1px solid var(--gray-300-color);
  flex: 1 0 auto;
  position: relative;
  &:last-child {
    border-right: 1px solid var(--gray-300-color);
  }
}



// Button
.tab-label-item {
  background-color: var(--gray-200-color);
  cursor: pointer;
  color: var(--gray-400-color);
  display: block;
  padding: 1em 0;
  text-align: center;
  width: 100%;
  @include hover() {
    color: var(--gray-600-color);
  }
  &[aria-selected="true"] {
    background-color: var(--white-color);
    color: var(--gray-dark-color);
  }
}



.tab-contents {
  border-left: 1px solid var(--gray-300-color);
  border-right: 1px solid var(--gray-300-color);
  border-bottom: 1px solid var(--gray-300-color);
}

.tab-contents-item {
  animation: show .4s linear 0s;
  padding: 1em;
}
