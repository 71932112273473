@charset 'UTF-8';
@use './global/' as *;



.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include baseContainer(1rem);
  @include media(lg) {
    flex-direction: column;
  }
}



.header-brand-column {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}



.header-brand {
  display: flex;
  align-items: center;
  gap: .5em;
  @include hover() {
    .logo {
      opacity: .8;
    }
  }

  .mark {
    flex: 0 0 42px;
    img {
      display: block;
    }
  }
  .logo {
    color: var(--white-color);
    flex: 1;
    line-height: 1;
    transition: opacity var(--base-transition);

    .amg {
      font-size: .75em;
      margin-bottom: .5em;
    }
    .prior {
      font-size: 1.142857em;
      font-weight: 700;
    }
  }
}



.header-burger-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-left: 1rem;
  @include media(lg) {
    display: none;
  }
}
