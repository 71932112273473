@charset 'UTF-8';
@use 'sass:map';
@use '../functions' as *;



$base  : #007e39 !default;
$accent: #2e2f33 !default;
$uno   : #6f42c1 !default;
$dos   : #d63384 !default;
$tres  : #fd7e14 !default;
$cuatro: #2da8a8 !default;
$cinco : #5f6987 !default;

$themes: (
  'base'  : $base,
  'accent': $accent,
  'uno'   : $uno,
  'dos'   : $dos,
  'tres'  : $tres,
  'cuatro': $cuatro,
  'cinco' : $cinco,
  'first' : $uno,
  'second': $dos,
  'third' : $tres,
  'fourth': $cuatro,
  'five'  : $cinco
) !default;

// lighten
$prefix-light: '-light' !default;
$light-weight: 70% !default;
$themes-light: (
  'base#{$prefix-light}'  : tint-color($base, $light-weight),
  'accent#{$prefix-light}': tint-color($accent, $light-weight),
  'uno#{$prefix-light}'   : tint-color($uno, $light-weight),
  'dos#{$prefix-light}'   : tint-color($dos, $light-weight),
  'tres#{$prefix-light}'  : tint-color($tres, $light-weight),
  'cuatro#{$prefix-light}': tint-color($cuatro, $light-weight),
  'cinco#{$prefix-light}' : tint-color($cinco, $light-weight),
  'first#{$prefix-light}' : tint-color($uno, $light-weight),
  'second#{$prefix-light}': tint-color($dos, $light-weight),
  'third#{$prefix-light}' : tint-color($tres, $light-weight),
  'fourth#{$prefix-light}': tint-color($cuatro, $light-weight),
  'five#{$prefix-light}'  : tint-color($cinco, $light-weight)
) !default;

// darken
$prefix-dark: '-dark' !default;
$dark-weight: 40% !default;
$themes-dark: (
  'base#{$prefix-dark}'  : shade-color($base, $dark-weight),
  'accent#{$prefix-dark}': shade-color($accent, $dark-weight),
  'uno#{$prefix-dark}'   : shade-color($uno, $dark-weight),
  'dos#{$prefix-dark}'   : shade-color($dos, $dark-weight),
  'tres#{$prefix-dark}'  : shade-color($tres, $dark-weight),
  'cuatro#{$prefix-dark}': shade-color($cuatro, $dark-weight),
  'cinco#{$prefix-dark}' : shade-color($cinco, $dark-weight),
  'first#{$prefix-dark}' : shade-color($uno, $dark-weight),
  'second#{$prefix-dark}': shade-color($dos, $dark-weight),
  'third#{$prefix-dark}' : shade-color($tres, $dark-weight),
  'fourth#{$prefix-dark}': shade-color($cuatro, $dark-weight),
  'five#{$prefix-dark}'  : shade-color($cinco, $dark-weight)
) !default;



$red   : #b0120a !default;
$yellow: #fff000 !default;
$orange: #f18f2c !default;
$green : #63a764 !default;
$blue  : #005fcc !default;
$pink  : #ff0066 !default;
$purple: #6f42c1 !default;
$cyan  : #17a2b8 !default;

$commons: (
  'red'   : $red,
  'yellow': $yellow,
  'orange': $orange,
  'green' : $green,
  'blue'  : $blue,
  'pink'  : $pink,
  'purple': $purple,
  'cyan'  : $cyan
) !default;



$gray-100 : #f8f9fa !default;
$gray-200 : #e9ecef !default;
$gray-300 : #dee2e6 !default;
$gray-400 : #ced4da !default;
$gray-500 : #adb5bd !default;
$gray-600 : #6c757d !default;
$gray-700 : #495057 !default;
$gray-800 : #343a40 !default;
$gray-900 : #212529 !default;
$gray-dark: #333    !default;
$black    : #000    !default;
$white    : #fff    !default;

$grays: (
  'gray-100':  $gray-100,
  'gray-200':  $gray-200,
  'gray-300':  $gray-300,
  'gray-400':  $gray-400,
  'gray-500':  $gray-500,
  'gray-600':  $gray-600,
  'gray-700':  $gray-700,
  'gray-800':  $gray-800,
  'gray-900':  $gray-900,
  'gray-dark': $gray-dark,
  'black':     $black,
  'white':     $white
) !default;



// カラーの配列を作る

// テーマカラーの明度
$themes-luminance: map.merge($themes-light, $themes-dark);

// テーマカラー
$theme-colors: map.merge($themes, $themes-luminance);

// グレースケールと汎用カラーの配列
$basic-colors: map.merge($grays, $commons);

// テーマカラーを含めたすべての配列
$all-colors: map.merge($theme-colors, $basic-colors);
