@charset 'UTF-8';
@use './global/' as *;



.under-hero-wrapper {
  background-color: var(--base-light-color);
  padding: 4rem 0;
  width: 100%;
}

.under-headline {
  font-size: 1.7857142rem;
}



// テーブル内PDFアイコン
.file-download-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  span {
    display: inline-block;
  }
  a {
    background-image: url('/images/pdf.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 76%;
    border-radius: 50%;
    box-shadow: var(--box-shadow-small);
    display: block;
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    transition: var(--base-transition);
    @include hover() {
      background-color: var(--gray-100-color);
    }
  }
}



// Google Map
.map-wrapper {
  aspect-ratio: 16/9;
  position: relative;
}



//
.privacy-signature-wrapper {
  margin-top: var(--large-spacing);
  text-align: right;
  ul.signature {
    li {
      &:nth-of-type(1) {
        font-weight: 500;
      }
      &:nth-of-type(2) {
        font-size: 1.25em;
        font-weight: 700;
        margin-bottom: .5em;
        span {
          color: var(--gray-800-color);
          display: inline-block;
          font-size: .75em;
          font-weight: 400;
          margin-right: .5em;
        }
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        font-size: .875em;
      }
    }
  }
}
