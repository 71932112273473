@charset 'UTF-8';
@use './global/' as *;



h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  font-weight: $headings-font-weight;
  line-height: $heading-line-height;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}



.heading {
  margin-bottom: $heading-margin-bottom;
  &:not(:first-child) {
    margin-top: var(--xlarge-spacing);
  }
}



h1 {
  font-size: $h1-font-size;
}



h2 {
  font-size: $h2-font-size;
  &.top-heading {
    border-top: 1px solid var(--base-color);
    border-bottom: 1px solid var(--base-color);
    color: var(--base-color);
    margin: 0 0 1em;
    padding: .5em;
  }
  &.heading {
    background-color: var(--gray-200-color);
    border-radius: 8px;
    padding: .75em;
    &:not(:first-child) {
      margin-top: var(--exlarge-spacing);
    }
  }
}



h3 {
  font-size: $h3-font-size;
  &.heading {
    border-bottom: 2px solid var(--base-color);
    padding: 0 0 .5em;
    position: relative;
  }
}



h4 {
  font-size: $h4-font-size;
}



h5 {
  font-size: $h5-font-size;
}



h6 {
  font-size: $h6-font-size;
}



// Paragraph
.lead {
  font-size: inherit;
  letter-spacing: $lead-letter-spacing;
  line-height: $lead-line-height;
  text-rendering: optimizeLegibility;
  // .lead spacer
  &:not(:last-child) {
    margin-bottom: var(--spacing);
  }
}
// 画像用
.image {
  &:not(:last-child) {
    margin-bottom: var(--spacing);
  }
}



// 注釈
.notes {
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: '※';
    font-family: sans-serif;
  }
}
