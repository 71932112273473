@charset 'UTF-8';
@use './global/' as *;



.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}



.construct-header {
  background-color: $header-background-color;
  box-shadow: var(--box-shadow);
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-header;
  @include media(lg){
    box-shadow: none;
  }
}



.construct-globalnav {
  animation: show var(--base-transition);
  display: none;
  &[aria-hidden="false"] {
    display: block;
  }

  background-color: $globalnav-background-color;
  flex: 0 0 auto;
  max-height: calc(100% - 70px);
  overflow: auto;
  width: 100%;
  z-index: $z-index-globalnav;
  &::after {
    content: '';
    background-color: #{colorCodeIncludingOpacity(#000000, 90%)};
    display: block;
    position: fixed;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    @include media(lg) {
      content: none;
    }
  }
  @include media(lg) {
    max-height: none;
    overflow: visible;
  }
}



.construct-main {
  background-color: transparent;
  container-type: inline-size;
  flex: 1 0 auto;
  padding: var(--exlarge-spacing) 0;
  width: 100%;
  z-index: $z-index-main;
}



.construct-footer {
  background-color: $footer-background-color;
  flex: none;
  width: 100%;
  z-index: $z-index-footer;
}



.container {
  @include baseContainer();
}



.section-content, .section-block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: var(--exlarge-spacing);
  }
}



.block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: var(--xlarge-spacing);
  }
}



.center-block {
  display: block;
  margin: 0 auto;
}
