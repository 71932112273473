@charset 'UTF-8';
@use './global/' as *;



.burger {
  background-color: $white;
  background-image: set-svg($burger, $accent);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  box-shadow: var(--box-shadow);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color var(--base-transition), background-image var(--base-transition);
  width: 40px;
  height: 40px;

  @include hover(){
    background-color: $gray-100;
  }

  // Active
  &[aria-pressed="true"] {
    background-color: $accent;
    background-image: set-svg($dismiss, $white);
  }
}
