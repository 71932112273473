@charset 'UTF-8';
@use './global/' as *;



.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include baseContainer(3rem);
}



.footer-left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 0 0 auto;
}



.footer-brand {
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
  gap: .5em;
  @include hover() {
    .prior {
      color: var(--white-color);
      opacity: .8;
    }
  }
  .mark {
    flex: 0 0 42px;
    img {
      display: block;
    }
  }
  .prior {
    font-size: 1.142857em;
    font-weight: 700;
    transition: opacity var(--base-transition);
  }
}



.footer-address {
  color: var(--white-color);
  font-size: .875rem;
  text-align: center;
}



.footer-contact {
  color: var(--white-color);
  font-size: 1.125rem;
  li {
    display: flex;
    align-items: center;
    gap: .25em;
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1em;
      height: 1.25em;
    }
    &.tel {
      &::before {
        background-image: set-svg($tel, $white);
      }
    }
    &.fax {
      margin: .5em 0 0;
      &::before {
        background-image: set-svg($fax, $white);
      }
    }
  }
}



.footer-right-column {
  display: block;
}



.footer-utilities-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
  li {
    a {
      color: var(--white-color);
      font-weight: 500;
      transition: opacity var(--base-transition);
      @include hover() {
        opacity: .8;
      }
    }
  }
}



.footer-legal {
  background-color: var(--accent-color);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}



.footer-legal-copyright {
  color: var(--white-color);
  font-size: .75rem;
}
