@charset 'UTF-8';
@use './global/' as *;



.btn {
  background-color: var(--accent-color);
  border: $btn-border-width solid transparent;
  border-radius: $btn-border-radius;
  color: var(--white-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: $btn-font-size-normal;
  font-weight: 500;
  text-align: center;
  appearance: none;
  text-decoration: none;
  transition: background-color var(--base-transition), color var(--base-transition);
  vertical-align: middle;
  padding: $btn-y-padding-normal $btn-x-padding-normal;
  &::after {
    content: '';
    background-image: set-svg($arrow-right, $white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: 0 0 auto;
    margin-left: .75rem;
    width: 1em;
    height: 1em;
  }

  // Sizes
  @each $name, $value in $btn-font-sizes {
    &.#{$name} {
      font-size: $value;
      padding: map-get( $btn-y-paddings, $name ) map-get( $btn-x-paddings, $name );
    }
  }

  // hover
  @include hover(){
    background-color: var(--gray-700-color);
    color: var(--white-color);
  }

  // Colors variation
  @each $name, $color in $themes {
    &.#{$name} {
      background-color: var(--#{$name}-color);
      color: var(--white-color);
      @include hover() {
        background: var(--#{$name}#{$prefix-light}-color);
        color: map-get( $btn-hover-colors, $name );
      }
    }
  }

  // Outline
  &.outline {
    background-color: var(--white-color);
    border: $btn-border-width solid var(--accent-color);
    color: var(--accent-color);
    &::after {
      background-image: set-svg($arrow-right, $accent);
    }
    // Outline hover
    @include hover(){
      background-color: var(--accent-color);
      color: var(--white-color);
      &::after {
        background-image: set-svg($arrow-right, $white);
      }
    }
  }

  // Icon
  @each $name, $icon in $svg-icon-types {
    &.#{$name} {
      &::after {
        content: '';
        background-image: set-svg($icon, $white);
      }
      // icon color
      @each $key, $color in $all-colors {
        &.ic-#{$key} {
          &::after, &::before {
            background-image: set-svg($icon, $color);
          }
        }
      }
    }
  }

  // Expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

}
