@charset 'UTF-8';
@use './global/' as *;



ul.news-list {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;

  &.top {
    li:nth-of-type(n+11) {
      display: none;
    }
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    position: relative;
    &:not(:first-child) {
      border-top: 1px solid var(--gray-300-color);
    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: background-color var(--base-transition);
      @include hover() {
        background-color: colorCodeIncludingOpacity($gray-500, 10%);
      }
    }

    &:has(a) {
      &::after {
        content: '';
        @include setSvgIcon($arrow-right-circle, $gray-600, 1.25em);
        flex: 0 0 auto;
        align-self: center;
      }
    }
  }
}

.news-container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  flex: 1;
  @include media(sm) {
    flex-direction: row;
  }
}

.news-date {
  color: var(--gray-600-color);
  font-weight: 500;
  flex: 0 0 auto;
  @include media(sm) {
    flex: 0 0 6rem;
  }
}

.news-body {
  flex: 1;
}
